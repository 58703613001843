import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { bagImage, personImage } from "./assets";
import { toast } from "react-toastify";
import { toastSuccessMessage } from "../../../components/src/toastMessage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  isOpen: boolean;
  callbackShopWithFriend: (isOpen: boolean) => void;
  // Customizable Area End
}

interface Member {
  name: string;
  role: string;
}

interface Group {
  id: string;
  type: string;
  attributes: GroupAttributes;
}

interface GroupAttributes {
  name: string;
  account_id: number;
  created_at: string;
  updated_at: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isMinimize: boolean;

  isFirstPageOpen: boolean;
  isCreateGroupOpen: boolean;
  isCreatedGroupOpen: boolean;
  isCreatedAddPeopleOpen: boolean;
  isChatScreenOpen: boolean;
  isEditGroupOpen: boolean;
  isEditAddPeopleOpen: boolean;
  isWishlistPageOpen: boolean;
  isGroupInfoPageOpen: boolean;
  isExitGroupDialogOpen: boolean;

  groupName: string;
  createdGroupId: string;
  groupMembers: Array<Member>;
  groups: Array<Group>;
  isSettingOpen: boolean;
  chatGroupName: string;
  newChatGroupName: string;
  chatGroupId: string;
  groupCreatorId: string;
  isGroupNameEditable: boolean;

  searchMember: string;
  searchMembersList: any[];
  invitedMembersList: any[];

  wishlistList: any[];
  activeCartId: any;
  catalogId: any;
  catalogVariantId: any;

  message: any;
  messageFile: any;
  messageList: any[];


  messagePopup: boolean,
  cartCount: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserGroupsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  CreateGroupCallId: any;
  GroupMembersCallId: any;
  GroupsCallId: any;
  EditGroupNameCallId: any;
  DeleteGroupCallId: any;
  SearchMemberCallId: any;
  CreateInvitationCallId: any;
  ZoomMeetingCallId: any;
  ExitFromGroupCallId: any;
  RemoveFromGroupCallId: any;
  AcceptDeclineInvitationCallId: any;
  GroupWishlistCallId: any;
  RemoveWishlistCallId: any;
  AddToBagOrderCallId: any;
  ActiveCartCallId: any;
  GetChattingCallId: any;
  PostMessageChattingCallId: any;
  ShoppingCartDataCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isMinimize: false,

      isFirstPageOpen: true,
      isCreateGroupOpen: false,
      isCreatedGroupOpen: false,
      isCreatedAddPeopleOpen: false,
      isChatScreenOpen: false,
      isEditGroupOpen: false,
      isEditAddPeopleOpen: false,
      isWishlistPageOpen: false,
      isGroupInfoPageOpen: false,
      isExitGroupDialogOpen: false,

      groups: [],

      groupName: "",
      createdGroupId: "",
      groupMembers: [],
      isSettingOpen: false,
      chatGroupName: "",
      newChatGroupName: "",
      chatGroupId: "",
      groupCreatorId: "",
      isGroupNameEditable: false,

      searchMember: "",
      searchMembersList: [],
      invitedMembersList: [],

      wishlistList: [],
      activeCartId: null,
      catalogId: null,
      catalogVariantId: null,

      message: "",
      messageFile: null,
      messageList: [],


      messagePopup: false,
      cartCount: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (apiRequestCallId && responseJson) {
        if (
          apiRequestCallId === this.CreateGroupCallId ||
          apiRequestCallId === this.GroupsCallId ||
          apiRequestCallId === this.GroupMembersCallId ||
          apiRequestCallId === this.EditGroupNameCallId ||
          apiRequestCallId === this.DeleteGroupCallId ||
          apiRequestCallId === this.SearchMemberCallId ||
          apiRequestCallId === this.CreateInvitationCallId ||
          apiRequestCallId === this.ZoomMeetingCallId ||
          apiRequestCallId === this.AcceptDeclineInvitationCallId
        ) {
          this.receiveBlock1(apiRequestCallId, responseJson);
        } else if (
          apiRequestCallId === this.ExitFromGroupCallId ||
          apiRequestCallId === this.RemoveFromGroupCallId ||
          apiRequestCallId === this.GroupWishlistCallId ||
          apiRequestCallId === this.RemoveWishlistCallId ||
          apiRequestCallId === this.ActiveCartCallId ||
          apiRequestCallId === this.AddToBagOrderCallId ||
          apiRequestCallId === this.GetChattingCallId ||
          apiRequestCallId === this.PostMessageChattingCallId ||
          apiRequestCallId === this.ShoppingCartDataCallId
        ) {
          this.receiveBlock2(apiRequestCallId, responseJson);
        }
      }

      if (errorReponse) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  receiveBlock1 = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.CreateGroupCallId) {
      this.createGroupApiResponse(responseJson);
    } else if (apiRequestCallId === this.GroupsCallId) {
      this.fetchGroupsApiResponse(responseJson);
    } else if (apiRequestCallId === this.GroupMembersCallId) {
      this.fetchGroupMembersApiResponse(responseJson);
    } else if (apiRequestCallId === this.EditGroupNameCallId) {
      this.editGroupNameApiResponse(responseJson);
    } else if (apiRequestCallId === this.DeleteGroupCallId) {
      this.deleteGroupApiResponse(responseJson);
    } else if (apiRequestCallId === this.SearchMemberCallId) {
      this.searchMemberApiResponse(responseJson);
    } else if (apiRequestCallId === this.CreateInvitationCallId) {
      this.createInvitationApiResponse(responseJson);
    } else if (apiRequestCallId === this.ZoomMeetingCallId) {
      this.handleZoomCallApiResponse(responseJson);
    } else if (apiRequestCallId === this.AcceptDeclineInvitationCallId) {
      this.acceptDeclineInvitationApiResponse(responseJson);
    }
  }

  receiveBlock2 = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.ExitFromGroupCallId) {
      this.exitFromGroupApiResponse(responseJson);
    } else if (apiRequestCallId === this.RemoveFromGroupCallId) {
      this.removeMemberFromGroupApiResponse(responseJson);
    } else if (apiRequestCallId === this.GroupWishlistCallId) {
      this.fetchGroupWishlistApiResponse(responseJson);
    } else if (apiRequestCallId === this.RemoveWishlistCallId) {
      this.removeCatalogueFromWishlistApiResponse(responseJson);
    } else if (apiRequestCallId === this.ActiveCartCallId) {
      this.activeCartApiResponse(responseJson);
    } else if (apiRequestCallId === this.AddToBagOrderCallId) {
      this.addToBagApiResponse(responseJson);
    } else if (apiRequestCallId === this.GetChattingCallId) {
      this.fetchGroupChatApiResponse(responseJson);
    } else if (apiRequestCallId === this.PostMessageChattingCallId) {
      this.postMessageToGroupApiResponse(responseJson);
    } else if (apiRequestCallId === this.ShoppingCartDataCallId) {
      this.getShoppingCartDetailApiResponse(responseJson);
    }
  }

  // Create Group
  createGroupApiResponse = (responseJson: any) => {
    this.CreateGroupCallId = null;

    if (responseJson?.data) {
      this.setState(
        {
          ...this.state,
          createdGroupId: responseJson.data?.id,
        },
        () => {
          this.openCreatedPage();
          this.fetchGroupMembers(this.state.createdGroupId);
        }
      );
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  // Fetch Groups
  fetchGroupsApiResponse = (responseJson: any) => {
    this.GroupsCallId = null;

    if (responseJson?.data) {
      this.setState({
        ...this.state,
        groups: responseJson.data,
        messageList: [],
      });
    } else {
      this.setState({
        ...this.state,
        groups: [],
        messageList: [],
      });
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  // Fetch Group Member
  fetchGroupMembersApiResponse = (responseJson: any) => {
    this.GroupMembersCallId = null;

    this.setState({
      ...this.state,
      groupMembers: responseJson,
    });

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  // Edit Group Name
  editGroupNameApiResponse = (responseJson: any) => {
    this.EditGroupNameCallId = null;

    if (responseJson?.data) {
      this.setState({
        ...this.state,
        chatGroupName: responseJson.data.attributes.name,
        newChatGroupName: responseJson.data.attributes.name,
        isChatScreenOpen: true,
        isEditGroupOpen: false,
        isSettingOpen: false,
      });
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  // Delete Group
  deleteGroupApiResponse = (responseJson: any) => {
    this.DeleteGroupCallId = null;

    if (responseJson?.meta?.message) {
      toastSuccessMessage(responseJson?.meta?.message);
    }

    this.setState(
      {
        ...this.state,
        chatGroupId: "",
        chatGroupName: "",
        newChatGroupName: "",
        isSettingOpen: false,
      },
      () => {
        this.openFirstPage();
        this.fetchGroup();
      }
    );

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }


  // Search Member
  searchMemberApiResponse = (responseJson: any) => {
    this.SearchMemberCallId = null;

    if (responseJson?.data) {
      this.setState({
        ...this.state,
        searchMembersList: responseJson.data,
      }, () => this.forceUpdate());
    } else {
      this.setState({
        ...this.state,
        searchMembersList: [],
      });
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  // Create Invitation
  createInvitationApiResponse = (responseJson: any) => {
    this.CreateInvitationCallId = null;

    if (responseJson?.meta?.message) {
      toast.success(responseJson?.meta?.message, {
        position: "top-right",
        delay: 1000
      });
    } else if (responseJson?.errors) {
      toast.error(responseJson.errors, {
        position: "top-right",
      });
    }

    this.searchMember();
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  // Handle Zoom Call API
  handleZoomCallApiResponse = (responseJson: any) => {
    this.ZoomMeetingCallId = null;

    if (responseJson?.error) {
      if (responseJson?.error === 'Email domain is blocked') {
        toast.error("Your email is not a valid email. Kindly update your email in Account Settings.", {
          position: "top-right"
        })
      } else {
        toast.error(responseJson.error, {
          position: "top-right",
        });
      }
    }
    if (responseJson?.message) {
      if (responseJson.message === "no meeting going on") {
        toast.warn("Meeting not yet started by host", {
          position: "top-right",
        });
      } else {
        toast.warn(responseJson.message, {
          position: "top-right",
        });
      }
    }
    if (responseJson.data) {
      if (this.state.groupCreatorId === localStorage.getItem("userId")) {
        window.open(responseJson.data.attributes.start_url, "_blank");
      } else {
        window.open(responseJson.data.attributes.meeting_link, "_blank");
      }
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  // Accept or Reject Group Invitation API
  acceptDeclineInvitationApiResponse = (responseJson: any) => {
    this.AcceptDeclineInvitationCallId = null;

    if (responseJson?.data) {
      this.fetchGroup();
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  // Exit From Group API
  exitFromGroupApiResponse = (responseJson: any) => {
    this.ExitFromGroupCallId = null;

    this.setState(
      {
        ...this.state,
        isExitGroupDialogOpen: false,
      },
      () => {
        this.fetchGroup();
        this.openFirstPage();
      }
    );

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  // Remove Member from Group API
  removeMemberFromGroupApiResponse = (responseJson: any) => {
    this.RemoveFromGroupCallId = null;

    if (responseJson?.message) {
      toastSuccessMessage(responseJson?.message);
    }
    if (responseJson) {
      this.fetchGroupMembers(this.state.chatGroupId);
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }


  // Fetch Group Wishlist  API
  fetchGroupWishlistApiResponse = (responseJson: any) => {
    this.GroupWishlistCallId = null;

    if (responseJson?.data) {
      this.setState({
        ...this.state,
        wishlistList: responseJson.data,
      });
    } else {
      this.setState({
        ...this.state,
        wishlistList: [],
      });
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  // Remove Catalog from Group WishList API
  removeCatalogueFromWishlistApiResponse = (responseJson: any) => {
    this.RemoveWishlistCallId = null;

    if (responseJson) {
      this.fetchWishList();
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  // Add to bag - Group Shopping
  activeCartApiResponse = (responseJson: any) => {
    this.ActiveCartCallId = null;

    if (responseJson?.data) {

      this.setState(
        { ...this.state, activeCartId: responseJson?.data?.id },
        () => {
          this.addToBagOrder();
        }
      );
    } else {
      this.setState({ ...this.state, activeCartId: null }, () => {
        this.addToBagOrder();
      });
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }


  addToBagApiResponse = (responseJson: any) => {
    this.AddToBagOrderCallId = null;

    if (responseJson?.data) {

      console.log(location.pathname, "pathname")
      if (location.pathname == "/ShoppingBag") { location.reload() }

      toast.success("Successfully added in your bag", {
        position: "top-right",
      });
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }


  // Chatting - GET
  fetchGroupChatApiResponse = (responseJson: any) => {
    this.GetChattingCallId = null;

    if (responseJson?.data) {
      this.setState(
        {
          ...this.state,
          messageList: responseJson?.data.reverse(),
        },
        () => this.scrollToBottom()
      );
    }

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  // Chatting - POST
  postMessageToGroupApiResponse = (responseJson: any) => {
    this.PostMessageChattingCallId = null;

    this.setState(
      {
        ...this.state,
        message: "",
        messageFile: null,
      },
      () => {
        this.getChatting();
      }
    );

    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  // cart items count start 
  // shopping cart data start 
  getShoppingCartDetailApiResponse = (responseJson: any) => {
    this.ShoppingCartDataCallId = null;
    if (responseJson?.data) {
      // this.setState({ ShoppingCartData: responseJson.data }, () => {
      const cartCount: any = responseJson?.data?.length
      this.setState({ cartCount: cartCount })

    };
    // this.setState({ shoppingCartloading: false });
    // console.log('////////////// ShoppingCartData data------------------------------------------------------', this.state.ShoppingCartData);
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      //Need To send Login token message to save for future call
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  }

  timer: any;
  messageList: any;
  upload: any;
  images = {
    bagImage,
    personImage,
  };

  scrollToBottom = () => {
    const scrollHeight = this.messageList?.scrollHeight;
    if (scrollHeight) {
      const height = this.messageList.clientHeight;
      const maxScrollTop = scrollHeight - height;
      this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0
    }
  };

  onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];

    this.setState({
      ...this.state,
      messageFile: file,
    });
  }

  // Customizable Area Start
  async componentDidMount() {
    try {
      if (localStorage.getItem("authToken")) {
        localStorage.removeItem("groupId");
        this.fetchGroup();
        this.shoppingCartData()
        console.log(location.pathname, "pathname")
      }

      this.timer = setInterval(() => {
        if (this.state.isChatScreenOpen) {
          console.log("set INterval====>>>>>")
          this.getChatting();
        }
      }, 5000);
    } catch (error) { }


  }

  async componentWillUnmount() {
    clearInterval(this.timer);
  }

  // Chatting - Get
  getChatting = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GetChattingCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.chattingAPIEndPoint}/${this.state.chatGroupId}/get_messages`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Chatting - Post
  postMassage = () => {
    // const body = {
    //   text: this.state.message,
    // };

    let formData = new FormData();
    formData.append('text', this.state.message.toString());
    formData.append('images[]', this.state.messageFile);

    const header = {
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.PostMessageChattingCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.chattingAPIEndPoint}/${this.state.chatGroupId}/send_message`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Handle Search Member
  handleSearchMember(name: string) {
    this.setState({
      ...this.state,
      searchMember: name,
    });
  }

  // Search Member API
  searchMember = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    // we are getting createdGroupId only when are creating a group for edit use chatGroupId
    const group_id = this.state.createdGroupId ? this.state.createdGroupId : this.state.chatGroupId;

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.SearchMemberCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchMemberAPIEndPoint + `?search=${this.state.searchMember}&group_id=${group_id}`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Edit Group Name API
  handleEditGroupName = () => {
    const body = {
      group: {
        name: this.state.newChatGroupName,
      },
    };

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.EditGroupNameCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editGroupNameAPIEndPoint + this.state.chatGroupId
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPutMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Delete Group API
  handleDeleteGroup = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.DeleteGroupCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteGroupNameAPIEndPoint + this.state.chatGroupId
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // cart items count start

  shoppingCartData() {

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.ShoppingCartDataCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ShoppingCartAPIEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;



  }

  // cart items count end

  // Group Listing API
  fetchGroup = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GroupsCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchGroupsAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Fetch Group Member
  fetchGroupMembers(id: string) {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    // we are getting createdGroupId only when are creating a group for edit use chatGroupId
    const group_id = this.state.createdGroupId ? this.state.createdGroupId : this.state.chatGroupId;

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GroupMembersCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchGroupMemberAPIEndPoint + group_id
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Exit From Group API
  exitFromGroup = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const body = {
      group_id: Number(this.state.chatGroupId),
      member_id: Number(localStorage.getItem("userId")),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.ExitFromGroupCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exitFromGroupAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Remove From Group API
  removeFromGroup = (id: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    // we are getting createdGroupId only when are creating a group for edit use chatGroupId
    const group_id = this.state.createdGroupId ? this.state.createdGroupId : this.state.chatGroupId;

    const body = {
      group_id: Number(group_id),
      member_id: id,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.RemoveFromGroupCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exitFromGroupAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Create Group - Handle Group Name
  handleChangeGroupName(name: string) {
    this.setState({
      ...this.state,
      groupName: name,
    });
  }

  // Edit Group - Handle Group Name
  handleChangeEditGroupName(name: string) {
    this.setState({
      ...this.state,
      newChatGroupName: name,
    });
  }

  // Create Group API
  handleCreateGroup = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const body = {
      group: {
        name: this.state.groupName,
      },
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CreateGroupCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createGroupAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Toggle Settings
  handleToggleSetting() {
    this.setState({
      ...this.state,
      isSettingOpen: !this.state.isSettingOpen,
    });
  }

  // Back From Second Page
  backFromSecondPage() {
    this.setState({
      groupName: "",
      createdGroupId: "",
      groupMembers: [],
    });
  }

  backWithoutSave = () => {
    this.setState({
      ...this.state,
      newChatGroupName: this.state.chatGroupName,
      isChatScreenOpen: true,
      isEditGroupOpen: false,
      isSettingOpen: false,
      isWishlistPageOpen: false,
    });
  };

  // Invite User to join Group
  createInvitation = (gId: string, uId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    // we are getting createdGroupId only when are creating a group for edit use chatGroupId
    const group_id = this.state.createdGroupId ? this.state.createdGroupId : this.state.chatGroupId;

    const body = {
      group_shopping_id: group_id,
      invited_user_id: uId,
    };

    // this.setState({
    //   ...this.state,
    //   invitedMembersList: [...this.state.invitedMembersList, uId],
    // });

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.CreateInvitationCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createInvitationAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Fetch Group Wishlist API
  fetchWishList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.GroupWishlistCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGroupWishListAPIEndPoint + this.state.chatGroupId
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Remove Catalog from Group WishList API
  removeWishlist = (cid: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const body = {
      group_shopping_id: Number(this.state.chatGroupId),
      catalogue_id: Number(cid),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.RemoveWishlistCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeWishListAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Accept / Decline to join Group
  acceptDeclineInvitation = (status: string, id: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const body = {
      status: status,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.AcceptDeclineInvitationCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.acceptDeclineInvitationGroupAPIEndPoint
      }/${id}/accept_reject_invite`
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPutMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Add to Bag API
  addToBagOrder = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const catalogue_variant_id = this.state.catalogVariantId || null;

    // const httpBody = {
    //   quantity: 1,
    //   catalogue_id: Number(this.state.catalogId),
    //   cart_id: this.state.activeCartId ? Number(this.state.activeCartId) : null,
    //   catalogue_variant_id: Number(catalogue_variant_id)
    // };


    const httpBody =
      localStorage.getItem("exchangeFlow") ? {
        quantity: 1,
        catalogue_id: Number(this.state.catalogId),
        // catalogue_variant_id: catvariantid ? catvariantid : null,
        catalogue_variant_id: Number(catalogue_variant_id),
        type: "exchange", //#use this when you will create the new exchange order.
        order_item_id: localStorage.getItem("exchangeItemOrderId") //here take order_item_id which item you have to exchange.
      } : {
        quantity: 1,
        catalogue_id: Number(this.state.catalogId),
        cart_id: this.state.activeCartId ? Number(this.state.activeCartId) : null,
        catalogue_variant_id: Number(catalogue_variant_id)
      }




    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.AddToBagOrderCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addToBagOrderAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  activeCartId = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.ActiveCartCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activeCartAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // logo Name
  logoName = (name: string) => {
    let first = name.split(' ').slice(0, 2).join(' ');
    let logoName = first.split(' ').map((word: any) => word[0]).join('');
    return logoName;
  }

  // Random color
  getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  // Enable Edit Group Name
  enableEditGroupName() {
    this.setState({
      ...this.state,
      isGroupNameEditable: true,
    });
  }

  handleGroup = (group: Group) => {
    this.setState(
      {
        ...this.state,
        chatGroupId: group.id,
        chatGroupName: group.attributes.name,
        newChatGroupName: group.attributes.name,
        groupCreatorId: "" + group.attributes.account_id,
        searchMember: "",
        searchMembersList: [],
        invitedMembersList: [],
        message: "",
        messageFile: null,
      },
      () => {
        this.openChatPage();
        this.getChatting();
        // window.scrollTo(0, );
      }
    );
  };

  // Handle Zoom Call API
  handleZoomCall = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    let zoomAPIEndPoint: string = "";
    if (this.state.groupCreatorId === localStorage.getItem("userId")) {
      zoomAPIEndPoint = configJSON.zoomAdminMeetingAPIEndPoint;
    } else {
      zoomAPIEndPoint = configJSON.zoomParticipantMeetingAPIEndPoint;
    }

    const body = {
      group_owner_id: this.state.groupCreatorId,
      group_shopping_id: this.state.chatGroupId,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.ZoomMeetingCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      zoomAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Handle Page
  openFirstPage = () => {
    this.setState({
      ...this.state,
      isFirstPageOpen: true,
      isCreateGroupOpen: false,
      isCreatedGroupOpen: false,
      isCreatedAddPeopleOpen: false,
      isChatScreenOpen: false,
      isEditGroupOpen: false,
      isEditAddPeopleOpen: false,
      isWishlistPageOpen: false,
      isGroupInfoPageOpen: false,
    });
  };

  openCreatePage = () => {
    this.setState({
      ...this.state,
      isCreateGroupOpen: true,
      isFirstPageOpen: false,
      isCreatedGroupOpen: false,
      isCreatedAddPeopleOpen: false,
      isChatScreenOpen: false,
      isEditGroupOpen: false,
      isEditAddPeopleOpen: false,
      isWishlistPageOpen: false,
      isGroupInfoPageOpen: false,
    });
  };

  openChatPage = () => {
    this.setState({
      ...this.state,
      // chatGroupId: group.id,
      // chatGroupName: group.attributes.name,
      // newChatGroupName: group.attributes.name,
      // searchMember: "",
      // searchMembersList: [],
      // invitedMembersList: [],
      isGroupNameEditable: false,
      isSettingOpen: false,
      isChatScreenOpen: true,
      isFirstPageOpen: false,
      isCreateGroupOpen: false,
      isCreatedGroupOpen: false,
      isCreatedAddPeopleOpen: false,
      isEditGroupOpen: false,
      isEditAddPeopleOpen: false,
      isWishlistPageOpen: false,
      isGroupInfoPageOpen: false,
    });
  };

  openCreatedPage = () => {
    this.setState({
      ...this.state,
      isCreateGroupOpen: false,
      isFirstPageOpen: false,
      isCreatedGroupOpen: true,
      isCreatedAddPeopleOpen: false,
      isChatScreenOpen: false,
      isEditGroupOpen: false,
      isEditAddPeopleOpen: false,
      isWishlistPageOpen: false,
      isGroupInfoPageOpen: false,
      searchMember: '',
      searchMembersList: []
    });
  };

  openEditPage = () => {
    this.setState({
      ...this.state,
      isChatScreenOpen: false,
      isEditGroupOpen: true,
      isFirstPageOpen: false,
      isCreateGroupOpen: false,
      isCreatedGroupOpen: false,
      isCreatedAddPeopleOpen: false,
      isEditAddPeopleOpen: false,
      isWishlistPageOpen: false,
      isGroupInfoPageOpen: false,
      searchMember: '',
      searchMembersList: []
    });
  };

  openCreatedAddPeoplePage = () => {
    this.setState({
      ...this.state,
      isChatScreenOpen: false,
      isEditGroupOpen: false,
      isFirstPageOpen: false,
      isCreateGroupOpen: false,
      isCreatedGroupOpen: false,
      isCreatedAddPeopleOpen: true,
      isEditAddPeopleOpen: false,
      isWishlistPageOpen: false,
      isGroupInfoPageOpen: false,
    });
  };

  openEditAddPeoplePage = () => {
    this.setState({
      ...this.state,
      isChatScreenOpen: false,
      isEditGroupOpen: false,
      isFirstPageOpen: false,
      isCreateGroupOpen: false,
      isCreatedGroupOpen: false,
      isCreatedAddPeopleOpen: false,
      isWishlistPageOpen: false,
      isEditAddPeopleOpen: true,
      isGroupInfoPageOpen: false,
    });
  };

  openWishlistPage = () => {
    this.setState({
      ...this.state,
      isChatScreenOpen: false,
      isEditGroupOpen: false,
      isFirstPageOpen: false,
      isCreateGroupOpen: false,
      isCreatedGroupOpen: false,
      isCreatedAddPeopleOpen: false,
      isEditAddPeopleOpen: false,
      isWishlistPageOpen: true,
      isGroupInfoPageOpen: false,
    });
  };

  openGroupInfoPage = () => {
    this.setState({
      ...this.state,
      isChatScreenOpen: false,
      isEditGroupOpen: false,
      isFirstPageOpen: false,
      isCreateGroupOpen: false,
      isCreatedGroupOpen: false,
      isCreatedAddPeopleOpen: false,
      isEditAddPeopleOpen: false,
      isWishlistPageOpen: false,
      isGroupInfoPageOpen: true,
    });
  };

  handleDialog = () => {
    this.setState({
      ...this.state,
      isExitGroupDialogOpen: !this.state.isExitGroupDialogOpen,
    });
  };

  // Remove State
  removeState = () => {
    this.setState(
      {
        ...this.state,
        isMinimize: false,
        groupName: "",
        createdGroupId: "",
        groupMembers: [],
        isSettingOpen: false,
        chatGroupName: "",
        newChatGroupName: "",
        chatGroupId: "",
        groupCreatorId: "",
        isGroupNameEditable: false,

        searchMember: "",
        searchMembersList: [],
        invitedMembersList: [],

        wishlistList: [],
      },
      () => {
        this.openFirstPage();
      }
    );
  };


  handleOpenMessagePopup = () => {
    this.setState({ messagePopup: true })
  };

  handleCloseMessagePopup = () => {
    this.setState({ messagePopup: false })
  };


  // Customizable Area End
}
