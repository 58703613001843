import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
// Customizable Area End
import React, { Component } from "react";
import { VendorAddAddressModel } from "../../../framework/src/Interfaces/IAddress";
export const configJSON = require("./config");
import moment from "moment";
import { toast } from "react-toastify";
export interface Props {
  navigation: any;
  id: number;
  mode: string;
  // Customizable Area Start
  classes: any;
  callbackShopWithFriend: Function;
  // Customizable Area End
}

interface S {
  txtSavedValue: string;
  token: string | null;
  data: any;
  showNewAddressDialog: boolean;
  showEditAddressDialog: boolean
  // Customizable Area Start
  nameError: any;
  mobileError: any;
  addressError: any;
  townError: any;
  pincodeError: any;
  cityError: any;
  stateError: any;
  save_addError: any;
  otpPhoneError: any
  otpEmailError: any
  mode: string
  addressData: any;
  state: string;
  city: string;
  mobile: any;
  email: string;
  town: string;
  id: number | null;
  name: string;
  address: string;
  pincode: any;
  save_add: string;
  saveAddress: string;
  // addressID: number | null;
  priceDetailsData: any;
  addNewDeliveryAddress: any;
  activeCartId: any;
  deliveryAddressId: any;
  placeOrderLoader: boolean,
  deleteAddressId: any,
  addressId: any,
  PhoneVerify: boolean,
  MailVerify: boolean
  editName: any
  editPhoneNumber: any
  editAddress: any
  editTown: any
  editZipCode: any
  editCity: any
  editState: any
  editSaveAddressAs: any;
  editAddressResponse: any;
  profileDetail: any;
  editProfileResponse: any;
  editProfileName: any
  editProfileemail: any
  editProfilePhoneNumber: any
  editProfileDateOfBirth: any
  editProfileGender: any
  isDelete: any
  editProfileNameError: any
  editProfileEmailError: any;
  editProfilePhoneError: any
  editProfileDateOfBirthError: any;
  verifyEmail: any;
  verifyPhone: any;
  emailOtpData: any;
  phoneOtpData: any;
  otpPinEmail: any;
  otpPinPhone: any;
  emailVerifyToken: any;
  PhoneVerifyToken: any;
  verifyEmailData: any;
  verifyPhoneData: any;
  otpTimerEmail: any;
  otpTimerPhone: any;
  resendActivateEmail: boolean;
  resendActivatePhone: boolean;
  addressBookLoader: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomerAddressController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiPhoneLoginCallId: any;
  addressCallId: any = null;
  getaddressCallId: any = null;
  DelAddressCallId: any = null;
  // addNewAddressCallId: any = null;
  EditAddressCallId: any = null;
  EidthAddressCallId: any = null;
  priceDetailsDataCallId: any;
  addNewAddressCallId: any;
  editAddressCallId: any;
  deliveryAddressSelectedForOrderCallId: any;
  placeOrderCallId: any;
  ActiveCartCallId: any;
  getProfileId: any;
  editProfileCallId: any;
  emailOtpCallId: any;
  phoneOtpCallId: any;
  emailVerifyCallId: any
  PhoneVerifyCallId: any;
  myRef: React.RefObject<HTMLDivElement>;

  // Customizable Area End

  public intialValues: VendorAddAddressModel = {
    name: '',
    mobile: '',
    email: '',
    address: '',
    town: '',
    city: '',
    state: '',
    pincode: ''
  };

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.myRef = React.createRef()
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    // const result = msg.getData('id');

    // const result = msg.getData('id');

    // console.log("props.id::::", props);
    this.state = {
      txtSavedValue: 'A',
      token: '',
      data: [],
      showNewAddressDialog: false,
      showEditAddressDialog: false,
      // Customizable Area Start
      nameError: "",
      mobileError: "",
      addressError: "",
      townError: "",
      pincodeError: "",
      cityError: "",
      stateError: "",
      save_addError: "",
      otpPhoneError: "",
      otpEmailError: "",
      mode: '',
      addressData: [],
      // editAddress: {},
      state: '',
      city: '',
      mobile: '',
      email: '',
      town: '',
      id: this.props.id || null,
      name: '',
      address: '',
      pincode: '',
      save_add: 'home',
      saveAddress: '',
      priceDetailsData: [],
      addNewDeliveryAddress: [],
      activeCartId: [],
      deleteAddressId: "",
      deliveryAddressId: "",
      placeOrderLoader: false,
      addressId: "",
      PhoneVerify: false,
      MailVerify: false,
      editName: "",
      editPhoneNumber: "",
      editAddress: "",
      editTown: "",
      editZipCode: "",
      editCity: "",
      editState: "",
      editSaveAddressAs: "",
      editAddressResponse: [],
      profileDetail: [],
      editProfileResponse: [],
      editProfileName: "",
      editProfileemail: "",
      editProfilePhoneNumber: localStorage.getItem("phone"),
      editProfileDateOfBirth: "",
      editProfileGender: "",
      isDelete: false,
      editProfileNameError: "",
      editProfileEmailError: "",
      editProfilePhoneError: "",
      editProfileDateOfBirthError: "",
      verifyEmail: false,
      verifyPhone: false,
      emailOtpData: "",
      phoneOtpData: [],
      otpPinEmail: "",
      otpPinPhone: "",
      emailVerifyToken: "",
      PhoneVerifyToken: "",
      verifyEmailData: "",
      verifyPhoneData: "",
      otpTimerEmail: "",
      otpTimerPhone: "",
      resendActivateEmail: false,
      resendActivatePhone: false,
      addressBookLoader: false


      //addressID: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    const msg = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    const data = msg.getData(
      getName(MessageEnum.VendorAddressDataMessage)
    );
    // console.log("constructor data::", data);
    // this.send(msg);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.myRef.current?.scrollIntoView({ behavior: 'auto' })
    // token
    // console.log('----->> token');
    try {
      const localToken = await localStorage.getItem("authToken")
      // console.log(localToken, "tokentoken")

      this.setState({ token: localToken });
      this.addressCall()
      this.getProfile()



    } catch (e) {
      // error reading value
    }
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);


    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      // console.log("navigation payload:::")
      const data = message.getData(
        getName(MessageEnum.VendorAddressDataMessage)
      );
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (apiRequestCallId && responseJson) {
        if (
          apiRequestCallId === this.addressCallId ||
          apiRequestCallId === this.emailVerifyCallId ||
          apiRequestCallId === this.PhoneVerifyCallId ||
          apiRequestCallId === this.getProfileId ||
          apiRequestCallId === this.emailOtpCallId ||
          apiRequestCallId === this.phoneOtpCallId ||
          apiRequestCallId === this.editProfileCallId ||
          apiRequestCallId === this.editAddressCallId ||
          apiRequestCallId === this.DelAddressCallId
        ) {
          this.receiveBlock1(apiRequestCallId, responseJson);
        }
      }

      if (responseJson?.errors && apiRequestCallId !== this.editProfileCallId) {
        this.parseApiErrorResponse(responseJson);
      }

      if (errorReponse) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End

  }

  receiveBlock1 = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.addressCallId) {
      this.addressApiResponse(responseJson);
    } else if (apiRequestCallId === this.emailVerifyCallId) {
      this.emailVerifyApiResponse(responseJson);
    } else if (apiRequestCallId === this.PhoneVerifyCallId) {
      this.phoneVerifyApiResponse(responseJson);
    } else if (apiRequestCallId === this.getProfileId) {
      this.getProfileApiResponse(responseJson);
    } else if (apiRequestCallId === this.emailOtpCallId) {
      this.emailOtpApiResponse(responseJson);
    } else if (apiRequestCallId === this.phoneOtpCallId) {
      this.phoneOtpApiResponse(responseJson);
    } else if (apiRequestCallId === this.editProfileCallId) {
      this.editProfileApiResponse(responseJson);
    } else if (apiRequestCallId === this.editAddressCallId) {
      this.editAddressApiResponse(responseJson);
    } else if (apiRequestCallId === this.DelAddressCallId) {
      this.deleteAddressApiResponse(responseJson);
    }
  }

  addressApiResponse = (responseJson: any) => {
    this.addressCallId = null;

    if (responseJson?.data) {
      this.setState({ addressData: responseJson.data });
      this.setState({ addressBookLoader: false })
    }
  }

  emailVerifyApiResponse = (responseJson: any) => {
    this.emailVerifyCallId = null;
    if (responseJson?.data) {
      this.setState({ verifyEmailData: responseJson }, () => {
        this.setState({ MailVerify: false })
        this.getProfile()
      });
    }

    if (responseJson?.errors && responseJson?.errors?.length) {
      const otpErr: any = responseJson.errors[0].pin
      this.setState({ otpEmailError: otpErr })
    }
  }

  phoneVerifyApiResponse = (responseJson: any) => {
    this.PhoneVerifyCallId = null;

    if (responseJson?.data) {
      this.setState({ verifyEmailData: responseJson }, () => {
        this.setState({ PhoneVerify: false })
        this.getProfile()
      });
    }

    if (responseJson?.errors && responseJson?.errors?.length) {
      const otpErr: any = responseJson.errors[0].pin
      this.setState({ otpPhoneError: otpErr })
    }
  }

  getProfileApiResponse = (responseJson: any) => {
    this.getProfileId = null;

    if (responseJson?.data) {

      this.setState({ profileDetail: responseJson.data },
        () => {
          localStorage.setItem("phone", this.state.profileDetail?.attributes?.phone_number); this.setState(
            {
              editProfileName: this.state.profileDetail?.attributes?.name,
              editProfileemail: this.state.profileDetail?.attributes?.email,
              // editProfilePhoneNumber:this.state.profileDetail.attributes?.phone_number,
              editProfileDateOfBirth: moment(this.state.profileDetail?.attributes?.date_of_birth).format("DD-MM-YYYY"),
              editProfileGender: this.state.profileDetail?.attributes?.gender,
              verifyPhone: this.state.profileDetail?.attributes?.phone_verified,
              verifyEmail: this.state.profileDetail?.attributes?.email_verified
            }
          )
        }
      );
      // console.log('//////////////profile detail data------------------------------------------------------', this.state.profileDetail);

    }
  }

  emailOtpApiResponse = (responseJson: any) => {
    this.emailOtpCallId = null;
    if (responseJson) {
      this.setState({ emailOtpData: responseJson });
      this.setState({ emailVerifyToken: this.state.emailOtpData.token })
    }
  }

  phoneOtpApiResponse = (responseJson: any) => {
    this.phoneOtpCallId = null;
    if (responseJson) {
      this.setState({ phoneOtpData: responseJson });
      this.setState({ PhoneVerifyToken: this.state.phoneOtpData.token })
    }
  }

  editProfileApiResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ editProfileResponse: responseJson.data });
      // this.setState({ shoppingCartloading: false });
      // console.log('//////////////editProfileResponse------------------------------------------------------', this.state.editProfileResponse);
      // this.getProfile()
      this.props.navigation.navigate("CustomerMyProfile")
      localStorage.removeItem("phone");

    }
    console.log("response:::==>>>>", responseJson)
    if (responseJson?.errors?.email == "email has already been taken") {
      this.setState({ editProfileEmailError: responseJson?.errors?.email })
    }
    if (responseJson?.errors?.fullPhoneNumber == "fullPhoneNumber has already been taken") {
      this.setState({ editProfilePhoneError: "mobile number has already been taken" })
    }
  }

  editAddressApiResponse = (responseJson: any) => {
    this.editAddressCallId = null;
    if (responseJson?.data) {
      this.setState({ editAddressResponse: responseJson.data });
      this.addressCall();
      this.handleCloseEditAddressDialog()
    }
  }

  deleteAddressApiResponse = (responseJson: any) => {
    this.DelAddressCallId = null;

    if (responseJson?.message == "Address deleted successfully") {
      this.setState({
        isDelete: false
      })
      this.addressCall()
    } else if (responseJson?.error) {
      this.setState({
        isDelete: false
      })
      toast.error(responseJson?.error);
      this.addressCall()
    }
  }

  getProfile() {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token

    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;
  }


  // deleteAddress starat 
  deleteAddress() {

    // console.log("---------------->>>>>>> showCategories");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    // console.log("$$$$$$$$$$$$$", apiRequest);
    this.DelAddressCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAddressAPiEndPoint.concat(this.state.addressId)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DeleteMethodType
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;


  }

  //   deleteAddress end 

  addressCall() {
    // console.log('---------------->>>>>>> address');
    this.setState({ addressBookLoader: true })
    const header = {
      "Content-Type": "application/json",
      token: this.state.token
      // token: ''
    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addressCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addressAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;
  }




  handleCloseNewAddressDialog = () => {
    this.setState({
      showNewAddressDialog: false,
      nameError: "",
      mobileError: "",
      addressError: "",
      townError: "",
      pincodeError: "",
      cityError: "",
      stateError: "",
      save_addError: "",
    });
  };

  handleCloseEditAddressDialog = () => {
    this.setState({
      showEditAddressDialog: false,
      state: '',
      city: '',
      mobile: '',
      email: '',
      town: '',
      name: '',
      address: '',
      pincode: '',
      nameError: "",
      mobileError: "",
      addressError: "",
      townError: "",
      pincodeError: "",
      cityError: "",
      stateError: "",
      save_addError: "",
    });
  };
  addNewAddress() {

    if (!this.state.name || !this.state.mobile || !this.state.town || !this.state.pincode || !this.state.city || !this.state.state || !this.state.save_add || (this.state.address.length < 10 && this.state.address.length > 0) || !this.state.address) {
      if (!this.state.name) {
        this.setState({ nameError: "Please fill name field" })
      } else { this.setState({ nameError: "" }) }
      if (!this.state.mobile) {

        this.setState({ mobileError: "Please fill number field" })
      }
      else if (this.state.mobile.length != 10) {
        this.setState({ mobileError: "Please enter valid number" })
      }
      else { this.setState({ mobileError: "" }) }

      if (this.state.address.length < 10 && this.state.address.length > 0 || !this.state.address) {

        if (this.state.address.length < 10 && this.state.address.length > 0) {
          this.setState({ addressError: "address is less than 10 characters" })
        }
        else if (!this.state.address) {

          this.setState({ addressError: "Please fill address field" })
        } else { this.setState({ addressError: "" }) }
      }

      if (!this.state.town) {

        this.setState({ townError: "Please fill town field" })
      } else { this.setState({ townError: "" }) }


      if (!this.state.pincode) {

        this.setState({ pincodeError: "Please fill pincode field" })
      } else { this.setState({ pincodeError: "" }) }


      if (!this.state.city) {

        this.setState({ cityError: "Please fill city field" })
      } else { this.setState({ cityError: "" }) }


      if (!this.state.city) {

        this.setState({ cityError: "Please fill city field" })
      } else { this.setState({ cityError: "" }) }


      if (!this.state.state) {

        this.setState({ stateError: "Please fill state field" })
      } else { this.setState({ stateError: "" }) }


      if (!this.state.save_add) {

        this.setState({ save_addError: "Please fill save address as field" })
      } else { this.setState({ save_addError: "" }) }

    }

    else {
      this.handleCloseNewAddressDialog()


      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
      };

      const data = {
        name: this.state.name,
        phone_number: this.state.mobile,
        address: this.state.address,
        town: this.state.town,
        zip_code: this.state.pincode,
        city: this.state.city,
        state: this.state.state,
        save_address_as: this.state.save_add,
        // email: "nomail@gmail.com",
        country: "india",
        is_default: true
      };

      const httpBody =
        data

      // console.log("--------------->>>>>>>>>>", httpBody);


      const apiRequest = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addNewAddressCallId = apiRequest.messageId;
      // console.log(this.addNewAddressCallId)
      apiRequest.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addNewAddressAPiEndPoint
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePost
      );
      this.setState({
        name: "", city: "", town: "", mobile: "", pincode: "", address: "", state: "",

        nameError: "", cityError: "", townError: "", mobileError: "", pincodeError: "", addressError: "", stateError: ""
      })
      runEngine.sendMessage(apiRequest.id, apiRequest);
      return true;

    }

  }

  ValidateEmail = (text: string): boolean => {
    let reg = /^\w+([.-]?\w+)([.-]?\w+)@\w+([.-]?\w+)(.\w\w+)+$/
    if (reg.test(text) === false) {
      return false;
    }
    else {
      return true;
    }

  }

  // edit address start
  EditProfile() {


    if (!this.ValidateEmail(this.state.editProfileemail) || !this.state.editProfileName || !this.state.editProfileemail || !this.state.editProfilePhoneNumber || this.state.editProfilePhoneNumber.length != 10 || !this.state.editProfileDateOfBirth || !this.state.editProfileGender) {

      if (!this.state.editProfileName) {

        this.setState({ editProfileNameError: "Please enter your name" })
      } else { this.setState({ editProfileNameError: "" }) }

      if (!this.state.editProfileemail) {

        this.setState({ editProfileEmailError: "Please enter your email" })
      } else if (!this.ValidateEmail(this.state.editProfileemail)) { this.setState({ editProfileEmailError: "Please enter valid email" }) }
      else { this.setState({ editProfileEmailError: "" }) }


      if (!this.state.editProfilePhoneNumber) {

        this.setState({ editProfilePhoneError: "Please enter your phone number" })

      } else if (this.state.editProfilePhoneNumber.length != 10) {

        this.setState({ editProfilePhoneError: "must be 10 digits" })

      } else { this.setState({ editProfilePhoneError: "" }) }


      if (!this.state.editProfileDateOfBirth) {

        this.setState({ editProfileDateOfBirthError: "Please enter your date of birth" })
      } else { this.setState({ editProfileDateOfBirthError: "" }) }

    }

    else {
      this.setState({ editProfilePhoneError: "" })
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
      };

      const data = {

        name: this.state.editProfileName,
        email: this.state.editProfileemail,
        phone_number: this.state.editProfilePhoneNumber,
        date_of_birth: this.state.editProfileDateOfBirth,
        gender: this.state.editProfileGender
      }


      const httpBody =
        data

      // console.log("--------------->>>>>>>>>>", httpBody);


      const apiRequest = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editProfileCallId = apiRequest.messageId;

      apiRequest.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.editProfileAPiEndPoint

      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.PatchMethodType
      );

      runEngine.sendMessage(apiRequest.id, apiRequest);
      return true;

    }

  }


  // edit address start
  EditAddress() {

    if (!this.state.editName || (!this.state.editPhoneNumber || this.state.editPhoneNumber.length != 10) || !this.state.editTown || !this.state.editZipCode || !this.state.editCity || !this.state.editState || !this.state.save_add || (this.state.editAddress.length < 10 && this.state.editAddress.length > 0) || !this.state.editAddress) {
      if (!this.state.editName) {
        this.setState({ nameError: "Please fill name field" })
      } else { this.setState({ nameError: "" }) }
      if (!this.state.editPhoneNumber) {

        this.setState({ mobileError: "Please fill number field" })
      } else if (this.state.editPhoneNumber.length != 10) { this.setState({ mobileError: "Please enter valid number" }) }
      else { this.setState({ mobileError: "" }) }
      if (this.state.editAddress.length < 10 && this.state.editAddress.length > 0 || !this.state.editAddress) {

        if (this.state.editAddress.length < 10 && this.state.editAddress.length > 0) {
          this.setState({ addressError: "address is less than 10 characters" })
        }
        else if (!this.state.editAddress) {

          this.setState({ addressError: "Please fill address field" })
        } else { this.setState({ addressError: "" }) }
      }

      if (!this.state.editTown) {

        this.setState({ townError: "Please fill town field" })
      } else { this.setState({ townError: "" }) }


      if (!this.state.editZipCode) {

        this.setState({ pincodeError: "Please fill pincode field" })
      } else { this.setState({ pincodeError: "" }) }


      if (!this.state.editCity) {

        this.setState({ cityError: "Please fill city field" })
      } else { this.setState({ cityError: "" }) }


      if (!this.state.editCity) {

        this.setState({ cityError: "Please fill city field" })
      } else { this.setState({ cityError: "" }) }


      if (!this.state.editState) {

        this.setState({ stateError: "Please fill state field" })
      } else { this.setState({ stateError: "" }) }


      if (!this.state.save_add) {

        this.setState({ save_addError: "Please fill save address as field" })
      } else { this.setState({ save_addError: "" }) }

    }

    else {
      this.handleCloseNewAddressDialog()
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
      };

      const data = {
        name: this.state.editName,
        phone_number: this.state.editPhoneNumber,
        address: this.state.editAddress,
        town: this.state.editTown,
        zip_code: this.state.editZipCode,
        city: this.state.editCity,
        state: this.state.editState,
        save_address_as: this.state.editSaveAddressAs,
        // email: "nomail@gmail.com",
        country: "india",
        is_default: true
      };

      const httpBody =
        data

      // console.log("--------------->>>>>>>>>>", httpBody);


      const apiRequest = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editAddressCallId = apiRequest.messageId;

      apiRequest.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.editAddressAPiEndPoint.concat(this.state.addressId)
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      apiRequest.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.PatchMethodType
      );
      runEngine.sendMessage(apiRequest.id, apiRequest);
      return true;

    }

  }

  // edit  address end 
  sendOtpEmail() {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emailOtpCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailOtpAPiEndPoint.concat(this.state.editProfileemail)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;


  }


  sendOtpPhone() {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };



    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.phoneOtpCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.phoneOtpAPiEndPoint.concat(this.state.editProfilePhoneNumber)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethodType
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;


  }

  verifyEmail() {
    // console.log("tokenverify", this.state.emailVerifyToken, "otp", this.state.otpPinEmail)

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.emailVerifyToken,
    };

    const data = {
      pin: this.state.otpPinEmail
    };

    const httpBody =
      data

    // console.log("--------------->>>>>>>>>>", httpBody);


    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emailVerifyCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailVerifyAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostMethodType
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  verifyPhone() {
    // console.log("tokenverify", this.state.PhoneVerifyToken, "otp", this.state.otpPinPhone)

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.PhoneVerifyToken,
    };

    const data = {
      pin: this.state.otpPinPhone
    };

    const httpBody =
      data

    // console.log("--------------->>>>>>>>>>", httpBody);


    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.PhoneVerifyCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PhoneVerifyAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostMethodType
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }


  isStringNullOrBlank(str: string | number) {
    return str === null || str.toString().length === 0;
  }


  addThisDeliveryAddress() {

    this.addNewAddress();
    setTimeout(() => this.addressCall(), 2000)


  }

  handleClose() {
    this.setState({
      isDelete: false
    });
  }


  otpTimerEmail() {

    var timeleft = 30;
    this.setState({ otpTimerEmail: timeleft })

    var downloadTimer = setInterval(() => {
      var updatedtime = this.state.otpTimerEmail - 1;
      this.setState({ otpTimerEmail: updatedtime })
      if (this.state.otpTimerEmail <= 0) {
        clearInterval(downloadTimer);
        this.setState({ resendActivateEmail: true })
      }
    }, 1000);


  }
  otpTimerPhone() {

    var timeleft = 30;
    this.setState({ otpTimerPhone: timeleft })

    var downloadTimer = setInterval(() => {
      var updatedtime = this.state.otpTimerPhone - 1;
      this.setState({ otpTimerPhone: updatedtime })
      if (this.state.otpTimerPhone <= 0) {
        this.setState({ resendActivatePhone: true })
        clearInterval(downloadTimer);
      }

    }, 1000);


  }



}