import React from "react";
import { InputLabel, Input, FormControl } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import BackArrow from "@material-ui/icons/ArrowBack";
import AffiliateFormController, {
  Props,
  configJSON,
} from "./AffiliateFormController.web";
const cross = require("../assets/cross.png");
import { withStyles } from "@material-ui/core/styles";
import { AffiliateStyleWeb } from "./AffiliateFormStyle.web";

export class AffiliateForm extends AffiliateFormController {
  myRef: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.myRef = React.createRef();
    // Customizable Area End
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div ref={this.myRef} className={classes.pageOuter}>
          <div>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      width: "760px",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    {" "}
                    <Link style={{ color: "black" }} href="/Aboutus">
                      <BackArrow />
                    </Link>
                    <h2>Affiliate Inquiry</h2>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="block-wrapper">
                    <div className="block-wrapper-inner">
                      <h4>Basic Information</h4>

                      <div className="form-group">
                        <InputLabel htmlFor="txtInputName">
                          Name<sup>*</sup>
                        </InputLabel>
                        <FormControl>
                          <Input
                            id="txtInputName"
                            value={this.state.name}
                            onChange={(e) =>
                              this.setState({ name: e.target.value })
                            }
                          />
                        </FormControl>
                        <p className="error-msg">{this.state.nameError}</p>
                      </div>
                      <div className="form-group">
                        <InputLabel htmlFor="txtInputEmail">
                          Email<sup>*</sup>
                        </InputLabel>
                        <FormControl>
                          <Input
                            id="txtInputEmail"
                            value={this.state.email}
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                          />
                        </FormControl>
                        <p className="error-msg">{this.state.emailError}</p>
                      </div>
                      <div className="form-group">
                        <InputLabel htmlFor="txtInputPhone">
                          Phone Number<sup>*</sup>
                        </InputLabel>
                        <FormControl>
                          <Input
                            type="number"
                            id="txtInputPhone"
                            value={this.state.phone}
                            onChange={(e) =>
                              this.setState({ phone: e.target.value })
                            }
                          />
                        </FormControl>
                        <p className="error-msg">{this.state.phoneError}</p>
                      </div>

                      <div className="inner-title-wrapper">
                        <h4>Where you would promote the product:</h4>
                        <p>Add Channel</p>
                      </div>
                      {this.state.channelArrayEmpty ? (
                        <>
                          <h1>no channels </h1>{" "}
                        </>
                      ) : (
                        <>
                          {this.state.channelArray.map(
                            (item: any, index: any) => (
                              <>
                                <div className="inner-close-title">
                                  <h4>Channel {index + 1} </h4>
                                  <img
                                    className="close-img"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.onRemoveChannelView(index)
                                    }
                                    src={cross}
                                  />
                                </div>

                                <div className="form-group">
                                  <InputLabel htmlFor="channel">
                                    Channel Name
                                  </InputLabel>
                                  <FormControl>
                                    <Input
                                    id="channel_name_input"
                                      value={
                                        item.channel_name || this.state.channel
                                      }
                                      onChange={(e) => {
                                        this.addChannelInput(
                                          e.target.value,
                                          item,
                                          "channel_name"
                                        );
                                      }}
                                    />
                                  </FormControl>
                                </div>
                                <div className="form-group">
                                  <InputLabel htmlFor="url">URL</InputLabel>
                                  <FormControl>
                                    <Input
                                    id="channel_url_input"
                                      value={item.url || this.state.url}
                                      onChange={(e) =>
                                        this.addChannelInput(
                                          e.target.value,
                                          item,
                                          "url"
                                        )
                                      }
                                    />
                                  </FormControl>
                                </div>

                                {/* drop down start  */}
                                <div className="form-group">
                                  <InputLabel htmlFor="standard-adornment-amount">
                                    {" "}
                                    social
                                  </InputLabel>
                                  <Select
                                    onChange={(e) => {
                                      this.setState;
                                      this.addChannelInput(
                                        e.target.value,
                                        item,
                                        "social"
                                      );
                                    }}
                                    value={item.social || this.state.social}
                                    labelId="product-category"
                                    id="demo-simple-select"
                                    displayEmpty
                                  >
                                    {this.state.languageList.map(
                                      (option: any, index: any) => (
                                        <MenuItem
                                          value={option}
                                          key={index}
                                          id={index}
                                        >
                                          {option}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </div>
                                <div className="form-group">
                                  <InputLabel htmlFor="type_here">
                                    Type here
                                  </InputLabel>
                                  <FormControl>
                                    <Input
                                    id="channel_type_here_input"
                                      value={
                                        item.type_here || this.state.type_here
                                      }
                                      onChange={(e) => {
                                        this.setState;
                                        this.addChannelInput(
                                          e.target.value,
                                          item,
                                          "type_here"
                                        );
                                      }}
                                    />
                                  </FormControl>
                                </div>
                              </>
                            )
                          )}
                        </>
                      )}
                      <div className="outline-btn-wrapper">
                        <Button
                          variant="outlined"
                          className="outline-btn"
                          type="button"
                          onClick={() => this.onAddChannelView()}
                        >
                          Add More Channel
                        </Button>
                      </div>
                      <div className="send-btn-wrapper">
                        <Button variant="contained" className="black-btn" type="button" onClick={() => {this.doAffiliate();this.myRef.current?.scrollIntoView({behavior: 'auto'})}}>Send</Button>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(AffiliateStyleWeb)(AffiliateForm);
