import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import querystring from "query-string";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  contentPerPage: number;
  number: number;
  counter: number;
  startValue: number;
  endValue: number;

  loading: boolean;
  products: any[];
  filter: any;

  category: any[];
  categoryValue: any[];
  brand: any[];
  brandValue: any[];
  maxValue: number;
  price: any[];
  priceValue: any[];
  color: any[];
  colorValue: any[];
  occasion: any[];
  occasionValue: any[];

  sortBy: any;

  currentPage: number;
  pagination: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ItemListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  ProductsCallId: any;
  AddToWishCallId: any;
  RemoveToWishCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      number: 1,
      contentPerPage: 5,
      counter: 1,
      startValue: 0,
      endValue: 5,

      loading: true,
      products: [],
      filter: null,

      category: [],
      categoryValue: [],
      brand: [],
      brandValue: [],
      maxValue: 0,
      price: [0, 0],
      priceValue: [0, 0],
      color: [],
      colorValue: [],
      occasion: [],
      occasionValue: [],

      sortBy: "",

      currentPage: 1,
      pagination: null,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    // Get Products
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ProductsCallId !== null &&
      this.ProductsCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.ProductsCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        if(this.state.maxValue === 0){
          this.setState({
            ...this.state,
            maxValue: this.state.maxValue === 0 ? responseJson.filter? responseJson?.filter?.max_price[0] : 0 : 0,
            price:[0,  responseJson?.filter ? responseJson?.filter?.max_price[0] : 0],
          });
        }
        this.setState({
          ...this.state,
          loading: false,
          products: responseJson.data,
          filter: responseJson.data.length > 0 && responseJson.filter,
          pagination: responseJson.meta,
          currentPage: responseJson.meta?.current_page ? responseJson.meta.current_page : 1,
        });
      } else {
        this.setState({
          ...this.state,
          loading: false,
          products: [],
          filter: [],
          currentPage: 1,
          pagination: null
        });
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Add To Wishlist
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.AddToWishCallId !== null &&
      this.AddToWishCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.AddToWishCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Remove From Wishlist
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.RemoveToWishCallId !== null &&
      this.RemoveToWishCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.RemoveToWishCallId = null;
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        //Need To send Login token message to save for future call
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }

    // Customizable Area End
  }

  useQuery() {
    return new URLSearchParams(window.location.search);
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.colorValue.length !== this.state.colorValue.length ||
      prevState.categoryValue.length !== this.state.categoryValue.length ||
      prevState.brandValue.length !== this.state.brandValue.length ||
      prevState.occasionValue.length !== this.state.occasionValue.length ||
      prevState.sortBy !== this.state.sortBy ||
      prevState.currentPage !== this.state.currentPage ||
      prevState.priceValue !== this.state.priceValue
    ) {
      this.getProducts();
    }
  }

  // Component Did Mount
  async componentDidMount() {
    this.getProducts();
  }

  // Get Products
  getProducts = () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    const {
      colorValue,
      categoryValue,
      brandValue,
      brand,
      occasionValue,
      sortBy,
      priceValue
    } = this.state;

    let brandNames: string[] = brand && 
                                brand.length ?
                                brand.map((b) => b.unit) : [];
    const brandNamesByCommaSeprate = brandNames.join(",");
    
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem("authToken")
        ? localStorage.getItem("authToken")
        : "",
    };

    const param = querystring.parse(window.location.search);

    let productParam;
    if('sub_sub_category_id' in param){
      productParam = configJSON.productListingAPIEndPoint + "?sub_sub_category_id=" + param.sub_sub_category_id;
    } else if('sub_category_id' in param){
      productParam = configJSON.productListingAPIEndPoint + "?sub_category_id=" + param.sub_category_id;
    } else if('category_id' in param){
      productParam = configJSON.productListingAPIEndPoint + "?category_id=" + param.category_id;
    } else if('search' in param){
      productParam = configJSON.productListingAPIEndPoint + "?search=" + param.search;
    } else if('cta_id' in param){
      productParam = configJSON.productListingAPIEndPoint + "?cta_id=" + param.cta_id;
    } else if('shop_by_edit_id' in param){
      productParam = configJSON.productListingAPIEndPoint + "?shop_by_edit_id=" + param.shop_by_edit_id;
    } else if('offer_id' in param){
      productParam = configJSON.productListingAPIEndPoint + "?sub_category_id=" + param.offer_id;
    }
    
    productParam = productParam + "&per_page=16";

    if (colorValue.length > 0) {
      productParam += `&color=${colorValue.toString()}`;
    }
    if (categoryValue.length > 0) {
      productParam += `&category_id=${categoryValue.toString()}`;
    }
    if (brandNamesByCommaSeprate) {
      productParam += `&brand_name=${brandNamesByCommaSeprate}`;
    }
    if (occasionValue.length > 0) {
      productParam += `&occasion=${occasionValue.toString()}`;
    }
    if (sortBy.length > 0) {
      productParam += `&requested_on=${sortBy}`;
    }
    if (priceValue[1] !== 0) {
      productParam += `&price=${priceValue[0]},${priceValue[1]}`;
    }

    productParam += `&page=${this.state.currentPage}`;

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.ProductsCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      productParam
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  };

  // Add To Wishlist
  AddWishList(data: any) {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const httpBody = {
      "catalogue_ids": [data]
    }

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.AddToWishCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddWishlistAPIEndPoint
    );

    const products = this.state.products.map((product) => {
      if (product.id === data) {
        product["attributes"]["is_wishlist"] = true;
      }
      return product;
    });

    this.setState({
      ...this.state,
      products: products,
    });

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Remove From Wishlist
  RemoveWishList(data: any) {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.RemoveToWishCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RemoveWishlistAPIEndPoint.concat(data)
    );

    const products = this.state.products.map((product) => {
      if (product.id === data) {
        product["attributes"]["is_wishlist"] = false;
      }
      return product;
    });

    this.setState({
      ...this.state,
      products: products,
    });

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Handle Pagination
  handlePagination = (value: any) => {
    this.setState({
      ...this.state,
      currentPage: Number(value),
    });
  };

  handlePaginationPrev = () => {
    this.setState({
      ...this.state,
      currentPage: this.state.currentPage - 1,
    });
  };

  handlePaginationNext = () => {
    this.setState({
      ...this.state,
      currentPage: this.state.currentPage + 1,
    });
  };

  // Handle Filter
  removeCategory = (category: any) => {
    const sCategory = this.state.category.filter((cat) => {
      return cat.id !== category.id;
    });
    const sCategoryValue = this.state.categoryValue.filter((id) => {
      return id !== category.id;
    });
    this.setState({
      ...this.state,
      category: sCategory,
      categoryValue: sCategoryValue,
    });
  };

  removeBrand = (brand: any) => {
    const sBrand = this.state.brand.filter((brnd) => {
      return brand.id !== brnd.id;
    });
    const sBrandValue = this.state.brandValue.filter((id) => {
      return id !== brand.id;
    });
    this.setState({
      ...this.state,
      brand: sBrand,
      brandValue: sBrandValue,
    });
  };

  removeColor = (color: any) => {
    const sColor = this.state.color.filter((sClr)=> { 
      return sClr.color !== color.color 
    });
    const sColorValue = this.state.colorValue.filter((sClr)=> { 
      return sClr !== color.color 
    });
    this.setState({
      ...this.state,
      color: sColor,
      colorValue: sColorValue
    });
  };

  removeOccasion = (ocason: any) => {
    const sOccasion = this.state.occasion.filter((occasion)=> { 
      return ocason.id !== occasion.id 
    });
    const sOccasionValue = this.state.occasionValue.filter((id)=> { 
      return id !== ocason.id 
    });
    this.setState({
      ...this.state,
      occasion: sOccasion,
      occasionValue: sOccasionValue
    });
  };

  // Customizable Area End
}
