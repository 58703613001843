import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    push_notification: boolean;
    email_notification: boolean;
    whatsapp_notification: boolean,
    // my code  
    filter: any;
    isOpenDateRangePicker: boolean,
    dateRange: any,
    startDate: null;
    endDate: null;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ReportsDownloadController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    GetVendorSettingsCallId: any;
    UpdateVendorSettingsCallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            push_notification: false,
            email_notification: false,
            whatsapp_notification: false,
            filter: "All",
            isOpenDateRangePicker: false,
            startDate: null,
            endDate: null,
            dateRange: [{
                startDate: new Date(),
                endDate: new Date(),
                key: "selection"
            }],

            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
        }

        // Customizable Area Start
        // Get
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.GetVendorSettingsCallId !== null &&
            this.GetVendorSettingsCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            this.GetVendorSettingsCallId = null;

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson.data) {
                this.setState({
                    ...this.state,
                    push_notification: responseJson.data.attributes.push_notification,
                    email_notification: responseJson.data.attributes.email_notification,
                    whatsapp_notification: responseJson.data.attributes.whatsapp_notification,
                });
            }

            var errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            } else {
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorResponse);
        }

        // Update
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.UpdateVendorSettingsCallId !== null &&
            this.UpdateVendorSettingsCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            this.UpdateVendorSettingsCallId = null;

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson.data) {
                this.setState({
                    ...this.state,
                    push_notification: responseJson.data.attributes.push_notification,
                    email_notification: responseJson.data.attributes.email_notification,
                    whatsapp_notification: responseJson.data.attributes.whatsapp_notification,
                });
            }

            var errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.meta && responseJson.meta.token) {
                runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            } else {
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorResponse);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        try {
            // this.getVendorSettings();
        } catch (error) { }
    }
    // date filter custom function 
    getOrderFilterByStartAndEndDate = async () => {
        // const orderStatus = this.state.tabName;
        const startDate = moment(this.state.dateRange[0].startDate).format('DD/MM/YYYY');
        const endDate = moment(this.state.dateRange[0].endDate).format('DD/MM/YYYY');
        console.log(startDate, endDate, "dates")
        // this.setState({ isLoading: true, orderList: [] });
        // this.getvendorOrderListApiCallId = await this.apiCall({
        //     contentType: configJSON.contentType,
        //     method: configJSON.httpGetMethod,
        //     endPoint: `${configJSON.searchOrderApiEndPoint}?search=${this.state.searchInput}&status=${orderStatus}&requested_on=${this.state.dayWiseOrder}&from_date=${startDate}&to_date=${endDate}&page=${this.state.currentPage}&per_page=${paginationCount}`,
        // });
    };
    // Get
    getVendorSettings = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: localStorage.getItem("authToken"),
        };

        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.GetVendorSettingsCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.SettingAPIEndPoint
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiGetMethodType
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;
    };

    // Update
    updateVendorSettings = () => {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: localStorage.getItem("authToken"),
        };

        const body = {
            push_notification: this.state.push_notification,
            email_notification: this.state.email_notification,
            whatsapp_notification: this.state.whatsapp_notification,
        };

        const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.UpdateVendorSettingsCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateSettingAPIEndPoint
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiPutMethodType
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;
    };
    // Customizable Area End
}
