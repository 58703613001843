// Object.defineProperty(exports, "__esModule", {
//   value: true
// });

// Customizable Area Start
// exports.validationApiContentType = "application/json";
// exports.validationApiMethodType = "GET";
// exports.exampleAPiEndPoint = "EXAMPLE_URL";
// exports.exampleAPiMethod = "POST";
// exports.exampleApiContentType = "application/json";
// exports.textInputPlaceHolder = "Enter Text";
// exports.labelTitleText = "Payments";
// exports.labelBodyText = "Payments Body";

// exports.btnExampleTitle = "CLICK ME";
// Customizable Area End

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";

exports.apiGetMethodType = "GET";
exports.apiPutMethodType = "PUT";

exports.SettingAPIEndPoint = "bx_block_notifications/notification_settings";
exports.updateSettingAPIEndPoint = "bx_block_notifications/notification_settings";
// Customizable Area End