// Object.defineProperty(exports, "__esModule", {
//   value: true
// });

// // Customizable Area Start
// exports.productApiContentType = "application/json";
// exports.apiMethodTypeGet = "GET";
// exports.productAPiEndPoint = "catalogue/catalogues";
// // Customizable Area End




Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypePut = "PUT";
exports.apiMethodTypeDELETE = "DELETE";
exports.productAPiEndPoint = "catalogue/catalogues";
//size data get
exports.sizeAPiEndPoint = "bx_block_catalogue/size_charts";
//categories
exports.apiCategoryAPiEndPoint = "bx_block_categories/categories_listing";
exports.apiSubCategoryAPiEndPoint =
  "bx_block_categories/sub_categories_listing?category_id=";
exports.apiSizeDeleteAPiEndPoint = "bx_block_catalogue/size_charts/";
exports.apiSizeAPiEndPoint = "bx_block_catalogue/size_charts";
exports.apiSizeUpdateAPiEndPoint = "bx_block_catalogue/size_charts/";
// RecentlyViewdsCallId
exports.apiRecentlyViewdAPiEndPoint =
  "bx_block_catalogue/catalogues/recent_view_product";
//apiBlogAPiEndPoint
exports.apiBlogAPiEndPoint = "bx_block_blogpostsmanagement/blogs";
exports.apiBrandAPiEndPoint = "catalogue/brands";
//categories
exports.validationApiMethodType = "GET";
// Categories
exports.AddWishlistAPIEndPoint = "bx_block_wishlist2/wishlist/add_to_wishlist?catalogue_id=";
exports.RemoveWishlistAPIEndPoint = "bx_block_wishlist2/wishlist/remove_to_wishlist";
exports.WishlistAPIEndPoint = "bx_block_wishlist2/wishlist/wishlisted_catalogue";
exports.PlaceOrderAPiEndPoint = "bx_block_order_management/orders";
exports.ActiveCartAPIEndPoint = "bx_block_shopping_cart/get_active_cart";
exports.ShoppingCartAPIEndPoint = "bx_block_shopping_cart/cart_items";
exports.RemoveShoppingBagAPIEndPoint = "bx_block_shopping_cart/cart_items";
exports.shoppingPriceAPIEndPoint = "bx_block_shopping_cart/price_detail_of_cart"
exports.couponsAPIEndPoint = "bx_block_coupons/find_coupons?catalogue_id="
exports.appliedcouponAPIEndPoint = "bx_block_order_management/orders/apply_coupon_new"
exports.RemoveappliedcouponAPIEndPoint = "bx_block_order_management/orders/remove_coupon_new"
exports.quantityUpdateAPIEndPoint = "bx_block_shopping_cart/update_cart_item_quantity?id="
exports.sizeUpdateAPIEndPoint = "bx_block_shopping_cart/update_cart_item_size/?id="
exports.loyaltyPointsAPIEndPoint = "bx_block_shopping_cart/loyalty_points";

// payment Api
exports.paymentAPIEndPoint = "bx_block_order_management/payments";
exports.fetchPaymentAPIEndPoint = "bx_block_order_management/fetch_payment";
// Customizable Area End

exports.loyaltyPointsAPIEndPoint = "bx_block_shopping_cart/loyalty_points"
exports.addressAPiEndPoint = "bx_block_order_management/addresses";
exports.addNewAddressAPiEndPoint = "bx_block_order_management/addresses";
exports.deliveryAddressSelectedForOrderAPiEndPoint = "order_management/orders/174/add_address_to_order"
exports.placeOrderAPiEndPoint = "bx_block_order_management/payments"
exports.ActiveCartAPIEndPoint = "bx_block_shopping_cart/get_active_cart";
exports.applyLoyaltyPointsAPIEndPoint = "bx_block_order_management/orders/use_loyalty_point?cart_id="
exports.removeLoyaltyPointsAPIEndPoint = "bx_block_order_management/orders/remove_loyalty_point?cart_id="
exports.PlaceExchangeOrderAPiEndPoint = "bx_block_order_management/exchange_product"
exports.getProfileAPiEndPoint = "account_block/account"
 // Customizable Area End