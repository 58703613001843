import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItems from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";
import { champion, searchIcon } from "./assets";
import Loader from "../../../components/src/Loader.web";
import { RadioGroup, Radio, FormControlLabel } from "@material-ui/core";
import {
  Menu,
  MenuItem,
  MenuButton,
  SubMenu,
} from "../../../../node_modules/@szhsin/react-menu";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";

import {
  Box,
  TextField,
  InputLabel,
  Input,
  Divider,
  FormControl,
} from "@material-ui/core";
import InventoryController from "./InventoryController.web";
import { configJSON } from "./InventoryController.web";
import PaginationComponent from "../../../components/src/shared/pagination";
import { PRODUCT_STATUS } from "../../../framework/src/Enum";

const styles = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "25px 0 70px",
    "& .btn-wrapper button": {
      marginRight: "30px",
      "@media (max-width:1199px)": {
        marginRight: "10px",
      },
    },
    "& .btn-wrapper button:last-child": {
      marginRight: "0",
    },
    "& .black-btn": {
      backgroundColor: "#121212",
      color: "#fff",
      borderRadius: "0",
      padding: "6px 10px",
      minWidth: "150px",
      minHeight: "40px",
      textTransform: "capitalize",
      boxShadow: "none",
      "@media (max-width:1199px)": {
        minWidth: "120px",
      },
    },
    "& .outline-btn": {
      "@media (max-width:1199px)": {
        minWidth: "120px",
      },
    },
    "& .menu-block .MuiIconButton-root": {
      padding: "0",
    },
    "& .menu-block .szh-menu": {
      right: "-20px",
      left: "auto !important",
    },
  },
};

export class Inventory extends InventoryController {
  constructor(props: any) {
    super(props);
    this.elementRef = React.createRef();
  }

  renderProductListing = () => {
    return (
      this.state.productList.map((product: any) => {
        const sale_price = product.attributes.sale_price
          ? product.attributes.sale_price
          : (product.attributes
            .catalogue_variants_attributes as any[]).length
            ? product.attributes.catalogue_variants_attributes[0]
              .attributes.sale_price
            : "";
        const maximum_retail_price = product.attributes
          .maximum_retail_price
          ? product.attributes.maximum_retail_price
          : (product.attributes
            .catalogue_variants_attributes as any[]).length
            ? product.attributes.catalogue_variants_attributes[0]
              .attributes.maximum_retail_price
            : "";
        const discount = parseFloat(((((maximum_retail_price -
          sale_price) *
          100) /
          maximum_retail_price) as unknown) as string).toFixed(2);
        const product_status = product.attributes.product_status; // enable : true , disable : false
        const status = product.attributes.status; // draft , published
        return (
          <>
            <Grid
              className="product-list"
              style={{
                backgroundColor: `${status === PRODUCT_STATUS.DRAFT || !product_status ? "#efefef" : "inherit"
                  }`,
              }}
            >
              <div className="product-list-inner">
                <div className="product-img">
                  <img
                    className="img-block"
                    src={
                      (product.attributes.galleries &&
                        (product.attributes.galleries as any[])
                          .length &&
                        product.attributes.galleries[0].url) ||
                      ""
                    }
                    alt="no image found"
                  />
                </div>
                <div className="product-detail">
                  <div className="product-heading">
                    <h4 className="product-name">
                      {product.attributes.name}
                    </h4>
                    <div className="menu-block">
                      <Menu
                        menuButton={
                          <div>
                            <IconButton>
                              <MoreHorizIcon />
                            </IconButton>
                          </div>
                        }
                      >
                        <MenuItem
                          onClick={() =>
                            this.goToAddProductScreen(
                              product.id,
                              true
                            )
                          }
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            this.setState({
                              showDeleteDialog: true,
                              selectedProductId: product.id,
                              selectedProductName:
                                product.attributes.name,
                            });
                          }}
                        >
                          Delete
                        </MenuItem>
                        {product.attributes.product_status ? (
                          <MenuItem
                            onClick={(e) =>
                              this.productEnableDisableApi(
                                false,
                                product.id
                              )
                            }
                          >
                            Disable
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={(e) =>
                              this.productEnableDisableApi(
                                true,
                                product.id
                              )
                            }
                          >
                            Enable
                          </MenuItem>
                        )}
                      </Menu>
                    </div>
                  </div>
                  <div className="product-desc">
                    {product.attributes.description}
                  </div>
                  <div className="pricing-link">
                    <div className="pricing-wrapper">
                      <span className="price">
                        &#8377;{sale_price}
                      </span>
                      <span className="price old-price">
                        &#8377;{maximum_retail_price}
                      </span>
                      {isFinite((discount as unknown) as number) ? (
                        <span className="offer">
                          ({discount}% off)
                        </span>
                      ) : null}
                      {/* {(product.attributes.discount != null && product.attributes.discount != 0) ? <span className="offer">({discount}% off)</span> : null} */}
                    </div>
                    <div>
                      {this.renderProductStatus(product)}
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </>
        );
      })
    )
  }

  renderProductStatus = (product: any) => {
    return (
      <>
        {
          product?.attributes?.product_status === false &&
          <Button variant="contained" color="default" style={{ cursor: "default", marginRight: "5px" }}>
            Disable
          </Button>
        }
        {
          product?.attributes?.status === PRODUCT_STATUS.DRAFT &&
          <Button variant="contained" color="default" style={{ cursor: "default" }}>
            Draft
          </Button>
        }
        {
          product?.attributes?.status === PRODUCT_STATUS.APPROVED &&
          <Button variant="contained" color="primary" style={{ cursor: "default" }} >
            {product.attributes.status}
          </Button>
        }
        {
          product?.attributes?.status === PRODUCT_STATUS.DIS_APPROVED &&
          <Button variant="contained" color="secondary" style={{ cursor: "default" }} >
            {product.attributes.status}
          </Button>
        }
      </>
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.loading ? <Loader loading={this.state.loading} /> : null}
        <div className={classes.pageOuter}>
          <Container maxWidth="md">
            <Grid container className="product-wrapper" ref={this.elementRef}>
              <Grid className="inventory-heading" item xs={12}>
                <div className="heading-left">
                  <div className="search-wrapper">
                    <div className="form-group">
                      <img src={searchIcon} className="search-ic" />
                      <input
                        placeholder="Search by order, product, order id"
                        // inputProps={{ "aria-label": "search" }}
                        value={this.state.searchText}
                        onChange={(e) => this.searchProduct(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="select-border">
                    <span className="select-label">Sort By:</span>
                    <Select
                      labelId="product-category"
                      id="demo-simple-select"
                      displayEmpty
                      value={this.state.filter}
                      onChange={(e) => {
                        this.setState({ filter: e.target.value });
                        this.getFilterData(e.target.value);
                      }}
                    >
                      {/* <MenuItems value="New">New</MenuItems> */}
                      <MenuItems value="">Select</MenuItems>
                      <MenuItems value="Low">Low</MenuItems>
                      <MenuItems value="High">High</MenuItems>
                      {/* <MenuItems value="Relevance">Relevance</MenuItems> */}
                    </Select>
                  </div>
                </div>
                <div className="btn-wrapper">
                  <Button
                    href=""
                    type="submit"
                    variant="contained"
                    className="outline-btn order-btn"
                    onClick={(e) => {
                      this.props.navigation.navigate("SizeCharts");
                    }}
                  >
                    Size Charts
                  </Button>
                  <Button
                    href=""
                    type="submit"
                    variant="contained"
                    className="black-btn order-btn"
                    onClick={(e) => {
                      this.setState({
                        addProductDialog: true,
                      });
                    }}
                  >
                    Add Product
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} className="productlist-wrapper">
                {this.state.productList && this.state.productList.length ? (
                  this.renderProductListing()
                ) : (
                  <div style={{ textAlign: "center" }}> No product found </div>
                )}

                {/* pagination */}
                {this.state.totalPage ? (
                  <PaginationComponent
                    handlePageChange={this.handlePageChange}
                    totalPage={this.state.totalPage}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Container>
        </div>
        <Dialog
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.addProductDialog}
          className="select-dialog"
        >
          <DialogTitle id="customized-dialog-title">
            <span>Add product</span>
            <CloseIcon
              className="close-dialog"
              onClick={(e) => {
                this.setState({
                  addProductDialog: false,
                });
              }}
            />
          </DialogTitle>
          <DialogContent style={{ width: "450px" }}>
            <RadioGroup className="custom-radio" name="price">
              <ul>
                <li>
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        color="default"
                        onClick={(e) => this.goToAddProductScreen()}
                      />
                    }
                    label="One Product"
                  />
                </li>
                <li>
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                        color="default"
                        onClick={(e) => this.goToCsvUploadScreen()}
                      />
                    }
                    label="Multiple Products (CSV Upload)"
                  />
                </li>
              </ul>
            </RadioGroup>
          </DialogContent>
        </Dialog>
        <Dialog
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.showDeleteDialog}
          className="cancel-order"
        >
          <DialogTitle id="customized-dialog-title">
            <CloseIcon
              className="close-dialog"
              onClick={(e) => {
                this.setState({
                  showDeleteDialog: false,
                });
              }}
            />
          </DialogTitle>
          <DialogContent style={{ width: "370px" }}>
            <h2 className="modal-heading">
              Are you sure you want to delete the product?
            </h2>
            <div className="sub-txt">{this.state.selectedProductName}</div>
            <div className="btn-wrapper">
              <Button
                href=""
                type="submit"
                variant="contained"
                className="black-btn"
                onClick={(e) => {
                  this.setState({ showDeleteDialog: false });
                }}
              >
                No
              </Button>
              <Button
                href=""
                type="submit"
                variant="contained"
                className="outline-btn"
                onClick={(e) => {
                  this.deleteProductApi();
                }}
              >
                Yes
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
export default withStyles(styles)(Inventory);
