import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import BackArrow from "@material-ui/icons/ArrowBack";
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import {
  Box,
  TextField,
  InputLabel,
  Input,
  Divider,
  FormControl,
  Link,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddProductDetailController from "./AddProductDetailController.web";
import { Formik } from "formik";
import * as Yup from 'yup';
// import { champion, cross } from "./assets";
import { toast } from "react-toastify";
import Loader from "../../../components/src/Loader.web";
const styles = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "50px 0",
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "700",
      fontFamily: "'Montserrat', sans-serif",
      textTransform: "uppercase",
      color: "#121212",
    },
    "& h4": {
      margin: "0 0 20px 0",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "600",
      color: "#121212",
      fontFamily: "'Montserrat', sans-serif",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "30px 15px 40px",
    },
    "& .block-wrapper-inner": {
      maxWidth: "500px",
      margin: "0 auto",
    },
    "& .btn-wrapper": {
      textAlign: "center",
    },
    "& .upload-btn": {
      width: "82px",
      height: "82px",
      borderRadius: "5px",
      backgroundColor: "#fff",
      border: "1px solid rgba(18,18,18,0.15)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      overflowX: "hidden",
      marginTop: '10px',
    },
    "& .upload-btn svg": {
      color: "rgba(18,18,18,0.15)",
    },
    "& .upload-btn input": {
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      opacity: "0",
      cursor: "pointer",
    },
    "& .upload-btn input::-webkit-file-upload-button": {
      cursor: "pointer",
    },
    "& .block-wrapper-inner .error": {
      color: "red",
    },
    "& .radio-outer": {
      display: "flex",
      // flex-wrap: "wrap"
    },
    "& .radio-wrapper": {
      position: "relative",
      height: "100px",
      width: "100px",
      borderRadius: "5px",
      // overflow: "hidden",
      background: "#aaa",
      margin: "0 10px 10px 0"
    },
    "& .radio-wrapper input": {
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      height: "100%",
      width: "100%",
      opacity: "0"
    },
    "& .radio-wrapper .main-img": {
      height: "100%",
      width: '100%',
      objectFit: "cover"
    },
    "& .radio-wrapper .cross-icon": {
      position: "absolute",
      top: "5px",
      right: "5px",
      color: "white",
      backgroundColor: "black",
      borderRadius: "50%"
    },
    "& .radio-wrapper .check": {
      position: "absolute",
      bottom: "5px",
      left: "5px",
      background: "#fff",
      height: "20px",
      width: "20px",
      borderRadius: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    "& .radio-wrapper input:checked + .check:after": {
      content: "",
      height: "7px",
      width: "12px",
      border: "2px solid #fff",
      display: "block",
      borderTop: "none",
      borderRight: "none",
      transform: "rotate(-45deg)",
      marginTop: "-4px"
    },
    // "& .radio-wrapper input:checked + .check": {
    //   background: "green"
    // },
  },
};

const productDetailSchema = Yup.object().shape({
  description: Yup.string().nullable().required("Product Description is required"),
  gender: Yup.string().nullable().required("Gender is required"),
  material: Yup.string().nullable().required("Material Type is required"),
  // length: Yup.string().nullable().required("Length is required"),
  // width: Yup.string().nullable().required("Width is required"),
  // height: Yup.string().nullable().required("Height is required"),
  // closureId: Yup.string().nullable().required("Closure is required"),
  // pockets: Yup.string().nullable().required("Pockets is required"),
  // compartment: Yup.string().nullable().required("Compartment is required"),
  quantity: Yup.string().nullable().required("Quantity is required")
});

export class AddProductDetail extends AddProductDetailController {
  fileObj = [];
  fileArray = [];

  constructor(props: any) {
    super(props);
    // Customizable Area Start
    this.myRef = React.createRef();
    this.mycolorAndSizeRef = React.createRef();
    // Customizable Area End
  }

  genderData = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' }
  ];

  // pocketData = [
  //   { label: '1', value: 'one' },
  //   { label: '2', value: 'two' },
  //   { label: '3', value: 'three' },
  //   { label: '4', value: 'four' },
  //   { label: '5', value: 'five' },
  //   { label: '6', value: 'six' },
  // ];

  compartmentData = [
    { label: 'YES', value: true },
    { label: 'NO', value: false }
  ];

  steps = ['Basic Info', 'Product Details', 'Pricing', 'Other Info', 'Policy'];
  setActiveStep = (step: any) => {
    this.setState({ activeStep: step });
  }

  handleNext = () => {
    this.setActiveStep(this.state.activeStep + 1);
  };

  handleBack = () => {
    this.gotoProductBaiscDetailScreen();
  };

  menuProps: any = {
    style: { maxHeight: "300px" },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.loading && <Loader loading={this.state.loading} />}
        {this.state.isProductDetailScreen ?
          <div className={classes.pageOuter} id="productDetail" ref={this.myRef}>
            <div>
              <Container maxWidth="lg">
                <Grid container>
                  <Grid item xs={12}>
                    {/* step bar start */}
                    <Box sx={{ width: '100%' }}>
                      <Stepper activeStep={this.state.activeStep}>
                        {this.steps.map((label, index) => {
                          const stepProps: { completed?: boolean } = {};
                          const labelProps: {
                            optional?: React.ReactNode;
                          } = {};
                          if (index < this.state.activeStep) {
                            stepProps.completed = true;
                          }
                          return (
                            <Step key={label} {...stepProps}>
                              <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                      <React.Fragment>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                          <Button
                            color="inherit"
                            disabled={this.state.activeStep === 0}
                            onClick={this.handleBack}
                          >
                            Back
                          </Button>
                          <Box sx={{ flex: '1 1 auto' }} />
                          {/* <Button onClick={this.handleNext}>
                            {this.state.activeStep === this.steps.length - 1 ? 'Finish' : 'Next'}
                          </Button> */}
                        </Box>
                      </React.Fragment>
                    </Box>
                    {/* step bar end */}

                    <h2>{this.state.isEdit ? "EDIT PRODUCT" : "ADD PRODUCT"}</h2>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div className="block-wrapper">
                    <div className="block-wrapper-inner">
                      <Formik
                        initialValues={this.productDetailIntialValues}
                        validationSchema={productDetailSchema}
                        onSubmit={(values) => {
                          this.addProductDetailApi(values);
                        }}>
                        {(props) => {
                          const { errors, touched, isValid, dirty } = props;
                          if (Object.keys(errors).length) {
                            this.hasProductDetailPageError = true;
                            // this.setState({hasProductDetailPageError: true}); // here setState method not working gives error
                            this.myRef.current?.scrollIntoView({ behavior: 'auto' });
                          }
                          props.values.description = this.state.description;
                          props.values.gender = this.state.gender;
                          props.values.material = this.state.material;
                          props.values.length = this.state.length;
                          props.values.width = this.state.width;
                          props.values.height = this.state.height;
                          props.values.closureId = this.state.closureId;
                          props.values.pockets = this.state.pockets;
                          props.values.compartment = this.state.compartment;
                          props.values.quantity = this.state.quantity;

                          return (
                            <form onSubmit={props.handleSubmit}>
                              <h4>Product Details</h4>
                              <div className="form-group">
                                <InputLabel htmlFor="my-input">
                                  Add Product Photos<sup>*</sup>
                                </InputLabel>
                                {/*1. using Create Object URL */}
                                <div className="radio-outer">
                                  {this.state.galleries &&
                                    this.state.galleries.length > 0 &&
                                    this.state.galleries.map((item, index) => {
                                      return (
                                        <div className="radio-wrapper">
                                          <img className="main-img" src={item.url} />
                                          <div className="cross-icon" style={{ cursor: "pointer" }} onClick={() => this.deleteProductImage(item.id)}> <CancelRoundedIcon /> </div>
                                        </div>
                                      )
                                    })}
                                </div>
                                {
                                  this.state.galleries.length <= 0 ?
                                    (
                                      <div className="upload-btn">
                                        <AddIcon />
                                        {/* <input type="file" /> */}
                                        <input accept="image/*" type="file" onChange={(e) => this.uploadMultipleImages(e)} />
                                      </div>
                                    ) : null}
                                {(this.hasProductDetailPageError || this.state.hasProductDetailPageError) && (!this.state.galleries || !this.state.galleries.length) && <span className="error">Please add atleast one product variant</span>}
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="my-input">
                                  Product Description<sup>*</sup>
                                </InputLabel>
                                <FormControl>
                                  <Input
                                    autoFocus
                                    type="text"
                                    id="description"
                                    name="description"
                                    value={props.values.description}
                                    error={touched.description && errors.description ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                  <span className="error">{touched.description && errors.description ? errors.description : ''}</span>
                                </FormControl>
                              </div>
                              <div className="form-group">
                                <InputLabel id="product-category">
                                  Size &amp; Color<sup>*</sup>
                                </InputLabel>
                                <Select
                                  id="sizeColor"
                                  name="sizeColor"
                                  value={this.state.colorAndSizePlaceHolder || ''}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                >
                                  {!this.state.colorAndSizePlaceHolder && <Link><MenuItem onClick={this.displaySizeAndColorScreen} >Add Color and Size</MenuItem></Link>}
                                  {this.state.colorAndSizePlaceHolder && <MenuItem onClick={this.displaySizeAndColorScreen} value={this.state.colorAndSizePlaceHolder} ><Link>{this.state.colorAndSizePlaceHolder}</Link></MenuItem>}
                                </Select>
                                {(this.hasProductDetailPageError || this.state.hasProductDetailPageError) && !this.state.colorAndSizePlaceHolder && <span className="error">Please add atleast one product variant color and size</span>}
                              </div>
                              <div className="form-group">
                                <InputLabel id="product-category">
                                  Gender<sup>*</sup>
                                </InputLabel>
                                <Select
                                  id="gender"
                                  name="gender"
                                  value={props.values.gender}
                                  error={touched.gender && errors.gender ? true : false}
                                  onChange={(e) => { this.handleChange(e) }}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                >
                                  {
                                    this.genderData &&
                                    this.genderData.length &&
                                    this.genderData.map((gender: any) =>
                                      <MenuItem value={gender.value}>{gender.label}</MenuItem>
                                    )
                                  }
                                </Select>
                                <span className="error">{touched.gender && errors.gender ? errors.gender : ''}</span>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="my-input">
                                  Material Type<sup>*</sup>
                                </InputLabel>
                                <FormControl>
                                  <Input
                                    type="text"
                                    id="material"
                                    name="material"
                                    value={props.values.material}
                                    error={touched.material && errors.material ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                  <span className="error">{touched.material && errors.material ? errors.material : ''}</span>
                                </FormControl>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="my-input">
                                  Length
                                </InputLabel>
                                <FormControl>
                                  <Input
                                    type="text"
                                    id="length"
                                    name="length"
                                    value={props.values.length}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                </FormControl>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="my-input">
                                  Width
                                </InputLabel>
                                <FormControl>
                                  <Input
                                    type="text"
                                    id="width"
                                    name="width"
                                    value={props.values.width}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                </FormControl>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="my-input">
                                  Height
                                </InputLabel>
                                <FormControl>
                                  <Input
                                    type="text"
                                    id="height"
                                    name="height"
                                    value={props.values.height}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                </FormControl>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="my-input">
                                  Closure
                                </InputLabel>
                                <Select
                                  id="closureId"
                                  name="closureId"
                                  value={props.values.closureId}
                                  onChange={(e) => { this.handleChange(e) }}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                  displayEmpty
                                >
                                  <MenuItem value="">Select</MenuItem>
                                  {
                                    this.closureData &&
                                    this.closureData.length &&
                                    this.closureData.map((closure: any) =>
                                      <MenuItem value={closure.value}>{closure.label}</MenuItem>
                                    )
                                  }
                                </Select>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="my-input">
                                  Pockets
                                </InputLabel>
                                <Select
                                  id="pockets"
                                  name="pockets"
                                  value={props.values.pockets}
                                  onChange={(e) => { this.handleChange(e) }}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                  displayEmpty
                                >
                                  <MenuItem value="">Select</MenuItem>
                                  {this.state.pocketData &&
                                    this.state.pocketData.length &&
                                    this.state.pocketData.map((pocket: any) =>
                                      <MenuItem value={pocket.value}>{pocket.label}</MenuItem>
                                    )
                                  }
                                </Select >
                              </div >
                              <div className="form-group">
                                <InputLabel htmlFor="my-input">
                                  Compartment
                                </InputLabel>
                                <Select
                                  id="compartment"
                                  name="compartment"
                                  value={props.values.compartment}
                                  onChange={(e) => { this.handleChange(e) }}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                >
                                  {
                                    this.compartmentData &&
                                    this.compartmentData.length &&
                                    this.compartmentData.map((compartment: any) =>
                                      <MenuItem value={compartment.value}>{compartment.label}</MenuItem>
                                    )
                                  }
                                </Select>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="my-input">
                                  Quantity<sup>*</sup>
                                </InputLabel>
                                <FormControl>
                                  <Input
                                    type="text"
                                    id="quantity"
                                    name="quantity"
                                    value={props.values.quantity}
                                    error={touched.quantity && errors.quantity ? true : false}
                                    onChange={(e) => { this.handleChange(e) }}
                                    aria-describedby="my-helper-text"
                                  />
                                  <span className="error">{touched.quantity && errors.quantity ? errors.quantity : ''}</span>
                                </FormControl>
                              </div>
                              <div className="btn-wrapper">
                                <Button
                                  type="submit"
                                  variant="contained"
                                  className="black-btn"
                                >
                                  Continue
                                </Button>
                              </div>
                            </form >
                          )
                        }
                        }
                      </Formik >
                    </div >
                  </div >
                </Grid >
              </Container >
            </div >
          </div >
          :
          // 2. add color and size screen
          <div className={classes.pageOuter} ref={this.mycolorAndSizeRef}>
            {this.state.hasErrorInDeleteVariant ? toast.error(this.state.errorMessageInDeleteVariant, { position: "top-right", autoClose: 5000, onOpen: this.removeDeleteVariantToastMessage }) : null}
            <div>
              <Container maxWidth="lg">
                <Grid container>
                  <Grid item xs={12}>
                    <Box sx={{ width: '100%' }}>
                      <Stepper activeStep={this.state.activeStep}>
                        {this.steps.map((label, index) => {
                          const stepProps: { completed?: boolean } = {};
                          const labelProps: {
                            optional?: React.ReactNode;
                          } = {};
                          if (index < this.state.activeStep) {
                            stepProps.completed = true;
                          }
                          return (
                            <Step key={label} {...stepProps}>
                              <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                      <React.Fragment>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                          <Button
                            color="inherit"
                            disabled={this.state.activeStep === 0}
                            onClick={this.handleBack}
                          >
                            Back
                          </Button>
                          <Box sx={{ flex: '1 1 auto' }} />
                          {/* <Button onClick={this.handleNext}>
                            {this.state.activeStep === this.steps.length - 1 ? 'Finish' : 'Next'}
                          </Button> */}
                        </Box>
                      </React.Fragment>
                    </Box>
                    <h2>Add Colors and Sizes Variation</h2>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Link style={{ color: "black" }}><BackArrow onClick={() => this.setState({ isProductDetailScreen: true }, this.getProductData)} /></Link>
                  <div className="block-wrapper">
                    <div className="block-wrapper-inner">

                      {/* <form onSubmit={this.addAndUpdateProduct}> */}
                      <div className="outline-btn-wrapper">
                        <Button variant="outlined" className="outline-btn" type="button" style={{ float: "right" }} onClick={() => this.addProductInputView()}>
                          Add
                        </Button>
                      </div>
                      <h4>Add Variations</h4>

                      {this.state.productData.map((item: any, index: any) =>
                      (<>
                        <div className="form-group">
                          <h4> Product {index + 1} </h4>
                          {/* <img className="close-img" style={{ cursor: "pointer", float: "right" }} onClick={() => this.onRemoveProductView(index, item.id)} src={cross}/> */}
                          <CancelRoundedIcon className="close-img" style={{ cursor: "pointer", float: "right" }} onClick={() => this.onRemoveProductView(index, item.id)} />
                          {/* </img> */}
                        </div>
                        <div className="form-group">
                          <InputLabel htmlFor="my-input">
                            Product name<sup>*</sup>
                          </InputLabel>
                          <FormControl>
                            <Input
                              autoFocus
                              type="text"
                              id="product_name"
                              name="product_name"
                              value={item.attributes.product_name}
                              onChange={(e) => { this.addProductVariant(e.target.value, item, 'product_name') }}
                              aria-describedby="my-helper-text"
                            />
                            {this.state.hasError && !item.attributes.product_name && <span className="error">Product name is required</span>}
                          </FormControl>
                        </div>
                        <div className="form-group">
                          <InputLabel htmlFor="my-input">
                            Color name<sup>*</sup>
                          </InputLabel>
                          <FormControl>
                            <Input
                              type="text"
                              id="color"
                              name="color"
                              value={item.attributes.color}
                              onChange={(e) => { this.addProductVariant(e.target.value, item, 'color') }}
                              aria-describedby="my-helper-text"
                            />
                            {this.state.hasError && !item.attributes.color && <span className="error">Color name is required</span>}
                          </FormControl>
                        </div>
                        <div className="form-group">
                          <InputLabel htmlFor="my-input">
                            SKU<sup>*</sup>
                          </InputLabel>
                          <FormControl>
                            <Input
                              type="text"
                              id="sku"
                              name="sku"
                              value={item.attributes.sku}
                              onChange={(e) => { this.addProductVariant(e.target.value, item, 'sku') }}
                              aria-describedby="my-helper-text"
                            />
                            {this.state.hasError && !item.attributes.sku && <span className="error">SKU is required and it should be number</span>}
                          </FormControl>
                        </div>

                        <div className="form-group">
                          <InputLabel htmlFor="my-input">
                            Add Product Photos<sup>*</sup>
                          </InputLabel>
                          <div className="radio-outer">
                            {item.attributes.galleries &&
                              item.attributes.galleries.length > 0 &&
                              item.attributes.galleries.map((variantImage: any, imageIndex: number) => {
                                return (
                                  <>
                                    <div className="radio-wrapper" style={variantImage.is_default ? { border: "2px solid green" } : {}}>
                                      <input type="radio" checked={variantImage.is_default ? true : false} onClick={() => this.makeImageDefault(index, variantImage)} name="gender" />
                                      <span style={variantImage.is_default ? { color: "green", background: "white" } : {}} className="check"> {variantImage.is_default ? <CheckCircleIcon /> : null} </span>
                                      <img className="main-img" src={variantImage.url} />
                                      <div className="cross-icon" style={{ cursor: "pointer", color: "white" }} onClick={() => this.deleteProductVariantImage(index, variantImage)}> <CancelRoundedIcon /> </div>
                                      {variantImage.is_default ? <div style={{ color: "green" }}>Thumbnail</div> : <></>}
                                    </div>
                                  </>
                                )
                              })}
                          </div>
                          <div className="upload-btn">
                            <AddIcon />
                            <input accept="image/*" type="file" onChange={(e) => { this.addProductVariantImages(e, item) }} multiple />
                          </div>
                          {this.state.hasError && ((item.attributes.galleries as any[]).length) === 0 && <span className="error"> Photos is required</span>}
                        </div>

                        <div className="form-group">
                          <InputLabel htmlFor="my-input">
                            Size<sup>*</sup>
                          </InputLabel>
                          <Select
                            id="size"
                            name="size"
                            // multiple={true}
                            value={item.attributes.measurement_size_value_id}
                            aria-describedby="my-helper-text"
                            MenuProps={this.menuProps}
                            onChange={(e: any) => this.addProductVariant(e.target.value, item, 'size', e._targetInst.stateNode.innerText)}
                          >
                            {
                              this.sizeData &&
                              this.sizeData.length &&
                              this.sizeData.map((size: any) =>
                                <MenuItem value={size.value}>{size.label}</MenuItem>
                              )
                            }
                          </Select>
                          {this.state.hasError && !item.attributes.measurement_size_value_id && <span className="error">Size is required</span>}
                        </div>

                      </>
                      ))}
                      {this.state.hasError && (!this.state.productData || !this.state.productData.length) && <div style={{ textAlign: "center", color: "red" }}> Please add atleast one Product variant </div>}
                      <div className="btn-wrapper">
                        <Button
                          type="submit"
                          variant="contained"
                          className="black-btn"
                          onClick={(e) => this.AddUpdateProductVariantApi(e)}
                        >
                          Continue
                        </Button>
                      </div>
                      {/* </form> */}
                    </div>
                  </div>
                </Grid>
              </Container >
            </div >
          </div >
        }
      </>
    );
  }
}
export default withStyles(styles)(AddProductDetail);
