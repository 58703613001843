import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { championItem, DocIc } from "./assets";
import {
    InputLabel,
    Input,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
} from "@material-ui/core";
import CancelItemController, { Props } from "./CancelItemController.web";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/src/Loader.web";
import CustomerAccountSideBarWeb from "../../../components/src/CustomerAccountSideBar.web";
import { imageModel } from "../../../framework/src/Interfaces/IProductDetail";
import { OrderItemModel } from "../../../framework/src/Interfaces/IOrderDetail";

const profileList = [
    { id: "1", category: "My Profile" },
    { id: "2", category: "My Orders" },
    { id: "3", category: "Shopping With Friends" },
    { id: "4", category: "Address Book" },
    { id: "5", category: "Coupons" },
    { id: "6", category: "Policies" },
    { id: "7", category: "Settings" },
    { id: "8", category: "Loyalty Points" },
];

const styles = {
    pageOuter: {
        backgroundColor: "#F7F7F7",
        padding: '50px 0',
        "& h2": {
            margin: "0 0 20px",
            textAlign: "center",
            textTransform: "uppercase",
            color: "#121212",
            fontSize: "20px",
            // margin: "0 0 20px",
        },
        "& h4": {
            margin: "0 0 20px 0",
        },
        "& .block-wrapper": {
            backgroundColor: "#fff",
            padding: "30px 15px 40px",
        },
        "& .block-wrapper-inner": {
            maxWidth: "515px",
            margin: "0 auto",
        },
        "& .error-msg": {
            fontSize: "13px",
            color: "red",
        },
        "& label": {
            fontSize: "14px",
            fontWeight: "400",
            color: "#121212",
        },
        "& .MuiFormControl-root": {
            display: "block",
        },
        "& .MuiInputBase-root": {
            display: "block",
        },
        "& .btn-wrapper": {
            textAlign: "center",
            paddingTop: "20px",
        },
        "& .custom-radio": {
            display: "flex",
            flexDirection: "row",
        },
        "& .custom-radio label": {
            color: "#000",
        },
        "& .custom-radio label .MuiIconButton-label": {
            color: "#000",
        },
        "& .productlist-wrapper": {
            padding: "0",
        },
        "& .download-doc": {
            border: "1px solid #d3d3d3",
            padding: "20px",
            minHeight: "88px",
            display: "flex",
            alignItems: "center",
            margin: "30px 0",
            '@media (max-width:767px)': {
                margin: "20px 0 0",
            }
        },
        "& .download-doc .outline-btn": {
            minWidth: "120px",
            padding: "5px",
            marginLeft: "auto",
        },
        "& .download-doc .file-name": {
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: "600",
            color: "#121212",
            fontFamily: "'Montserrat', sans-serif",
            marginLeft: "10px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "calc(100% - 180px)",
            textOverflow: "ellipsis",
        },
        "& .product-price span": {
            fontSize: "14px",
            lineHeight: "18px",
            display: "inline-block",
            color: "#a5a5a5",
        },
        "& .product-price span.current-price": {
            fontWeight: "700",
            fontSize: "16px",
            color: "#121212",
            margin: "0 10px 0 0",
        },
        "& .product-price span.off-price": {
            textDecoration: "line-through",
        },
        "& .order-info-blog": {
            borderTop: "1px solid #e3e3e3",
            padding: "25px 0 0 ",
        },
        "& .order-info-blog h4": {
            fontWeight: "600",
            color: "#212121",
            margin: "0 0 15px 0",
        },
        "& .order-info-blog p": {
            fontSize: "12px",
            lineHeight: "16px",
            color: "#121212",
            margin: "0 0 15px 0",
        },
        "& .btn-wrapper button": {
            height: "50px",
            minWidth: "150px",
            '@media (max-width:575px)': {
                width: "100%",
            },
        },
        "& .btn-wrapper button.outline-btn": {
            margin: "0 15px 0 0",
            '@media (max-width:575px)': {
                margin: "0px 0 10px",
            }
        },
    },
};


export class CancelItem extends CancelItemController {
    // CancelItemApi: void;
    constructor(props: Props) {
        super(props);

    }

    renderCancelOrder = () => {
        const itemdetail: any = JSON.parse(localStorage.getItem("itemdetail") || "{}")
        const product_name = itemdetail.product_name;
        const brand_name = itemdetail.brand_name;
        const imageUrl = itemdetail.galleries && itemdetail.galleries.length && itemdetail.galleries[0].url || '';
        const size = itemdetail.measurement_size_value;
        const description = itemdetail.description;
        const color = itemdetail.color;
        const sale_price = itemdetail.sale_price;
        const maximum_retail_price = itemdetail.unit_price;
        const { classes } = this.props;
        const { cancelReasonList, cancelReasonId, otherCancelreason, order } = this.state;

        const noOfItemsInOrder = order && order.attributes && order.attributes.order_items_details && order.attributes.order_items_details.length;
        const orderNumber = order && order.attributes && order.attributes.order_number;

        const orderItems: any[] = order && order.attributes && order.attributes.order_items_details;
        console.log("Order Items:::", orderItems);

        let status: string = order && order.attributes && order.attributes.status;

        return (<>

            <div className={classes.pageOuter}>

                <div>
                    <Container maxWidth="lg">
                        <Grid container>
                            <Grid item xs={12}>
                                <h2>Item Details</h2>
                            </Grid>

                            <Grid item xs={12}>
                                <div className="block-wrapper order-block">

                                    <div className="block-wrapper-inner">
                                        <div className="order-details-wrapper">
                                            <Grid item xs={12} className="productlist-wrapper">

                                                <Grid className="product-list">
                                                    <div className="product-list-inner">
                                                        <div className="product-img">
                                                            <img className="img-block" src={imageUrl} />
                                                        </div>
                                                        <div className="product-detail">
                                                            <div className="product-heading">
                                                                <h4 className="product-name">{product_name}</h4>
                                                            </div>
                                                            <div className="product-desc">
                                                                {description}
                                                            </div>
                                                            <div className="product-desc size-qty">
                                                                <span>Size: {size}</span>
                                                            </div>
                                                            <div className="product-price">
                                                                <span className="current-price">₹{sale_price}</span>
                                                                <span className="off-price">₹{maximum_retail_price}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>

                                            </Grid>
                                            <div className="download-doc">
                                                <img src={DocIc} alt="" />
                                                <span className="file-name">
                                                    Cancellation Policy
                                                </span>
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    className="outline-btn"
                                                >
                                                    View Policy
                                                </Button>
                                            </div>
                                            <div className="order-info-blog">
                                                <h4>Choose Reason for Cancellation</h4>
                                                {/* <p>
                                                        Lorem Ipsum is simply dummy text of the printing and
                                                        typesetting industry and standard dummy text.
                                                    </p> */}
                                                <div className="form-group">
                                                    <RadioGroup aria-label="return" name="return">
                                                        {cancelReasonList &&
                                                            cancelReasonList.length &&
                                                            cancelReasonList.map((reason: any,index:number) => {
                                                                return (
                                                                    <FormControlLabel
                                                                        value={reason.id}
                                                                        control={<Radio value={reason.id} id={`cancel-reason-${index}`} checked={Number(cancelReasonId) === Number(reason.id)} onChange={(e: any) => this.setState({ cancelReasonId: e.target.value }, () => console.log("selected reason::", this.state.cancelReasonId))} />}
                                                                        label={reason.attributes.reason}
                                                                    />
                                                                )
                                                            }) || null

                                                        }
                                                    </RadioGroup>
                                                </div>
                                                <div className="form-group">
                                                    <InputLabel htmlFor="my-input">
                                                        Additional Comments
                                                    </InputLabel>
                                                    <FormControl>
                                                        <Input
                                                            type="text"
                                                            id="otherCancelreason"
                                                            name="otherCancelreason"
                                                            aria-describedby="my-helper-text"
                                                            value={this.state.otherCancelreason}
                                                            onChange={(e) => this.setState({ otherCancelreason: e.target.value })}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="btn-wrapper">
                                            <Button
                                                variant="contained"
                                                className="black-btn"
                                                type="button"
                                                disabled={cancelReasonId ? false : true}
                                                onClick={() => this.CancelItemApi()}
                                            >
                                                Cancel Item
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
        </>)
    }

    render() {
        const { isLoading, isOrderCancelledSuccesfully, order } = this.state;
        return (
            <>
                {isLoading ? <Loader loading={isLoading} /> : null}
                {isOrderCancelledSuccesfully ? toast.success('Order Cancelled Successfully', { position: "top-right", autoClose: 5000, onOpen: this.hideCancelledToastMessage }) : null}
                <CustomerAccountSideBarWeb id={1} navigation={this.props.navigation} callbackShopWithFriend={this.props.callbackShopWithFriend}>
                    {this.renderCancelOrder()}
                </CustomerAccountSideBarWeb>
            </>
        );
    }
}
export default withStyles(styles)(CancelItem);
