import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getTimeZone } from "react-native-localize";
import { toast } from "react-toastify";
// import AsyncStorage from '@react-native-async-storage/async-storage'; 

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes?: any
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    showNewPackagingDialog: boolean;
    p_name: string;
    p_length: string;
    p_width: string;
    p_height: string;
    data: any;
    Eaddress: any;
    addressID: string | number;
    Checked: number;
    popup: boolean;
    navigate: boolean;
    status: string;
    token: string | null;
    addressEditData: any;
    is_defulat: boolean;
    visible: boolean;
    name_error: string;
    length_error: string;
    width_error: string;
    height_error: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PackageController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiPhoneLoginCallId: any;
    packageCallId: any;
    DelPackageCallId: any;
    addNewPackageCallId: any;
    EditAddressCallId: any;
    EidthAddressCallId: any;
    DefaultPackageCallId: any
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            showNewPackagingDialog: false,
            p_name: '',
            p_length: '',
            p_width: '',
            p_height: '',
            data: [],
            Eaddress: [],
            addressID: '',
            Checked: 0,
            popup: false,
            navigate: false,
            status: '',
            token: '',
            addressEditData: {},
            is_defulat: false,
            visible: false,
            name_error: "",
            length_error: "",
            width_error: "",
            height_error: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
      
            let errorReponse = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
      
      
            if (apiRequestCallId && responseJson) {
                if (
                    apiRequestCallId === this.packageCallId ||
                    apiRequestCallId === this.DelPackageCallId
                  ) {
                    this.reciveBlock1(apiRequestCallId, responseJson)
                  }
            }
            if(responseJson?.errors) {
                this.parseApiErrorResponse(responseJson);
            }

            this.parseApiCatchErrorResponse(errorReponse);
        }                
    }

    reciveBlock1 = (apiRequestCallId: any, responseJson: any) => {
        if(apiRequestCallId === this.packageCallId) {
            this.packageApiResponse(responseJson);
        } else if(apiRequestCallId === this.DelPackageCallId){
            this.deletePackageApiResponse(responseJson);
        }
    }

    packageApiResponse = (packageResponseJson: any) => {
        this.setState({ data: packageResponseJson.data });
            console.log('-------------->', this.state.data);
    }
    DefaultPackageApiResponse = (responseJson: any) => {
        console.log("default package api response",responseJson)
    }
    addPackageApiResponse = (responseJson: any) => {
        console.log("add package api response",responseJson)
    }
    deletePackageApiResponse = (responseJson: any) => {
        if(responseJson?.error) {
            toast.error(responseJson?.error, { position: "top-right" })
        }
    }

    txtInputWebProps = {
        onChangeText: (text: string) => {
            this.setState({ txtInputValue: text });
        },
        secureTextEntry: false
    };

    txtInputMobileProps = {
        ...this.txtInputWebProps,
        autoCompleteType: "email",
        keyboardType: "email-address"
    };

    txtInputProps = this.isPlatformWeb()
        ? this.txtInputWebProps
        : this.txtInputMobileProps;

    btnShowHideProps = {
        onPress: () => {
            this.setState({ enableField: !this.state.enableField });
            this.txtInputProps.secureTextEntry = !this.state.enableField;
            this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
                ? imgPasswordVisible
                : imgPasswordInVisible;
        }
    };

    btnShowHideImageProps = {
        source: this.txtInputProps.secureTextEntry
            ? imgPasswordVisible
            : imgPasswordInVisible
    };

    btnExampleProps = {
        onPress: () => this.doButtonPressed()
    };

    doButtonPressed() {
        let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
        msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.txtInputValue
        );
        this.send(msg);
    }
    // commented by me 
    // Customizable Area Start
    async componentDidMount() {
        // token
        console.log('----->> token');
        try {

            const localToken = await localStorage.getItem("authToken")
            console.log(localToken, "tokentoken")
            this.setState({ token: localToken })
            console.log('token new--------->', this.state.token);

        } catch (e) {
            // error reading value

        }
        this.packageCall();
        setTimeout(() => {
            this.packageCall();
        }, 1100)

    }

    packageCall() {

        console.log('---------------->>>>>>> address');

        const header = {
            "Content-Type": configJSON.packageApiContentType,
            token: this.state.token
            // token: ""
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.packageCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.packageAPiEndPoint
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.packageApiMethodType
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }



    addNewPackageCall() {
        if (!this.state.p_name || !this.state.p_length || !this.state.p_width || !this.state.p_height) {
            if (!this.state.p_name) {      // alert('Package Name Field is Empty');
                this.setState({ name_error: "Package Name Field is Empty" })
            } else { this.setState({ name_error: "" }) }
            if (!this.state.p_length) {
                // alert('Package Length Field is Empty');
                this.setState({ length_error: "Package Length Field is Empty" })
            } else { this.setState({ length_error: "" }) }
            if (!this.state.p_width) {
                // alert('Package Width Fieldis Empty');
                this.setState({ width_error: "Package Width Field is Empty" })
            } else { this.setState({ width_error: "" }) }
            if (!this.state.p_height) {
                // alert('Package Height Field is Empty');
                this.setState({ height_error: "Package Height Field is Empty" })
            } else { this.setState({ height_error: "" }) }
        }
        else {

            const header = {
                "Content-Type": configJSON.addpackageAPiContentType,
                token: this.state.token
            };

            const httpBody = {
                package_name: this.state.p_name,
                package_length: this.state.p_length,
                package_width: this.state.p_width,
                package_height: this.state.p_height
            };


            const apiRequest = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.addNewPackageCallId = apiRequest.messageId;


            apiRequest.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.addpackageAPiEndPoint
            );

            apiRequest.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            apiRequest.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            apiRequest.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.addpackageAPiMethodType);

            runEngine.sendMessage(apiRequest.id, apiRequest);
            this.props.navigation.navigate("PackagingWeb")

            return true;
        }
    }

    PackageDeleteCall() {

        const header = {
            "Content-Type": configJSON.DelpackageAPiContentType,
            token: this.state.token
        };

        const httpBody = {
            id: this.state.addressID,
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.DelPackageCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.DelpackageAPiEndPoint + `/${this.state.addressID}`
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.DelpackageAPiMethodType);

        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;

    }

    makeDefualtCall() {

        console.log('======,.,.,.,.,.,', this.state.addressID);


        const header = {
            "Content-Type": configJSON.addpackageAPiContentType,
            token: this.state.token
        };

        const httpBody = {
            is_default: true
        };

        console.log("===========>resss", httpBody)

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.DefaultPackageCallId = apiRequest.messageId;

        apiRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.DefaultpackageAPiEndPoint + `/${this.state.addressID}`
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.DefaultpackageAPiMethodType);

        runEngine.sendMessage(apiRequest.id, apiRequest);

        return true;
    }

    onClick(id: string, name: string, length: string, width: string, height: string) {
        localStorage.setItem("name", name)
        localStorage.setItem("lenght", length)
        localStorage.setItem("width", width)
        localStorage.setItem("height", height)
        localStorage.setItem("id", id)
        this.showMenu();
            this.setState({ addressID: id })
    }

    onAdd() {
        this.addNewPackageCall()
        // this.handleCloseNewPackagingDialog()
        setTimeout(() => {
            this.packageCall()
        }, 2500)

    }

    onDelete() {
        this.PackageDeleteCall();
        // this.hideMenu();
        setTimeout(() => {
            this.packageCall()
        }, 2500)

    }

    onMakeDefault() {
        this.makeDefualtCall();
        // this.hideMenu();
        setTimeout(() => {
            this.packageCall()
        }, 2500);
    }

    onPackageCall() {
        this.addNewPackageCall()
        setTimeout(() => {
            this.packageCall()
        }, 1000)
    }

    // popup 

    hideMenu = () => this.setState({ visible: false });




    showMenu = () => this.setState({ visible: true });

    handleCloseNewPackagingDialog = () => {
        this.setState({
            showNewPackagingDialog: false
        });
    };


    // Customizable Area End

}

