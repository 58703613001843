import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import axios from 'axios';
import { ProductPriceModel } from "../../../framework/src/Interfaces/IProductDetail";
import React from "react";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  token: string | null;
  isSamePrice: boolean;
  id: number | null;
  isEdit: boolean;
  product: any;
  activeStep: number;
  gstData: any[];
  priceData: any[];
  gstId: any;
  maximum_retail_price: string;
  salePrice: string;
  hasError: boolean;
}

interface SS {

}

export default class AddProductPriceDetailController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  addProductPriceApiCallId: any
  getProductDataApiCallId: any;
  addPriceVariationApiCallId: any;
  gstApiCallId: any;
  gstData = [{ value: '', label: '' }];

  myRef: React.RefObject<HTMLDivElement>; // error referrance
  // Customizable Area End

  public intialValues: ProductPriceModel = {
    gstId: '',
    maximum_retail_price: '',
    salePrice: ''
  };

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    this.state = {
      token: localStorage.getItem('authToken'),
      isSamePrice: true,
      id: Number(localStorage.getItem('id')) || null,
      isEdit: Boolean(localStorage.getItem('isEdit')),
      product: { attributes: { catalogue_variants_attributes: [] } },
      activeStep: 2,
      gstData: [{ value: '', lable: '' }],
      priceData: [],
      gstId: "",
      maximum_retail_price: "",
      salePrice: "",
      hasError: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.myRef = React.createRef();
    // Customizable Area Start
    this.getProductData();
    this.getGstListApi();
    // Customizable Area End

  }

  async componentDidMount() {
    // this.getClosureList();
  }

  async receive(from: string, message: Message) {
    console.log('api call ------------------>>>')
    console.log('message ------------------>>>', message)
    console.log('message ------------------>>>', message.id)

    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const data = message.getData(
        getName(MessageEnum.VendorAddUpdateProductDataMessage)
      );
      if (data) {
        const { id, isEdit } = data;
        this.setState({
          id: id,
          isEdit: isEdit
        });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      console.log('json response ------------------>>>', responseJson.data)

      if (responseJson && responseJson.errors && responseJson.errors.length && responseJson.errors[0].token) {
        this.goToLoginScreen();
      }

      if (apiRequestCallId && responseJson.data) {
        if (apiRequestCallId === this.getProductDataApiCallId) {
          this.setState({ product: responseJson.data, maximum_retail_price: responseJson.data.attributes.maximum_retail_price, salePrice: responseJson.data.attributes.sale_price });

          let arr: any[] = responseJson.data.attributes.catalogue_variants_attributes;
          arr = arr.sort((a, b) => a.id - b.id);
          var tempPrice: any = []
          arr.length > 0 && arr.map((obj: any) => {
            tempPrice.push({ id: obj.attributes.id, maximum_retail_price: obj.attributes.maximum_retail_price, sale_price: obj.attributes.sale_price, gst_id: '', name: obj.attributes.product_name, size: obj.attributes.measurement_size_value, color: obj.attributes.color })
          });

          this.setState({
            priceData: tempPrice,
            gstId: (responseJson.data.attributes.gst_id as any[]).length && responseJson.data.attributes.gst_id[0].id || '',
            isSamePrice: responseJson.data.attributes.maximum_retail_price ? true : false
          });

        } else if (apiRequestCallId === this.gstApiCallId) {
          this.gstData = [];
          responseJson.data.map((d: any) => {
            this.gstData.push({ label: d.attributes.name, value: d.id })
          });
          this.setState({ gstData: this.gstData });

        } else if (apiRequestCallId === this.addProductPriceApiCallId) {
          // go to add price detail price detais
          this.gotoProductOtherInfoScreen();

        } else if (apiRequestCallId === this.addPriceVariationApiCallId) {
          // go to add price detail price detais
          this.gotoProductOtherInfoScreen();
        }
      }

      // api errors 
      if(responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      }

      // api handle catch response
      if(errorReponse){
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  goToLoginScreen = () => {
    console.log("go to login screen:::");
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMobilePhoneLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  gotoProductOtherInfoScreen = () => {
    console.log("next:::");
    const msg: Message = new Message(
      getName(MessageEnum.NavigationProductOtherInfoMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.VendorAddUpdateProductDataMessage), {
      id: this.state.id,
      isEdit: this.state.isEdit
    });
    this.send(msg);
  }

  gotoProductDetailScreen = () => {
    console.log("back:::");
    const msg: Message = new Message(
      getName(MessageEnum.NavigationProductDetailMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    // msg.addData(getName(MessageEnum.VendorAddUpdateProductDataMessage), {
    //   id: this.state.id,
    //   isEdit: this.state.isEdit
    // });
    this.send(msg);
  }

  handleChange = (event: any) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value
    });
  }

  getProductData = () => {

    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token
    };

    const productId = this.state.id;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProductDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductDataApi + `/${productId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getGstListApi() {

    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.gstApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDropDown + "?value_type=gst"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  addProductPriceApiCall = () => {

    if (this.state.isSamePrice) {
      const header = {
        "Content-Type": configJSON.apiContentType,
        "token": this.state.token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addProductPriceApiCallId = requestMessage.messageId;

      let productID = this.state.id;

      let httpBody = {}

      httpBody = {
        maximum_retail_price: this.state.maximum_retail_price,
        sale_price: this.state.salePrice,
        gst_id: this.state.gstId,
        screen: 3
      };

      console.log('htttp dii ------>>>', httpBody)

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.productEditAPiEndPoint + `/${productID}`
      );

      console.log('url  ------>>>', configJSON.productEditAPiEndPoint + productID)

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {

      let hasError = false;
      hasError = this.validateProductVariants();
      if (hasError) {
        this.myRef.current?.scrollIntoView({ behavior: 'auto' });
        this.setState({ hasError: true })
        return true;
      }
      this.updatePriceVariationDetail()
    }
  }

  updatePriceVariationDetail = () => {
    const header = {
      // 'Content-Type': `multipart/form-data`,
      'Accept': 'application/json',
      "token": this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addPriceVariationApiCallId = requestMessage.messageId;


    let formdata = new FormData();
    let productData: any[] = [];
    formdata.append(`gst_id`, this.state.gstId);
    this.state.priceData &&
      this.state.priceData.length ?
      this.state.priceData.map((price: any, index: number) => {
        formdata.append(`catalogue_variants_attributes[${index}][id]`, price.id);

        formdata.append(`catalogue_variants_attributes[${index}][maximum_retail_price]`, price.maximum_retail_price || "");
        formdata.append(`catalogue_variants_attributes[${index}][sale_price]`, price.sale_price || "");

        for (let fd of formdata) {
          console.log(fd);
        }
        productData.push(formdata);
        return;
      }
      ) : [];

    formdata.append('screen', "3");

    console.log('formdata:::', formdata)
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productEditAPiEndPoint + `/${this.state.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethodType
    );

    console.log('http requestMessage', requestMessage)

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onUpdatePrice = (value: any, item: any, isFromTextField: any) => {
    let newMulticityFormData = this.state.priceData;
    let selectedIndex = newMulticityFormData.findIndex((itemFilter: any) => itemFilter.id == item.id);
    if (isFromTextField == 'gst_id')
      newMulticityFormData[selectedIndex].gst_id = value;
    else if (isFromTextField === 'retailPrice')
      newMulticityFormData[selectedIndex].maximum_retail_price = value;
    else if (isFromTextField === 'salePrice')
      newMulticityFormData[selectedIndex].sale_price = value;

    this.setState({ priceData: newMulticityFormData });
  }

  validateProductVariants = () => {
    let hasError = false;
    hasError = !this.state.gstId ? true : false;
    this.state.priceData &&
      this.state.priceData.length &&
      this.state.priceData.map((variant: any) => {
        if (!variant.maximum_retail_price || !variant.sale_price) {
          hasError = true
        }
      });
    return hasError;
  }
  // Customizable Area End

}

