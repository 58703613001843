import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import CancelOrderWeb from "./CancelOrder.web";
import { paginationCount } from "../../../framework/src/Constants";
import { scrollToTopOfElement } from "../../../framework/src/commonFunction";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: number;
    // children: React.ReactNode;
    callbackShopWithFriend: Function;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    token: string | null;
    accountId: number;
    isLoading: boolean;
    displayCancelOrderModal: boolean;
    cancelOrderId: string;
    openAddReviewModal: boolean;
    openDeleteReviewModal: boolean;
    exchangeItems: boolean;
    filterValue: string;
    orders: any[];
    order: any;
    selectedProductItemDetail: any;
    reviewId: number;

    totalPage: number;
    currentPage: number;
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class OrderListController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getCustomerOrderListAPICallId: any;
    deleteCustomerReviewApiCallId: any;

    elementRef: any;
    filterValue = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // console.log("props controller:::", props);
        // console.log("props controller:::", this.props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        this.state = {
            token: localStorage.getItem('authToken'),
            accountId: Number(localStorage.getItem('userId')) || 0,
            isLoading: false,
            displayCancelOrderModal: false,
            openDeleteReviewModal: false,
            cancelOrderId: '',
            openAddReviewModal: false,  // make it false
            exchangeItems: false,
            filterValue: '',
            orders: [],
            order: {},
            selectedProductItemDetail: {},
            reviewId: 0,
            totalPage: 0,
            currentPage: 1
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();

        this.getCustomerOrders('');
    }

    // temporary
    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        // console.log("message::::", message);


        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        // console.log("apiRequestCallId:::::", apiRequestCallId);
        // console.log("response json",responseJson);

        // Get Customer Order List
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getCustomerOrderListAPICallId !== null && apiRequestCallId === this.getCustomerOrderListAPICallId) {
            this.getCustomerOrderListAPICallId = null;
            this.setState({ isLoading: false });
            if (responseJson?.data && (responseJson?.data as any[]).length) {
                // display orders into 
                // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                let response: any[] = responseJson.data;
                // response.sort((a, b) => Number(a.id) - Number(b.id)).reverse();
                this.setState({ orders: response, totalPage: responseJson?.meta?.total_pages, });

                scrollToTopOfElement(this.elementRef);
            }
            else {
                // display no orders found in customer account 
                this.setState({ orders: [], totalPage: 0 })
            }

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.data) {
                // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END Get Customer Order List

        // Delete customer review
        else if (getName(MessageEnum.RestAPIResponceMessage) === message.id && this.deleteCustomerReviewApiCallId !== null && apiRequestCallId === this.deleteCustomerReviewApiCallId) {
            this.deleteCustomerReviewApiCallId = null;
            this.setState({ isLoading: false });
            if (responseJson?.data && (responseJson?.data as any[]).length) {
                // redirect to listing page or specific order page
                if (responseJson.data[0].success === 'true') {
                    // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                    this.setState({ reviewId: 0, openDeleteReviewModal: false }, () => this.getCustomerOrders(''))
                }
            }

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.data) {
                // runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
                //Need To send Login token message to save for future call
            } else {
                //Check Error Response
                this.parseApiErrorResponse(responseJson);
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // END Delete customer review

        // Customizable Area End

    }

    handlePageChange = async (currentPage: number) => {
        this.setState({
            currentPage: currentPage
        }, () => {
            this.getCustomerOrders(this.filterValue);
            scrollToTopOfElement(this.elementRef)
        })
    }

    getCustomerOrders(filter_value: string) {

        this.filterValue = filter_value;

        this.setState({ isLoading: true })

        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };

        const apiRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCustomerOrderListAPICallId = apiRequest.messageId;

        if (filter_value) {
            apiRequest.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.customerOrderListApiEndPoint + `?status=${filter_value}&page=${this.state.currentPage}&per_page=${paginationCount}`
            );
        } else {
            apiRequest.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.customerOrderListApiEndPoint + `?page=${this.state.currentPage}&per_page=${paginationCount}`
            );
        }
        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        apiRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.GetMethodType
        );

        runEngine.sendMessage(apiRequest.id, apiRequest);
        return true;
    }

    callCustomerOrders = (): void => {
        // console.log("function called by props:::")
        this.setState({ openAddReviewModal: false }, () => this.getCustomerOrders(''));
    }

    goToCancelOrderScreen = () => {
        this.props.navigation.navigate('cancelOrder', { id: this.state.order.id });
    }

    hideWriteReviewModal = () => {
        // console.log("hide hideWriteReviewModal:::");
        this.setState({
            openAddReviewModal: false,
            selectedProductItemDetail: ''
        });
    }

    deleteReview = () => {

        const header = {
            "Content-Type": configJSON.exampleApiContentType,
            token: this.state.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteCustomerReviewApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.customerReviewApiEndPoint + `/${this.state.reviewId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.DeleteMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
}