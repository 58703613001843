export const GroupShoppingStyleWeb: any = {
  shopWithFriends: {
    resize: "vertical",
    width: "400px",
    overflowX: "hidden",
    overflowY: "auto",
    minHeight: "280px",
    maxHeight: "82vh",
    background: "white",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    zIndex: "10000",
    position: "fixed",
    bottom: "50px",
    right: "50px",
    "& .top-nav": {
      background: "black",
      height: "30px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      position: "sticky",
      top: "0",
      zIndex: "100",
    },
    "& .top-nav .title": {
      color: "white",
      paddingLeft: "10px",
    },
    "& .top-nav svg": {
      fill: "white",
      // float: "right",
      cursor: "pointer",
    },
    "& .chat-friend": {
      height: "calc(100% - 30px)",
    },
    "& .chat-friend .first-page": {
      position: "relative",
      height: "calc(100% - 50px)",
    },
    "& .chat-friend .first-page .add-icon": {
      width: "50px",
      height: "50px",
      background: "black",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      // bottom: "-40px",
      // right: "15px",
      bottom: "75px",
      right: "75px",
      float: "right",
      position: "fixed",
      // position: "fixed",
      cursor: "pointer",
    },
    "& .chat-friend .first-page .no-group": {
      textAlign: "center",
      padding: "20px 10px 10px 10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    "& .chat-friend .first-page .no-group img": {
      marginBottom: "10px",
      width: "80px",
      height: "80px",
    },
    "& .chat-friend .first-page .no-group p": {
      marginBottom: "5px",
    },
    "& .chat-friend .first-page .groups": {
      padding: "0px 10px",
      height: "100%",
    },
    "& .chat-friend .first-page .groups .MuiAvatar-root": {
      width: "60px",
      height: "60px",
    },
    "& .chat-friend .MuiAvatar-root": {
      textTransform: "uppercase",
      fontSize: "16px",
    },
    "& .chat-friend .first-page .groups .group": {
      display: "flex",
      justifyContent: " flex-start",
      alignItems: "center",
      padding: "10px 0",
      borderBottom: "1px solid gray",
      cursor: "pointer",
    },
    "& .chat-friend .first-page .groups .group .info": {
      margin: "0 0 0 5px",
      width: " 80%",
    },
    "& .chat-friend .first-page .groups .group .accept-decline": {
      display: "flex",
    },
    "& .chat-friend .first-page .groups .group .accept-decline button": {
      border: "1px solid black",
      padding: "2px",
    },
    "& .chat-friend .first-page .groups .group .accept-decline button:first-child": {
      marginRight: "5px",
    },
    "& .chat-friend .first-page .groups .group .info .name-time": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .chat-friend .first-page .groups .group .info .name-time b": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "70%",
    },
    "& .chat-friend .first-page .groups .group .info .name-time span": {
      marginLeft: "5px",
    },
    "& .chat-friend .first-page .groups .group .info .last-msg-no-read": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .chat-friend .first-page .groups .group .info .last-msg-no-read span": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .chat-friend .first-page .groups .group .info .last-msg-no-read .no-read": {
      marginLeft: "5px",
      color: "white",
      background: "red",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      textAlign: "center",
      fontSize: "14px",
    },
    "& .chat-friend .create-group": {
      padding: "10px 20px",
    },
    "& .chat-friend .create-group .create-group-form": {
      marginTop: "20px",
    },
    "& .chat-friend .create-group .create-group-form .MuiFormControl-fullWidth": {
      marginTop: "15px",
    },
    "& .chat-friend .create-group .create-group-form button": {
      marginTop: "15px",
    },
    "& .chat-friend .created-group .create-group-form": {
      marginTop: "20px",
    },
    "& .chat-friend .created-group .create-group-form .MuiFormControl-fullWidth": {
      marginTop: "15px",
    },
    "& .chat-friend .created-group .create-group-form button": {
      marginTop: "15px",
    },
    "& .chat-friend .created-group": {
      padding: "10px 20px",
    },
    "& .chat-friend .created-group .add-link": {
      padding: "10px 0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .chat-friend .created-group .add-link svg": {
      fill: "white",
      background: "black",
      padding: "5px",
      width: "40px",
      height: "40px",
      borderRadius: "50%",
    },
    "& .chat-friend .created-group .members-div": {
      marginTop: "15px",
    },
    "& .chat-friend .created-group .members .member": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "8px 0",
    },
    "& .chat-friend .created-group .members .member .name-icon": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    "& .chat-friend .created-group .members .member .name-icon span": {
      marginLeft: "10px",
    },
    "& .chat-friend .created-group .members .member button": {
      fontSize: "10px",
      padding: "0",
      textTransform: "capitalize",
    },
    "& .chat-friend .search-add-people": {
      padding: "10px 20px",
    },
    "& .chat-friend .search-add-people .search": {
      position: "relative",
      marginTop: "10px",
      marginBottom: "10px",
    },
    "& .chat-friend .search-add-people .search input": {
      border: "1px solid #d5d5d5",
      borderRadius: "5px",
      padding: "5px 30px 5px 5px",
    },
    "& .chat-friend .search-add-people .search svg": {
      position: "absolute",
      top: "6px",
      fill: "#d5d5d5",
      right: "5px",
      cursor: "pointer"
      // left: "7px",
    },
    "& .chat-friend .search-add-people .members .member": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "8px 0",
    },
    "& .chat-friend .search-add-people .members .member .name-icon": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    "& .chat-friend .search-add-people .members .member .name-icon span": {
      marginLeft: "10px",
    },
    "& .chat-friend .chat-screen": {
      height: "100%",
      overflow: "hidden",
    },
    "& .chat-friend .chat-screen .back": {
      background: "white",
      padding: "10px 20px",
      position: "sticky",
      // top: "30px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid #d3d3d3",
    },
    "& .chat-friend .chat-screen .back .back-icon": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .chat-friend .chat-screen .back .back-icon svg": {
      margin: "0 5px",
      cursor: "pointer",
    },
    "& .chat-friend .chat-screen .back .back-icon button.MuiButtonBase-root": {
      padding: "0",
    },
    "& .chat-friend .chat-screen .back span": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .chat-friend .chat-screen .text-chat": {
      background: "white",
      position: "sticky",
      bottom: "0",
      left: "0",
      right: "0",
      padding: "5px 15px",
      boxShadow:
        "rgb(0 0 0 / 10%) 0px 0px 5px 0px, rgb(0 0 0 / 10%) 0px 0px 1px 0px",
      // display: "flex",
      // justifyContent: "space-between",
      // alignItems: "center",
      // zIndex: "100",
      width: "100%",
    },
    "& .chat-friend .chat-screen .text-chat .input-box": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .chat-friend .chat-screen .text-chat svg": {
      cursor: "pointer",
    },
    "& .chat-friend .chat-screen .text-chat .input-div": {
      position: "relative",
      width: "100%",
      marginRight: "10px",
    },
    "& .chat-friend .chat-screen .text-chat .input-div input": {
      border: "none",
      background: "#eaeaea",
      borderRadius: "5px",
      padding: "5px 30px 5px 5px",
    },
    "& .chat-friend .chat-screen .text-chat .input-div svg": {
      position: "absolute",
      top: "5px",
      right: "6px",
      transform: "rotate(45deg)",
    },
    "& .chat-friend .chat-screen .text-chat span": {
      fontSize: "10px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    "& .chat-friend .chat-screen .text-chat .input-box > svg": {
      fill: "white",
      background: "black",
      borderRadius: "50%",
      width: "30px",
      height: "30px",
      padding: "5px",
    },
    "& .chat-friend .chat-screen .chat": {
      minHeight: "170px",
      padding: "5px 15px",
      height: "calc(100% - 90px)",
      overflowY: "auto",
    },
    "& .chat-friend .chat-screen .chat .name": {
      fontSize: "14px",
    },
    "& .chat-friend .chat-screen .msg-div": {
      width: "100%",
      wordBreak: "break-all",
      fontSize: "16px",
      display: "flex",
      alignContent: "center",
      justifyContent: "flex-start",
    },
    "& .chat-friend .chat-screen .image-div": {
      display: "flex",
      alignContent: "center",
      justifyContent: "flex-start",
      margin: "5px 0",
    },
    "& .chat-friend .chat-screen .image-div img": {
      width: "200px",
      height: "140px",
    },
    "& .chat-friend .chat-screen .my.image-div": {
      justifyContent: "flex-end",
    },
    "& .chat-friend .chat-screen .my.msg-div": {
      justifyContent: "flex-end",
    },
    "& .chat-friend .chat-screen .msg-div .msg-chat": {
      maxWidth: "85%",
      background: "#F7F7F7",
      color: "black",
      margin: "5px 0",
      display: "inline-block",
      padding: "4px 6px",
      borderRadius: "5px",
    },
    "& .chat-friend .chat-screen .my .msg-chat": {
      background: "black",
      color: "white",
    },
    "& .chat-friend .chat-screen .setting": {
      padding: "10px 0",
      background: "white",
      position: "sticky",
      bottom: "0",
      left: "0",
      right: "0",
      boxShadow:
        "rgb(0 0 0 / 28%) 0px 0px 25px 0px, rgb(0 0 0 / 20%) 0px 0px 33px 0px",
    },
    "& .chat-friend .chat-screen .setting b": {
      padding: "0 18px",
    },
    "& .chat-friend .edit-group .back": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .chat-friend .edit-group .create-group-form": {
      position: "relative",
    },
    "& .chat-friend .edit-group .create-group-form svg": {
      position: "absolute",
      top: "60px",
      right: "2px",
    },
    "& .chat-friend .wishlist": {
      padding: "0 0",
    },
    "& .chat-friend .wishlist .back": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      position: "sticky",
      background: "white",
      top: "30px",
      padding: "5px 20px",
    },
    "& .chat-friend .wishlist .back .title": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    "& .chat-friend .wishlist .back .back-icon": {
      display: "flex",
    },
    "& .chat-friend .wishlist .back .back-icon svg": {
      margin: "0 5px",
    },
    "& .chat-friend .wishlist .chat": {
      background: "#f3f3f3",
    },
    "& .chat-friend .wishlist .chat .wishlist-container": {
      background: "white",
      padding: "15px",
      marginBottom: "8px",
    },
    "& .chat-friend .wishlist .chat .wishlist-container .added-by": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    "& .chat-friend .wishlist .chat .wishlist-container .added-by img": {
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      border: "1px solid darkgray",
    },
    "& .chat-friend .wishlist .chat .wishlist-container .wishlist-item": {
      display: "flex",
      justifyContent: "flex-start",
      // alignItems: "center",
      marginTop: "5px",
    },
    "& .chat-friend .wishlist .chat .wishlist-container .wishlist-item img": {
      width: "70px",
      height: "85px",
      borderRadius: "5px",
    },
    "& .chat-friend .wishlist .chat .wishlist-container .wishlist-item .item-content": {
      paddingLeft: "10px",
    },
    "& .chat-friend .wishlist .chat .wishlist-container .added-by .name": {
      paddingLeft: "10px",
      textTransform: "capitalize",
    },
    "& .chat-friend .wishlist .chat .wish-cart": {
      marginTop: "10px",
      borderTop: "1px solid darkgray",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      cursor: "pointer",
    },
    "& .chat-friend .wishlist .chat .wish-cart .remove-from-wish": {
      fontWeight: "600",
      color: "#525252",
      margin: "8px 0",
    },
    "& .chat-friend .wishlist .chat .wish-cart .add-to-cart": {
      fontWeight: "600",
      color: "#525252",
      margin: "8px 0",
    },
    "& .chat-friend .wishlist .chat .wish-cart .cBorder": {
      borderRight: "2px solid #8f8f8f",
      height: "20px",
    },
  },
};
