import React, { Component } from "react";
import {
  Box,
  TextField,
  InputLabel,
  Input,
  Divider,
  FormControl,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from "@material-ui/core";
import SizeChartController, { Props } from "./SizeChartController.web";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { configJSON } from "./SizeChartController.web";
import * as _ from "lodash";

const styles = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "50px 0",
    fontFamily: "'Montserrat', sans-serif",
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "700",
      fontFamily: "'Montserrat', sans-serif",
      textTransform: "uppercase",
      color: "#121212",
    },
    "& h4": {
      margin: "0 0 20px 0",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "600",
      color: "#121212",
      fontFamily: "'Montserrat', sans-serif",
    },
    "& p": {
      fontFamily: "'Montserrat', sans-serif",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "30px 15px 40px",
    },
    "& .block-wrapper-inner": {
      maxWidth: "500px",
      margin: "0 auto",
    },
    "& .error-msg": {
      fontSize: "13px",
      color: "red",
    },
    "& label": {
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.54)",
    },
    "& .MuiFormControl-root": {
      display: "block",
    },
    "& .MuiInputBase-root": {
      display: "block",
    },
    "& .MuiSelect-select": {
      background: "transparent",
    },
    "& .MuiFormControl-root .MuiInputBase-root": {
      display: "block",
    },
    "& .title-btn-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "25px 0",
    },
    "& .title-btn-wrapper h4": {
      margin: "0 0 0",
    },
    "& .title-btn-wrapper .black-btn": {
      minWidth: "100px",
    },
    "& .btn-wrapper": {
      textAlign: "center",
      paddingTop: "20px",
    },
    "& .guide-block": {
      border: "1px solid rgba(0, 0, 0, 0.42)",
      padding: "25px 15px",
      textAlign: "center",
      borderRadius: "6px",
    },
    "& .guide-block p": {
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.54)",
    },
    "& .add-row": {
      margin: "30px 0 0",
      borderTop: "3px solid #e3e3e3",
    },
    "& .add-inner-row": {
      margin: "0 0 30px",
      borderBottom: "3px solid #e3e3e3",
    },
    "& .add-inner-row .row-title-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 0 25px",
    },
    "& .add-inner-row .row-title-wrapper p": {
      margin: "0 0 0",
    },
    "& .add-inner-row .row-title-wrapper img": {
      height: "15px",
      width: "15px",
      cursor: "pointer",
    },
  },
};
interface MyFormValues {
  product_name: string;
  product_cat: string;
  product_sub_cat?: string;
  measurement_unit: string;
  size_chart_column_name: any[];
  size_chart_column_value: any[];
}

// Validations
const FormSchema = Yup.object().shape({
  product_name: Yup.string().required('Required'),
  product_cat: Yup.string().required('Category is required!'),
  product_sub_cat: Yup.string().required('Sub-Category is required!'),
  measurement_unit: Yup.string().required('Measurement unit is required!'),
});
export class CreateChart extends SizeChartController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    this.getProductCategoies();
    this.setState({
      cols: [{ label_name: "", variant_size_name_id: "" }],
      chartRows: [{ label_value: "" }],
    })
  }

  /**
   * Function that returns categories
   * @returns React JSX component for categories
   */
  rendercategories() {
    let categories: any = [];
    this.state.categories.forEach((categ: any) => {
      const cate = (
        <MenuItem value={categ?.attributes?.id}>
          <Box>
            <Typography className="size-name">{_.startCase(_.toLower(categ?.attributes?.name))}</Typography>
          </Box>
        </MenuItem>
      );
      categories.push(cate);
    })
    return categories;
  }

  /**
   * Function that returns sub-categories based on the selected category
   * @returns React JSX component for sub-categories
   */
  rendersubcategories() {
    let categories: any = [];
    if (this.state.subCategories != null) {
      this.state.subCategories.forEach((scateg: any) => {
        const cate = (
          <MenuItem value={scateg?.id}>
            <Typography className="size-name">{_.startCase(_.toLower(scateg?.attributes?.name))}</Typography>
          </MenuItem>
        );
        categories.push(cate);
      })
    } else {
      const cate = (
        <MenuItem>
          <Typography className="size-name">No subcategory avaialble!</Typography>
        </MenuItem>
      )
      categories.push(cate);
    }
    return categories;
  }

  /**
   * 
   * @param event data related to onChange event for the category dropdown
   */
  handleCategoryChange(event: any) {
    let val = event.target.value;
    this.setState({
      productCategory: val
    });

    this.getProductSubCategoies(val);
    this.rendersubcategories();
  }

  render() {
    const { classes } = this.props;
    const initialValues: MyFormValues = {
      product_name: '',
      product_cat: '',
      product_sub_cat: '',
      measurement_unit: '',
      size_chart_column_name: this.state.cols,
      size_chart_column_value: this.state.chartRows,
    };

    return (
      <>
        <Box className={classes.pageOuter}>
          <Box>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12}>
                  <h2>Create Size Chart</h2>
                </Grid>
                <Grid item xs={12}>
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={FormSchema}
                    onSubmit={(values, actions) => {
                      // restructure chartRows
                      let cols: object[] = [];
                      this.state.cols.map((col: any) => {
                        cols.push({ label_value: _.toString(_.map(this.state.chartRows, col.label_name)) });
                      });
                      // renaming properties product_cat with categories_id and product_sub_cat with sub_categories_id
                      let sizeChart: { product_name: string, measurement_unit: string, categories_id: number, sub_categories_id: number, size_chart_column_value: any, size_chart_column_name: any } = {
                        product_name: values.product_name,
                        categories_id: Number(values.product_cat),
                        sub_categories_id: Number(values.product_sub_cat),
                        measurement_unit: values.measurement_unit,
                        size_chart_column_name: values.size_chart_column_name,
                        size_chart_column_value: cols
                      };

                      // calling the addSizeCharts method of parent controller class
                      if (
                        sizeChart.size_chart_column_value.filter((x: { label_value: string }) => x.label_value == 'undefined').length ||
                        sizeChart.size_chart_column_value.filter((x: { label_value: string }) => x.label_value == '').length ||
                        sizeChart.size_chart_column_value.filter((x: { label_value: string }) => x.label_value.indexOf('undefined') > -1).length ||
                        sizeChart.size_chart_column_name.filter((x: { label_name: string, id: string }) => x.label_name == '').length ||
                        sizeChart.size_chart_column_name.filter((x: { label_name: string, id: string }) => x.label_name == 'undefined').length ||
                        sizeChart.size_chart_column_name.length != sizeChart.size_chart_column_value.length
                      ) {
                        this.setState({ formError: true });
                      } else {
                        this.addSizeCharts(sizeChart);
                        actions.setSubmitting(false);
                      }
                    }}
                  >
                    {({ values, touched, errors, handleChange, handleBlur }) => {
                      if (this.state.subCategories == undefined) {
                        delete values.product_sub_cat;
                      }
                      return (
                        <Form translate>
                          <Box className="block-wrapper">
                            <Box className="block-wrapper-inner">
                              <Typography variant="h4">Add Product Detail</Typography>
                              <Box className="form-group">
                                <InputLabel htmlFor="my-input">
                                  Chart Title / Product Name
                                </InputLabel>
                                <FormControl>
                                  <Field name="product_name" />
                                  {errors.product_name && touched.product_name ? (
                                    <div style={{ "color": "red" }}>{errors.product_name}</div>
                                  ) : null}
                                </FormControl>
                              </Box>
                              <Box className="form-group">
                                <InputLabel id="product-category">
                                  Product Category
                                </InputLabel>
                                <Select
                                  labelId="product-category"
                                  value={values.product_cat}
                                  name="product_cat"
                                  displayEmpty
                                  onChange={(e: any) => { handleChange(e); this.handleCategoryChange(e) }}
                                  onBlur={handleBlur}
                                >
                                  {
                                    this.rendercategories()
                                  }
                                </Select>
                                {errors.product_cat && touched.product_cat ? (
                                  <div style={{ "color": "red" }}>{errors.product_cat}</div>
                                ) : null}
                              </Box>
                              <Box className="form-group">
                                <InputLabel id="product-subcategory">
                                  Product Subcategory
                                </InputLabel>
                                <Select
                                  labelId="product-subcategory"
                                  name="product_sub_cat"
                                  value={this.state.subCategories ? values.product_sub_cat : ''}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  {
                                    this.rendersubcategories()
                                  }
                                </Select>
                                {(errors.product_sub_cat && touched.product_sub_cat) || this.state.subCategories == undefined ? (
                                  <div style={{ "color": "red" }}>{errors.product_sub_cat}</div>
                                ) : null}
                                <Box display={this.state.subcatError ? 'flex' : 'none'}>
                                  <Typography color="error">Required</Typography>
                                </Box>
                              </Box>
                              <Box className="form-group">
                                <InputLabel id="measurement-unit">
                                  Measurement Units
                                </InputLabel>
                                <Select
                                  labelId="measurement-unit"
                                  displayEmpty
                                  name="measurement_unit"
                                  value={values.measurement_unit}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <MenuItem value="centimeter">Cm</MenuItem>
                                  <MenuItem value="inch">Inches</MenuItem>
                                  <MenuItem value="meter">Meters</MenuItem>
                                </Select>
                                {errors.measurement_unit && touched.measurement_unit ? (
                                  <div style={{ "color": "red" }}>{errors.measurement_unit}</div>
                                ) : null}
                                <Box display={this.state.meaError ? 'flex' : 'none'}>
                                  <Typography color="error">Required</Typography>
                                </Box>
                              </Box>
                              <Box className="guide-block">
                                <Typography variant="h4">How to create size chart guide?</Typography>
                                <p>Please check demo file for create size chart </p>
                                <Button
                                  type="button"
                                  variant="contained"
                                  data-testid="Sample"
                                  className="black-btn"
                                  onClick={(e) => {
                                    this.setState({
                                      viewChartTable: true,
                                    });
                                  }}
                                >
                                  View Sample Size Chart
                                </Button>
                              </Box>
                              <Box className="add-row">
                                <Box className="add-col" id="b1">
                                  <Box>
                                    <Box className="title-btn-wrapper">
                                      <h4>Add Column</h4>
                                      <Button
                                        variant="outlined"
                                        className="black-btn"
                                        type="button"
                                        onClick={() => this.addColumn()}
                                      >Add</Button>
                                    </Box>
                                    {values.size_chart_column_name && values.size_chart_column_name.length > 0 ? (
                                      values.size_chart_column_name.map((columns, index) => (
                                        <Box key={index} mb={5}>
                                          <Box className="form-group">
                                            <Box display="flex" justifyContent="space-between">
                                              <InputLabel htmlFor="name">Column {index + 1} </InputLabel>
                                              {(index >= 1) ? <CloseIcon onClick={() => this.removeColumn(index)}></CloseIcon> : <></>}
                                            </Box>
                                            <FormControl>
                                              <TextField value={values.size_chart_column_name[index].label_name} onChange={(e: any) => this.updateColumns(index, e)} />
                                            </FormControl>
                                          </Box>
                                        </Box>
                                      ))
                                    ) : (
                                      <Box className="title-btn-wrapper">
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                              <Box className="add-row">
                                <Box>
                                  <Box className="title-btn-wrapper">
                                    <h4>Add Row</h4>
                                    <Button
                                      variant="outlined"
                                      className="black-btn"
                                      type="button"
                                      id="add-row"
                                      onClick={() => this.addRow()}
                                    >Add</Button>
                                  </Box>
                                  {/* render rows for the size chart */}
                                  {values.size_chart_column_value.map((row, index) => (
                                    <Box key={index} mb={3}>
                                      <Box className="add-inner-row">
                                        <Box mb={3} display="flex" justifyContent="space-between">
                                          <InputLabel htmlFor="name">Row {index + 1}</InputLabel>
                                          {(index >= 1) ? <CloseIcon onClick={() => this.removeRow(index)}></CloseIcon> : <></>}
                                        </Box>
                                        {values.size_chart_column_name.map((column, colIndex) => {
                                          return (
                                            <Box mb={3}>
                                              <InputLabel htmlFor="name">{column.label_name}</InputLabel>
                                              <FormControl>
                                                <TextField value={values.size_chart_column_value[index][column.label_name]} onChange={(e) => this.updateRow(index, colIndex, e)} name={`size_chart_column_value[${colIndex}][${index}]label_value`} />
                                              </FormControl>
                                            </Box>
                                          )
                                        })}
                                      </Box>
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                              <Box display={this.state.formError ? 'flex' : 'none'}>
                                <div style={{ "color": "red" }}>{configJSON.formError}</div>
                              </Box>
                              <Box className="btn-wrapper">
                                <Button
                                  variant="contained"
                                  className="black-btn"
                                  type="submit"
                                > Create</Button>
                              </Box>
                            </Box>
                          </Box>
                        </Form>
                      )
                    }}
                  </Formik>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="customized-dialog-duplicate"
            open={this.state.viewChartTable}
            className="dummy-chart-dialog"
          >
            <DialogContent>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography variant="h5">
                    Dummy Size Charts
                  </Typography>
                  <Typography variant="subtitle1">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </Typography>
                </Box>
                <Box>
                  <Button onClick={(e) => {
                    this.setState({
                      viewChartTable: false,
                    });
                  }}>
                    <CloseIcon />
                  </Button>
                </Box>
              </Box>
              <img width="100%" src={require('../assets/dummy-chart.png')}></img>
            </DialogContent>
          </Dialog>
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(CreateChart);
