import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItems from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";
import { RadioGroup, Radio, FormControlLabel, Link } from "@material-ui/core";
import {
  Menu,
  MenuItem,
  MenuButton,
  SubMenu,
} from "../../../../node_modules/@szhsin/react-menu";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import BackArrow from "@material-ui/icons/ArrowBack";
import {
  Box,
  TextField,
  InputLabel,
  Input,
  Divider,
  FormControl,
} from "@material-ui/core";
import SchedulePickupController, { configJSON } from "./SchedulePickupController.web";
import Loader from "../../../components/src/Loader.web";
import moment from "moment";
import { CatalogueVariantModel, imageModel, ProductModel } from "../../../framework/src/Interfaces/IProductDetail";
import { OrderItemModel } from "../../../framework/src/Interfaces/IOrderDetail";
import { ORDER_STATUS_TYPES } from "../../../framework/src/Enum";

const styles = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "50px 0",
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      textTransform: "uppercase",
      color: "#121212",
      fontSize: "20px",
      // margin: "0 0 20px",
    },
    "& h4": {
      margin: "0 0 20px 0",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "30px 15px 40px",
    },
    "& .block-wrapper-inner": {
      maxWidth: "515px",
      margin: "0 auto",
    },
    "& .error-msg": {
      fontSize: "13px",
      color: "red",
    },
    "& label": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#121212",
    },
    "& .MuiFormControl-root": {
      display: "block",
    },
    "& .MuiInputBase-root": {
      display: "block",
    },
    "& .MuiSelect-select": {
      background: "transparent",
    },
    "& .MuiFormControl-root .MuiInputBase-root": {
      display: "block",
    },
    "& .title-btn-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "25px 0",
    },
    "& .title-btn-wrapper h4": {
      margin: "0 0 0",
    },
    "& .title-btn-wrapper .black-btn": {
      minWidth: "100px",
    },
    "& .btn-wrapper": {
      textAlign: "center",
      paddingTop: "20px",
    },
    "& .guide-block": {
      border: "1px solid rgba(0, 0, 0, 0.42)",
      padding: "25px 15px",
      textAlign: "center",
      borderRadius: "6px",
    },
    "& .guide-block p": {
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.54)",
    },
    "& .add-row": {
      margin: "30px 0 0",
      borderTop: "1px solid rgba(0,0,0,0.45)",
    },
    "& .add-inner-row": {
      margin: "0 0 30px",
      borderBottom: "1px solid rgba(0,0,0,0.45)",
    },
    "& .add-inner-row .row-title-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 0 25px",
    },
    "& .add-inner-row .row-title-wrapper p": {
      margin: "0 0 0",
    },
    "& .add-inner-row .row-title-wrapper img": {
      height: "15px",
      width: "15px",
      cursor: "pointer",
    },

    "& .block-wrapper-inner p": {
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "16px",
      margin: "0 0 15px",
    },
    "& .product-wrapper": {
      border: "1px solid #d3d3d3",
      padding: "15px",
      margin: "0 0 20px",
    },
    "& .product_detail": {
      margin: "0 0 20px",
    },
    "& .product_detail p": {
      margin: "0 0 10px",
    },
    "& .product_detail p:first-child": {
      margin: "0 0 15px",
    },
    "& .product_detail p b": {
      color: "#000",
      fontWeight: "400",
    },
    "& .custom-radio": {
      display: "flex",
      flexDirection: "row",
    },
    "& .custom-radio label": {
      color: "#000",
    },
    "& .custom-radio label .MuiIconButton-label": {
      color: "#000",
    },

    //order-block
    "& .order-block .title-btn-wrapper": {
      padding: "0",
      margin: "0 0 13px",
    },
    "& .order-block p": {
      fontSize: "14px",
      lineHeight: "20px",
      color: "#121212",
      margin: "0 0 15px",
      fontWeight: "600",
    },
    "& .order-detail": {
      borderBottom: "1px solid #e3e3e3",
      margin: "0 0 30px",
      padding: "0 0 6px",
    },
    "& .order-detail .user-detail": {
      fontSize: "14px",
      lineHeight: "20px",
      color: "#5d5d66",
      margin: "0 0 10px",
      fontWeight: "400",
    },
    "& .package-detail p": {
      fontWeight: "500",
      margin: "0 0 23px",
    },
    "& .package-detail": {
      margin: "0 0 50px",
    },
    "& .package-detail.mb-0": {
      margin: "0 0 0",
    },
    "& .order-details-wrapper": {
      borderBottom: "3px solid #e3e3e3",
      margin: "0 0 35px",
      padding: "0 0 15px",
    },
    "& .productlist-wrapper": {
      padding: "0",
    },
  },
};

export class SchedulePickup extends SchedulePickupController {
  constructor(props: any) {
    super(props);
  }

  renderAddressList = () => {
    const { addressList } = this.state;
    return (
      <>
        <ul>
          {addressList &&
            addressList.length ?
            addressList.map((address) => {
              return (
                <li key={address.id}>
                  <RadioGroup className="custom-radio" name="price" onClick={(e: any) => this.setState({ defaultAdddressId: e.target.value, addressDetail: address, changePickupAddress: false })} >
                    <FormControlLabel
                      value={address.id}
                      // onClick={(e: any) => this.setState({ defaultAdddressId: e.target.value, addressDetail: address, changePickupAddress: false })}
                      control={<Radio color="default" />}
                      checked={this.state.defaultAdddressId === address.id}
                      label={address.attributes.name}
                    />
                    <div className="address-detail" onClick={() => { console.log("event clicked") }}>
                      <div className="address">
                        {address.attributes.address}, {address.attributes.town}, {address.attributes.city} - {address.attributes.zip_code}, {address.attributes.state}
                      </div>
                      {address.attributes.is_default && (<><br /><span className="is-default"> Default </span></>)}
                      {/* <a href="#" className="default-link">
                            Default
                          </a> */}
                    </div>
                  </RadioGroup>
                </li>
              )
            }) :
            <div className="not-found">No address found</div>
          }
        </ul>
      </>
    )
  }

  renderPackageList = () => {
    const { packageList } = this.state;
    return (
      <>
        <ul>
          {packageList &&
            packageList.length ?
            packageList.map((item: any) => {
              return (
                <li key={item.id}>
                  <RadioGroup className="custom-radio" name="price" onClick={(e: any) => this.setState({ defaultPacakgeId: e.target.value, packageDetail: item, packageDimensions: false })} >
                    <FormControlLabel
                      value={item.id}
                      control={<Radio color="default" />}
                      checked={this.state.defaultPacakgeId === item.id}
                      label={item.attributes.package_name}
                    />
                    <div className="address-detail">
                      <div className="address">LWH: {item.attributes.package_length} x {item.attributes.package_width} x {item.attributes.package_height}  CM</div>
                    </div>
                  </RadioGroup>
                </li>
              )
            }) :
            <div className="not-found">No package found</div>
          }
        </ul>
      </>
    )
  }

  render() {
    const { classes } = this.props;
    const { addressDetail, addressList, packageDetail, packageList, isLoading, hasError, changePickupAddress, orderResponse } = this.state;
    const orderItems = orderResponse?.attributes?.order_items_details;
    return (
      <>
        {isLoading ? <Loader loading={isLoading} /> : null}
        {/* Schedule Pickup start */}
        <div className={classes.pageOuter}>
          <div>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12}>
                  <h2>Schedule Pickup</h2>
                </Grid>
                <Grid item xs={12}>
                  <Link style={{ color: "black" }} onClick={() => this.props.navigation.goBack()}><BackArrow /></Link>
                  <div className="block-wrapper order-block">
                    <div className="block-wrapper-inner">
                      <div className="title-btn-wrapper">
                        <h4>Shipping From</h4>
                        <Button
                          variant="outlined"
                          className="outline-btn"
                          type="button"
                          onClick={(e) => {
                            this.setState({
                              changePickupAddress: true,
                            });
                          }}
                        >
                          Change
                        </Button>
                      </div>
                      <div className="order-detail">
                        <p>{addressDetail?.attributes?.name}</p>
                        <p className="user-detail">
                          {addressDetail && Object.keys(addressDetail).length ?
                            <>
                              {addressDetail?.attributes?.address},
                              {addressDetail?.attributes?.town},
                              {addressDetail?.attributes?.city} -
                              {addressDetail?.attributes?.zip_code},
                              {addressDetail?.attributes?.state}
                            </> :
                            null
                          }
                        </p>
                      </div>
                      <div className="order-detail">
                        <h4>Shipping To</h4>
                        <p>{orderResponse?.attributes?.delivered_at?.name}</p>
                        <p className="user-detail">
                          {orderResponse?.attributes?.delivered_at?.address},
                          {orderResponse?.attributes?.delivered_at?.city},
                          {orderResponse?.attributes?.delivered_at?.state} -
                          {orderResponse?.attributes?.delivered_at?.zip_code}
                        </p>
                      </div>
                      <div className="order-details-wrapper">
                        <h4>Order Details</h4>
                        <Grid item xs={12} className="productlist-wrapper">
                          <Grid className="product-list">
                            {orderItems &&
                              orderItems.length &&
                              orderItems.map((item: OrderItemModel, index: number) => {
                                const imageUrl = item.galleries && item.galleries.length && item.galleries[0].url || '';
                                const product_name = item?.product_name;
                                const description = item?.description;
                                const brand_name = item?.brand;
                                const size = item?.measurement_size_value;
                                const price = Number(item.sale_price)
                                const color = item?.color;
                                const status = item?.status;
                                if (status === ORDER_STATUS_TYPES.CANCELLED) {
                                  return;
                                }
                                const date = this.state.orderResponse.attributes.updated_at
                                let updatedDate = this.handleDate(date)
                                return (
                                  <>
                                    <div className="product-list-inner">
                                      <div className="product-img">
                                        <img className="img-block" src={imageUrl} />
                                      </div>
                                      <div className="product-detail">
                                        <div className="product-heading">
                                          <h4 className="product-name">{product_name}({color}</h4>
                                          <h4 className="product-name">{updatedDate}</h4>
                                        </div>
                                        <div className="product-desc">
                                          {brand_name}
                                        </div>
                                        <div className="product-actions">
                                          <div className="product-desc">
                                            <span>Size: {size} </span>
                                            <span className="price">QTY: {item.quantity}</span>
                                            <span className="price">&#8377;{price}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              })
                            }
                          </Grid>
                        </Grid>
                      </div>
                      <div className="package-detail">
                        <h4>Package Details</h4>
                        <p>Package Dimensions</p>
                        <div className="form-group">
                          <InputLabel id="subtitle">Package</InputLabel>
                          <div
                            className="select-option-modal"
                            onClick={(e) => {
                              this.setState({
                                packageDimensions: true,
                              });
                            }}
                          >
                            <span className="selected-option">{packageDetail?.attributes?.package_name}</span>
                          </div>
                        </div>
                        <div className="form-group has-postfix">
                          <InputLabel htmlFor="my-input">Length</InputLabel>
                          <FormControl>
                            <Input
                              id="my-input"
                              aria-describedby="my-helper-text"
                              value={packageDetail?.attributes?.package_length}
                              readOnly
                            />
                            <span className="unit-text">CM</span>
                          </FormControl>
                        </div>
                        <div className="form-group has-postfix">
                          <InputLabel htmlFor="my-input">Width</InputLabel>
                          <FormControl>
                            <Input
                              id="my-input"
                              aria-describedby="my-helper-text"
                              value={packageDetail?.attributes?.package_width}
                              readOnly
                            />
                            <span className="unit-text">CM</span>
                          </FormControl>
                        </div>
                        <div className="form-group has-postfix">
                          <InputLabel htmlFor="my-input">Height</InputLabel>
                          <FormControl>
                            <Input
                              id="my-input"
                              aria-describedby="my-helper-text"
                              value={packageDetail?.attributes?.package_height}
                              readOnly
                            />
                            <span className="unit-text">CM</span>
                          </FormControl>
                        </div>
                      </div>
                      <div className="package-detail mb-0">
                        <p>Package Weight</p>
                        <div className="form-group has-postfix">
                          <InputLabel htmlFor="my-input">Weight</InputLabel>
                          <FormControl>
                            <Input
                              id="my-input"
                              aria-describedby="my-helper-text"
                              name="weight"
                              type="number"
                              value={this.state.packageWeight}
                              onChange={(e) => this.setState({ packageWeight: e.target.value })}
                            />
                            <span className="unit-text">KG</span>
                          </FormControl>
                          {Number(this.state.packageWeight) > 10 && <span className="error" >Package Weight maximum value will be 10 KG</span>}
                        </div>
                      </div>

                      {hasError && <div className="error">{configJSON.addressPackageNotFoundError}</div>}

                      <div className="btn-wrapper">
                        <Button
                          variant="contained"
                          className="black-btn"
                          type="button"
                          disabled={
                            !this.state.orderId ||
                            !this.state.packageWeight
                          }
                          onClick={() => this.schedulePickupOrder()}
                        >
                          Schedule Pickup
                        </Button>
                      </div>
                    </div>
                  </div>
                </Grid >
              </Grid >
            </Container >
          </div >
        </div >
        {/* Schedule Pickup end */}

        {/* Change Pickup Address */}
        <Dialog
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.changePickupAddress}
          className="select-dialog pickup-address order-dialog"
          // maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle id="customized-dialog-title">
            <span>Change Pickup Address</span>
            <CloseIcon
              className="close-dialog"
              onClick={(e) => {
                this.setState({
                  changePickupAddress: false,
                });
              }}
            />
          </DialogTitle>
          <DialogContent>
            {this.renderAddressList()}
            <div className="btn-wrapper">
              <Button
                href=""
                type="submit"
                variant="contained"
                className="black-btn full-width"
                onClick={(e) => { this.props.navigation.navigate("VendorAddressBook") }}
              >
                Add New Address
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        {/* Choose Package Dimensions */}
        <Dialog
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.packageDimensions}
          className="select-dialog package-dimensions order-dialog"
        >
          <DialogTitle id="customized-dialog-title">
            <span>Choose Package Dimensions</span>
            <CloseIcon
              className="close-dialog"
              onClick={(e) => {
                this.setState({
                  packageDimensions: false,
                });
              }}
            />
          </DialogTitle>
          <DialogContent style={{ width: "370px" }}>
            {this.renderPackageList()}
          </DialogContent>
        </Dialog>

      </>
    );
  }
}
export default withStyles(styles)(SchedulePickup);
