export const USER_TYPES = {
    CUSTOMER: "customer",
    VENDOR: "vendor",
    AFFILIATE: "affiliate"
}

export const ROLE = {
    CUSTOMER: "customer",
    VENDOR: "vendor",
    AFFILIATE: "affiliate",
    ALL: "all"
}

export const ORDER_STATUS_TYPES = {
    PLACED: "placed",
    CONFIRMED: "confirmed",
    READY_TO_SHIP: "ready_to_ship",
    SHIPPED: "shipped",
    DELIVERED: "delivered",
    CANCELLED: "cancelled",
    RETURNED: "returned",
    REFUND_PROCESSED: "refund_processed",
    EXCHANGE_DELIVERED: "exchange_delivered",
    RETURN_PENDING: "return_pending",
    RTO: "rto",
    REQUEST_FOR_RETURN: "request_for_return",
    REQUEST_FOR_EXCHANGE: "request_for_exchange",
    EXCHANGE: "exchange",

    // status from shiprocket
    PROCESSING: "Processing",
    ON_HOLD: "On-Hold",
    // CANCELLED: "Cancelled",
    UNSHIPPED: "Unshipped",
    // SHIPPED: "Shipped",
    // DELIVERED: "Delivered",
    REFUNDED: "Refunded",
    // RTO: "RTO"
}

export const ORDER_STATUS_COLOR = {
    PLACED: "#5A7994",
    CONFIRMED: "#5A7994",
    READY_TO_SHIP: "#5A7994",
    SHIPPED: "#5A7994",
    DELIVERED: "#4CC3A0",
    CANCELLED: "#f94a59",
    RETURNED: "returned",
    REFUND_PROCESSED: "refund_processed",
    EXCHANGE_DELIVERED: "exchange_delivered",
    RETURN_PENDING: "return_pending",
    RTO: "rto",
    REQUEST_FOR_RETURN: "#4CC3A0",
    REQUEST_FOR_EXCHANGE: "#4CC3A0",
    EXCHANGE: "#4CC3A0"   
}

export const PRODUCT_STATUS = {
    DRAFT : "draft",
    APPROVED : "approved",
    DIS_APPROVED : "dis_approved"
}