import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Select,
  Link,
  Grid,
  Breadcrumbs,
  Container,
  Button,
  Typography,
  Slider,
} from "@material-ui/core";
import MenuItems from "@material-ui/core/MenuItem";
import Pagination from '@material-ui/lab/Pagination';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { toast } from "react-toastify";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "../../../web/public/wishlist.css";
import "react-toastify/dist/ReactToastify.css";
const categoryBanner = require("../assets/category-banner.jpg");
const wishlistHeart = require("../assets/wishlist-heart.png");
const wishlistTrue = require("../assets/wishlistTrue.png");
const cross = require("../assets/cross.png");
const arrowLeft = require("../assets/arrow-left-black.svg");
const arrowRight = require("../assets/arrow-right-black.svg");
const noProductImage = require("../assets/product.png");

import ItemListController, {
  Props,
  configJSON,
} from "./ItemListController.web";
import Loader from "../../../components/src/Loader.web";

const IOSSlider = withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: '#121212',
    boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
    marginTop: -9,
    marginLeft: -9,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      '@media (hover: none)': {
        boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
      },
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#121212',
      // fontWeight: 'bold',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#121212',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: '#121212',
  },
})(Slider);

const styles = {
  pageOuter: {
    "& .MuiAccordion-root": {
      boxShadow: "none",
    },
    "& .MuiAccordion-root .MuiFormControlLabel-label": {
      textTransform: "capitalize",
    },
    "& .MuiAccordionDetails-root .content": {
      margin: "0",
    },
    "& .thumb-slider .carousel .control-prev.control-arrow:before": {
      backgroundImage: `url(${arrowLeft})`,
    },
    "& .thumb-slider .carousel .control-next.control-arrow:before": {
      backgroundImage: `url(${arrowRight})`,
    },
    "& .pagination ul li:first-child": {
      display:"none",
    },
    "& .pagination ul li:last-child": {
      display:"none",
    },
    "& .pagination ul li button": {
      fontSize: "14px",
      lineHeight: "18px",
      color: "#121212",
      fontWeight: "600",
      fontFamily: "Montserrat, sans-serif",
    },
    "& .filter-list": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textTransform: "capitalize",
    },
    "& .filter-list .cross-icon": {
      height: "16px",
      width: "10px",
    },
  },
};

export class ItemList extends ItemListController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {/* category page */}
        {this.state.loading && <Loader loading={this.state.loading} />}
        <div className={classes.pageOuter}>
          <div className="category-wrapper">
            {/* <div
              className="category-banner"
              style={{ backgroundImage: `url(${categoryBanner})` }}
            >
              <Container maxWidth="lg" className="product-container">
                <div className="banner-inner">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link href="/Home">Home</Link>
                    <Link href="/">Men</Link>
                    <Typography>T-shirts</Typography>
                  </Breadcrumbs>
                  <h2 className="product-name">Men T-Shirts</h2>
                </div>
              </Container>
            </div> */}
            <div className="category-columns mt-3">
              <Container maxWidth="lg" className="product-container">
                <div className="page-heading">
                  <div className="page-title">Filters</div>
                  <div className="page-right">
                    <div className="select-border">
                      <span className="select-label">Sort By:</span>
                      <Select
                        labelId="product-category"
                        id="demo-simple-select"
                        displayEmpty
                        value={this.state.sortBy}
                        onChange={(e:any)=>{
                          this.setState({
                            ...this.state,
                            sortBy: e.target.value,
                          });
                        }}
                      >
                        <MenuItems value="relevance">Relevance</MenuItems>
                        <MenuItems value="price_low_to_high">Price (Low to High)</MenuItems>
                        <MenuItems value="price_high_to_low">Price (High to Low)</MenuItems>
                        <MenuItems value="whats_new">What's New</MenuItems>
                        <MenuItems value="discount">Discount</MenuItems>
                      </Select>
                    </div>
                  </div>
                </div>
                <Grid container className="category-rows">
                  <Grid item className="sidebar">
                    <div className="block-filter">
                      {
                        this.state.filter?.category?.length !== 0 && 
                        (
                          <Accordion>
                            <AccordionSummary
                              classes={{
                                expandIcon: classes.expandIcon,
                                expanded: classes.expanded,
                              }}
                              aria-controls="panel1a-content"
                              className="accordion-heading"
                            >
                              <div className="filter-heading">Category</div>
                              <div className="filter-icon">
                                <span className="add-icon">
                                  <AddIcon />
                                </span>
                                <span className="remove-icon">
                                  <RemoveIcon />
                                </span>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul className="sidebar-filter">
                                {
                                  this.state.filter?.category && 
                                  this.state.filter?.category.map((catgry:any)=>{
                                    return (
                                      <li key={catgry.id}>
                                        <FormControlLabel
                                          value={catgry.unit}
                                          control={
                                            <Checkbox checked={this.state.categoryValue.includes(catgry.id)}
                                              onChange={(e:any)=>{
                                                if(e.target.checked){
                                                  this.setState({
                                                    ...this.state,
                                                    category:[...this.state.category, catgry],
                                                    categoryValue:[...this.state.categoryValue, catgry.id]
                                                  });
                                                }else{
                                                  this.removeCategory(catgry);
                                                }
                                              }}
                                            />
                                          }
                                          label={catgry.unit}
                                          labelPlacement="end"
                                        />
                                      </li>
                                    )
                                  })
                                }
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                        )
                      }
                      {
                        this.state.filter?.brand?.length !== 0 && 
                        (
                          <Accordion>
                            <AccordionSummary
                              classes={{
                                expandIcon: classes.expandIcon,
                                expanded: classes.expanded,
                              }}
                              aria-controls="panel1a-content"
                              className="accordion-heading"
                            >
                              <div className="filter-heading">Brand</div>
                              <div className="filter-icon">
                                <span className="add-icon">
                                  <AddIcon />
                                </span>
                                <span className="remove-icon">
                                  <RemoveIcon />
                                </span>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul className="sidebar-filter">
                                {
                                  this.state.filter?.brand && 
                                  this.state.filter?.brand.map((brand:any)=>{
                                    return (
                                      <li key={brand.id}>
                                        <FormControlLabel
                                          value={brand.unit}
                                          control={
                                            <Checkbox checked={this.state.brandValue.includes(brand.id)}
                                              onChange={(e:any)=>{
                                                if(e.target.checked){
                                                  this.setState({
                                                    ...this.state,
                                                    brand:[...this.state.brand, brand],
                                                    brandValue:[...this.state.brandValue, brand.id]
                                                  });
                                                }else{
                                                  this.removeBrand(brand);
                                                }
                                              }}
                                            />
                                          }
                                          label={brand.unit}
                                          labelPlacement="end"
                                          data-test-id="brand_checkbox"
                                        />
                                      </li>
                                    )
                                  })
                                }
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                        )
                      }
                      {
                        this.state.filter?.price?.length !== 0 && 
                        (
                          <Accordion>
                            <AccordionSummary
                              classes={{
                                expandIcon: classes.expandIcon,
                                expanded: classes.expanded,
                              }}
                              aria-controls="panel1a-content"
                              className="accordion-heading"
                            >
                              <div className="filter-heading">Price</div>
                              <div className="filter-icon">
                                <span className="add-icon">
                                  <AddIcon />
                                </span>
                                <span className="remove-icon">
                                  <RemoveIcon />
                                </span>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul className="price sidebar-filter">
                                <br />
                                <IOSSlider
                                  value={this.state.price}
                                  aria-label="ios slider"
                                  min={0}
                                  max={this.state.maxValue}
                                  onChange={(e: any, newValue: any)=>{
                                    this.setState({
                                      ...this.state,
                                      price: newValue,
                                    });
                                  }}
                                  onChangeCommitted={(e: any, newValue: any)=>{
                                    this.setState({
                                      ...this.state,
                                      priceValue: newValue,
                                    });
                                  }}
                                  aria-labelledby="range-slider"
                                  valueLabelDisplay="on"
                                />
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                        )
                      }
                      {
                        this.state.filter?.color?.length !== 0 && 
                        (
                          <Accordion>
                            <AccordionSummary
                              classes={{
                                expandIcon: classes.expandIcon,
                                expanded: classes.expanded,
                              }}
                              aria-controls="panel1a-content"
                              className="accordion-heading"
                            >
                              <div className="filter-heading">Colour</div>
                              <div className="filter-icon">
                                <span className="add-icon">
                                  <AddIcon />
                                </span>
                                <span className="remove-icon">
                                  <RemoveIcon />
                                </span>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul className="sidebar-filter">
                                {
                                  this.state.filter?.color &&
                                  this.state.filter?.color.map((clr:any)=>{
                                    return (
                                      <li key={clr.color}>
                                        <FormControlLabel
                                          value={clr.color}
                                          control={
                                            <Checkbox checked={this.state.colorValue.includes(clr.color)}
                                              onChange={(e:any)=>{
                                                if(e.target.checked){
                                                  this.setState({
                                                    ...this.state,
                                                    color:[...this.state.color, clr],
                                                    colorValue:[...this.state.colorValue, clr.color]
                                                  });
                                                }else{
                                                  this.removeColor(clr);
                                                }
                                              }} 
                                            />
                                          }
                                          label={clr.color}
                                          labelPlacement="end"
                                        />
                                      </li>
                                    )
                                  })
                                }
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                        )
                      }
                      {
                        this.state.filter?.occasion?.length !== 0 && 
                        (
                          <Accordion>
                            <AccordionSummary
                              classes={{
                                expandIcon: classes.expandIcon,
                                expanded: classes.expanded,
                              }}
                              aria-controls="panel1a-content"
                              className="accordion-heading"
                            >
                              <div className="filter-heading">Occasion</div>
                              <div className="filter-icon">
                                <span className="add-icon">
                                  <AddIcon />
                                </span>
                                <span className="remove-icon">
                                  <RemoveIcon />
                                </span>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul className="sidebar-filter">
                                {
                                  this.state.filter?.occasion &&
                                  this.state.filter?.occasion.map((ocason:any)=>{
                                    return (
                                      <li key={ocason.id}>
                                        <FormControlLabel
                                          value={ocason.unit}
                                          control={
                                            <Checkbox checked={this.state.occasionValue.includes(ocason.id)}
                                              onChange={(e:any)=>{
                                                if(e.target.checked){
                                                  this.setState({
                                                    ...this.state,
                                                    occasion:[...this.state.occasion, ocason],
                                                    occasionValue:[...this.state.occasionValue, ocason.id]
                                                  });
                                                }else{
                                                  this.removeOccasion(ocason);
                                                }
                                              }}
                                            />
                                          }
                                          label={ocason.unit}
                                          labelPlacement="end"
                                        />
                                      </li>
                                    )
                                  })
                                }
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                        )
                      }
                    </div>
                  </Grid>
                  <Grid item className="category-right">
                    {
                      this.state.products.length === 0 && <div>No Product Available.</div>
                    }
                    <ul className="filter-summary">
                      {
                        this.state.category.map((catgory:any)=>{
                          return (
                            <li className="filter-list">
                              <span>{catgory.unit}</span>
                              <span className="cross-icon" onClick={()=>{
                                this.removeCategory(catgory);
                              }}>
                                <img src={cross} />
                              </span>
                            </li>
                          )
                        })
                      }
                      {
                        this.state.brand.map((brand:any)=>{
                          return (
                            <li className="filter-list">
                              <span>{brand.unit}</span>
                              <span className="cross-icon" onClick={()=>{
                                this.removeBrand(brand);
                              }}>
                                <img src={cross} />
                              </span>
                            </li>
                          )
                        })
                      }
                      {
                        this.state.color.map((color:any)=>{
                          return (
                            <li className="filter-list">
                              <span>{color.color}</span>
                              <span className="cross-icon" onClick={()=>{
                                this.removeColor(color);
                              }}>
                                <img src={cross} />
                              </span>
                            </li>
                          )
                        })
                      }
                      {
                        this.state.occasion.map((ocason:any)=>{
                          return (
                            <li className="filter-list">
                              <span>{ocason.unit}</span>
                              <span className="cross-icon" onClick={()=>{
                                this.removeOccasion(ocason);
                              }}>
                                <img src={cross} />
                              </span>
                            </li>
                          )
                        })
                      }
                    </ul>
                    <Grid container className="product-items">
                      {this.state.products.map((item: any) => {
                        const product = item.attributes;
                        const sale_price = product && (product.sale_price ? product.sale_price : product.catalogue_variants_attributes && (product.catalogue_variants_attributes as any[]).length ? product.catalogue_variants_attributes[0].attributes.sale_price : "");
                        const maximum_retail_price = product && (product.maximum_retail_price ? product.maximum_retail_price : product.catalogue_variants_attributes && (product.catalogue_variants_attributes as any[]).length ? product.catalogue_variants_attributes[0].attributes.maximum_retail_price : "");
                        const discount = parseFloat(((((maximum_retail_price - sale_price) * 100) / maximum_retail_price) as unknown) as string).toFixed(2);

                        const imageUrl = item && item.attributes && item.attributes.galleries && (item.attributes.galleries as any[]).length && item.attributes.galleries[0].url || null
                        return (
                          <>
                            <Grid
                              key={item.id}
                              className="product-item"
                              item
                              xs={6}
                              sm={4}
                              lg={3}
                            >
                              <div className="product-item-info">
                                <div className="product-item-photo">
                                  <span className="product-image-wrapper">
                                    <Link href={`/ProductDetail/${item.id}`}>
                                      {
                                        (imageUrl) ?
                                        <img
                                          id="viewAllImg"
                                          src={imageUrl}
                                        /> :
                                        <img id="viewAllImg" src={noProductImage}/>
                                      }
                                    </Link>
                                  </span>
                                  <a
                                    onClick={() => {
                                      if (!localStorage.getItem("authToken")) {
                                        toast.warn("You need to login first", {
                                          position: "top-right",
                                        });
                                      } else {
                                        if (item.attributes.is_wishlist) {
                                          this.RemoveWishList(item.id);
                                        } else {
                                          this.AddWishList(item.id);
                                        }
                                      }
                                    }}
                                    className="product-wishlist action-link"
                                  >
                                    {item.attributes.is_wishlist ? (
                                      <img src={wishlistTrue} />
                                    ) : (
                                      <img src={wishlistHeart} />
                                    )}
                                  </a>
                                </div>
                                <div className="product-item-details">
                                  <div className="product-brand">
                                    {product.brand_name}
                                  </div>
                                  <div className="product-name">
                                    <a href="#" className="product-link">
                                      {product.name}
                                    </a>
                                  </div>
                                  <div className="pricing-wrapper">
                                    <span className="price">₹{sale_price}</span>
                                    <span className="price old-price">
                                      {maximum_retail_price && `₹${maximum_retail_price}`}
                                    </span>
                                    {isFinite(Number(discount)) && <span className="offer">({discount}% off)</span>}
                                    {/* <span className="offer">({discount}% off)</span> */}
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </>
                        );
                      })}
                    </Grid>
                    {
                      this.state.pagination &&
                        <div className="pagination-wrapper pagination">
                          <div className="pages">
                            Pages {this.state.pagination.current_page} of {this.state.pagination.total_pages}
                          </div>
                          
                          <Pagination onChange={(event:any, value:any)=>{
                              this.handlePagination(value);
                            }}
                            id="pagination_component"
                            count={this.state.pagination.total_pages} 
                            defaultValue={this.state.pagination.current_page}
                            defaultPage={this.state.pagination.current_page} 
                            siblingCount={2} 
                            page={this.state.pagination.current_page}
                          />

                          <div className="pagination-action">
                            <Button
                              className="outline-btn prev-btn"
                              disabled={this.state.pagination.prev_page === null}
                              onClick={() => {
                                this.handlePaginationPrev();
                              }}
                            >
                              Previous
                            </Button>
                            <Button
                              className="outline-btn next-btn"
                              disabled={this.state.pagination.next_page === null}
                              onClick={() => {
                                this.handlePaginationNext();
                              }}
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                    }
                  </Grid>
                </Grid>
              </Container>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(ItemList);
