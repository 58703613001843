import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Container, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Paper, TableContainer, Table, TableBody, TableHead, TableRow, createStyles, Theme, TableCell } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import CsvUploadController, { CsvUploadError, Props } from "./CsvUploadController.web";
import { CsvUploadStyleWeb } from "./CsvUploadStyle.web";
import Loader from "../../../components/src/Loader.web";
var DocIc = require("../assets/doc-icon.svg");

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
      minWidth: 250
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

export class CsvUpload extends CsvUploadController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  openFileUploadUI = () => {
    const fileInput = document.getElementById('file-button-input');
    fileInput?.click();
  }
  // Customizable Area End

  render() {
    console.log(this.state.file);

    const { classes } = this.props;
    return (
      <>
        <Loader loading={this.state.loading} />

        <div className={classes.pageOuter}>
          <div>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12}>
                  <h2>Add Multiple Products</h2>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div className="block-wrapper">
                  <div className="block-wrapper-inner">
                    <p>Download CSV files for edit inventory</p>
                    <div className="download-doc">
                      <img src={DocIc} alt="" />
                      <span className="file-name">Sample CSV File</span>
                      <Button
                        type="button"
                        variant="contained"
                        className="black-btn"
                        href={require("../assets/sample_catalogue.csv")}
                        download="sample-bulk-upload.csv"
                      >
                        Download
                      </Button>
                    </div>
                    <span className="divide-block" />
                    <p>
                      Attach CSV file to add multiple products in your product
                      lists
                    </p>
                    {this.state.file ? (
                      <div className="file-upload">
                        <span className="file-name">
                          {this.state.file.name}
                        </span>
                        <IconButton
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              file: null,
                            });
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    ) : (
                      <div className="file-upload input">
                        <input
                          type="file"
                          accept=".csv"
                          id="file-button-input"
                          onChange={(e: any) => {
                            console.log(e.target.files[0]);

                            this.setState({
                              ...this.state,
                              file: e.target.files[0],
                            });
                          }}
                        />
                        <span
                          onClick={this.openFileUploadUI}
                        >
                          Attach CSV File
                        </span>
                      </div>
                    )}
                    <div className="btn-wrapper">
                      <Button
                        disabled={!this.state.file}
                        variant="contained"
                        className="black-btn upload-btn"
                        onClick={() => {
                          this.uploadCsvFile();
                        }}
                      >
                        Upload
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Container>
          </div>
        </div>

        {/* render Errors */}
        {
          this.state.hasError &&
          (
            <Dialog
              open={this.state.hasError}
              // onClose={() => this.setState({ hasError: false })}
              scroll={"paper"}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              maxWidth="md"
              fullWidth={true}
            >
              <DialogTitle id="scroll-dialog-title">
                Csv File Errors
                <IconButton
                  aria-label="close"
                  onClick={() => this.setState({ hasError: false })}
                  style={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="scroll-dialog-description"
                  // ref={descriptionElementRef}
                  tabIndex={-1}
                >

                  <TableContainer component={Paper}>
                    <Table style={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell >Row Number</StyledTableCell>
                          <StyledTableCell >Error</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.errors &&
                          this.state.errors.length ?
                          this.state.errors.map((error: CsvUploadError) => {
                            let errorString = [...error.catalogue_row, ...error.variant_row];
                            return (
                              <>
                                <StyledTableRow key={error.row_number}>
                                  <StyledTableCell >{Number(error.row_number) + 2}</StyledTableCell>
                                  <StyledTableCell >
                                    {
                                      errorString &&
                                        errorString.length ?
                                        errorString.map((e) => {
                                          return (
                                            <>{e}<br></br></>
                                          )
                                        })
                                        : null
                                    }
                                  </StyledTableCell>
                                </StyledTableRow>
                              </>
                            )
                          })
                          :
                          null
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DialogContentText>
              </DialogContent>
            </Dialog >
          )
        }

      </>
    );
  }
}
export default withStyles(CsvUploadStyleWeb)(CsvUpload);
