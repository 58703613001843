
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "https://caelumgaurav-65468-ruby.b65468.dev.us-east-1.aws.svc.builder.cafe/address/addresses";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CatalogueCreationForm";
exports.labelBodyText = "CatalogueCreationForm Body";
exports.btnExampleTitle = "CLICK ME";
// SHOW ADDRESS
exports.addressApiMethodType = "GET";
// exports.addressAPiEndPoint = "bx_block_order_management/addresses";
exports.addressApiContentType = "application/json";
// ADD NEW ADDRESS 
exports.addAddressAPiContentType = "application/json";
exports.addAddressAPiMethodType = "POST";
exports.addAddressAPiEndPoint = "bx_block_order_management/addresses";
// DELETE ADDRESS 
exports.DelAddressAPiContentType = "application/json";
exports.DelAddressAPiMethodType = "DELETE";
exports.DelAddressAPiEndPoint = "bx_block_order_management/addresses";
// EDITH ADDRESS
exports.EdithAddressAPiContentType = "application/json";
exports.EdithAddressAPiMethodType = "PATCH";
exports.EdithAddressAPiEndPoint = "bx_block_order_management/addresses";
// MAKE DEFUALT
exports.DefualtAddressAPiContentType = "application/json";
exports.DefualtAddressAPiMethodType = "PATCH";
exports.DefualtAddressAPiEndPoint = "bx_block_order_management/addresses";

// ON EDIT 
exports.EditaddressApiMethodType = "PATCH";
exports.EditaddressAPiEndPoint = "bx_block_order_management/addresses";
exports.EditaddressApiContentType = "application/json";

// PACKAGE API

// SHOW PACKAGE
exports.packageApiMethodType = "GET";
exports.packageAPiEndPoint = "bx_block_packaging/packages";
exports.packageApiContentType = "application/json";
// ADD NEW PACKAGE 
exports.addpackageAPiContentType = "application/json";
exports.addpackageAPiMethodType = "POST";
exports.addpackageAPiEndPoint = "bx_block_packaging/packages";
// DELETE PACKAGE 
exports.DelpackageAPiContentType = "application/json";
exports.DelpackageAPiMethodType = "DELETE";
exports.DelpackageAPiEndPoint = "bx_block_packaging/packages";
// MAKE DEFUALT PACKAGE
exports.DefaultpackageAPiContentType = "application/json";
exports.DefaultpackageAPiMethodType = "PATCH";
exports.DefaultpackageAPiEndPoint = "bx_block_packaging/packages";
// ON EDIT PACKAGE
exports.EditpackageApiMethodType = "PATCH";
exports.EditpackageAPiEndPoint = "bx_block_packaging/packages";
exports.EditpackageApiContentType = "application/json";


// config data of customer profile book 
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "OrderDetailView";
exports.labelBodyText = "OrderDetailView Body";
exports.btnExampleTitle = "CLICK ME";
// myorderdetails alfred 
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST"
exports.shoppingPriceAPIEndPoint = "bx_block_shopping_cart/price_detail_of_cart"
exports.ReturnOrderAPiEndPoint = "bx_block_order_management/return_or_exchange"
exports.deliveryAddressSelectedForOrderAPiEndPoint = "order_management/orders/174/add_address_to_order"
// exports.addressAPiEndPoint = "bx_block_order_management/addresses";
exports.specificOrderDataAPiEndPoint = "bx_block_order_management/orders/"
exports.pickupAddressAPiEndPoint = "bx_block_order_management/addresses/"
exports.GetMethodType = "GET";
exports.PostMethodType = "POST";
exports.PutMethodType = "PUT";
exports.PatchMethodType = "PATCH";
exports.DeleteMethodType = "DELETE";
// vendor profile apis 
exports.addressAPiEndPoint = "bx_block_order_management/addresses";
exports.addNewAddressAPiEndPoint = "bx_block_order_management/addresses";
exports.deleteAddressAPiEndPoint = "bx_block_order_management/addresses/";
exports.editAddressAPiEndPoint = "bx_block_order_management/addresses/";
exports.getProfileAPiEndPoint = "account_block/account"
exports.editProfileAPiEndPoint = "account_block/account"
exports.emailOtpAPiEndPoint = "account_block/account/send_otp?email="
exports.phoneOtpAPiEndPoint = "account_block/account/send_otp?phone_number="
exports.emailVerifyAPiEndPoint = "account_block/account/email_confirm"
exports.PhoneVerifyAPiEndPoint = "account/accounts/sms_confirmation"
exports.brandDataAPiEndPoint = "bx_block_catalogue/vendor_brands"
exports.deleteBrandAPiEndPoint = "bx_block_catalogue/brands/"
exports.addNewBrandAPiEndPoint = "catalogue/brands";
exports.updateBrandAPiEndPoint = "bx_block_catalogue/brands/";
// Customizable Area End
