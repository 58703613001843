import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
const DocIc = require("../assets/doc-icon.svg");
import MyOrderDetailController, {
  Props,
  configJSON
} from "./MyOrderDetailController.web";


import {
  InputLabel,
  Input,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import CustomerAccountSideBarWeb from "../../../components/src/CustomerAccountSideBar.web";


const styles = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: '50px 0',
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      textTransform: "uppercase",
      color: "#121212",
      fontSize: "20px",
      // margin: "0 0 20px",
    },
    "& h4": {
      margin: "0 0 20px 0",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "30px 15px 40px",
    },
    "& .block-wrapper-inner": {
      maxWidth: "515px",
      margin: "0 auto",
    },
    "& .error-msg": {
      fontSize: "13px",
      color: "red",
    },
    "& label": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#121212",
    },
    "& .MuiFormControl-root": {
      display: "block",
    },
    "& .MuiInputBase-root": {
      display: "block",
    },
    "& .btn-wrapper": {
      textAlign: "center",
      paddingTop: "20px",
    },
    "& .custom-radio": {
      display: "flex",
      flexDirection: "row",
    },
    "& .custom-radio label": {
      color: "#000",
    },
    "& .custom-radio label .MuiIconButton-label": {
      color: "#000",
    },
    "& .productlist-wrapper": {
      padding: "0",
    },
    "& .download-doc": {
      border: "1px solid #d3d3d3",
      padding: "20px",
      minHeight: "88px",
      display: "flex",
      alignItems: "center",
      margin: "30px 0",
      '@media (max-width:767px)': {
        margin: "20px 0 0",
      }
    },
    "& .download-doc .outline-btn": {
      minWidth: "120px",
      padding: "5px",
      marginLeft: "auto",
    },
    "& .download-doc .file-name": {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "600",
      color: "#121212",
      fontFamily: "'Montserrat', sans-serif",
      marginLeft: "10px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: "calc(100% - 180px)",
      textOverflow: "ellipsis",
    },
    "& .product-price span": {
      fontSize: "14px",
      lineHeight: "18px",
      display: "inline-block",
      color: "#a5a5a5",
    },
    "& .product-price span.current-price": {
      fontWeight: "700",
      fontSize: "16px",
      color: "#121212",
      margin: "0 10px 0 0",
    },
    "& .product-price span.off-price": {
      textDecoration: "line-through",
    },
    "& .order-info-blog": {
      borderTop: "1px solid #e3e3e3",
      padding: "25px 0 0 ",
    },
    "& .order-info-blog h4": {
      fontWeight: "600",
      color: "#212121",
      margin: "0 0 15px 0",
    },
    "& .order-info-blog p": {
      fontSize: "12px",
      lineHeight: "16px",
      color: "#121212",
      margin: "0 0 15px 0",
    },
    "& .btn-wrapper button": {
      height: "50px",
      minWidth: "150px",
      '@media (max-width:575px)': {
        width: "100%",
      },
    },
    "& .btn-wrapper button.outline-btn": {
      margin: "0 15px 0 0",
      '@media (max-width:575px)': {
        margin: "0px 0 10px",
      }
    },
  },
};


export class ReturnOrder extends MyOrderDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }


  renderReturnOrder = () => {
    const { classes } = this.props;
    const productdata: any = JSON.parse(localStorage.getItem("productdata") || "{}")
    const productdetails: any = JSON.parse(localStorage.getItem("productdetails") || "{}")
    const productPolicyDays = productdata.attributes?.return_policy?.days
    const productSalePrice: any = Number(productdetails.sale_price)
    const total_price: any = Number(productdetails.total_price)
    const discount_on_mrp = Number(productdetails.discount_on_mrp)
    // const { returnReasonList, cancelReasonId, otherCancelreason, order } = this.state;
    const { returnReasonList } = this.state;


    return (
      <>
        {

          !localStorage.getItem("productdetails") ?
            this.props.navigation.navigate("MyOrderDetail") :
            <div className={classes.pageOuter}>
              <div>
                <Container maxWidth="lg">
                  <Grid container>
                    <Grid item xs={12}>
                      <h2>Item Details</h2>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="block-wrapper order-block">
                        <div className="block-wrapper-inner">
                          <div className="order-details-wrapper">
                            <Grid item xs={12} className="productlist-wrapper">
                              <Grid className="product-list">
                                <div className="product-list-inner">
                                  <div className="product-img">
                                    <img className="img-block" src={`${productdetails.galleries?.[0].url}`} />
                                  </div>
                                  <div className="product-detail">
                                    <div className="product-heading">
                                      <h4 className="product-name">{productdetails.product_name}</h4>
                                    </div>
                                    <div className="product-desc">
                                      {productdetails.description}
                                    </div>
                                    <div className="product-desc size-qty">
                                      <span>Size: {productdetails.measurement_size_value} </span>
                                    </div>
                                    <div className="product-price">

                                      <span className="current-price">₹{productSalePrice}</span>

                                      <span className="off-price">₹{total_price}</span>
                                    </div>
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                            <div className="download-doc">
                              <img src={DocIc} alt="" />
                              <span className="file-name">
                                Return & Exchange Policy
                              </span>
                              <Button
                                onClick={() => this.setState({ ReturnAndExchangePolicy: true })}
                                type="button"
                                variant="contained"
                                className="outline-btn"
                              >
                                View Policy
                              </Button>
                            </div>
                            <div className="order-info-blog">
                              <h4>Please tell the reason for return</h4>


                              {/* api reasons  */}
                              <div className="form-group">
                                <RadioGroup aria-label="return" name="return">
                                  {
                                    returnReasonList &&
                                    returnReasonList.length &&
                                    returnReasonList.map((reason: any) => {
                                      return (
                                        <>
                                          <FormControlLabel

                                            value={reason.id}
                                            control={<Radio value={reason.id} checked={Number(this.state.reasonOfReturnId) === Number(reason.id)} onChange={(e: any) => this.setState({ reasonOfReturnId: e.target.value }, () => console.log("selected reason::", this.state.reasonOfReturnId))} />}
                                            label={reason.attributes.reason}
                                          /> </>
                                      )
                                    })
                                    //  || null

                                  }
                                </RadioGroup>
                              </div>
                              <div className="form-group">
                                <InputLabel htmlFor="my-input"

                                >
                                  Additional Comments
                                </InputLabel>
                                <FormControl>
                                  <Input
                                    value={this.state.additionalComment}
                                    onChange={(e) => this.setState({ additionalComment: e.target.value })}
                                    type="text"
                                    id="my-input"
                                    aria-describedby="my-helper-text"
                                  />
                                </FormControl>
                              </div>
                            </div>
                          </div>

                          <div className="btn-wrapper">
                            <Button
                              variant="contained"
                              className="outline-btn"
                              type="button"
                            >
                              Cancel
                            </Button>


                            <Button
                              variant="contained"
                              className="black-btn"
                              type="button"
                              onClick={() => this.proceedReturn()}
                            >
                              Proceed
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
        }
        {/* return And Exchange Policy */}
        <Dialog
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.ReturnAndExchangePolicy}
          className="select-dialog"
        >
          <DialogTitle id="customized-dialog-title">
            <span>Return And Exchange Policy</span>

            <CloseIcon
              className="close-dialog"
              onClick={(e) => {
                this.setState({
                  ReturnAndExchangePolicy: false,
                });
              }}
            />
          </DialogTitle>
          <DialogContent style={{ width: "370px" }}>
            <p className="desc-content">Valid till {productPolicyDays || 10} days after delivered your items</p>
          </DialogContent>
        </Dialog>
      </>


    )
  }




  render() {
    const { classes } = this.props;




    return (<>
      <CustomerAccountSideBarWeb id={1} navigation={this.props.navigation} callbackShopWithFriend={this.props.callbackShopWithFriend}>
        {this.renderReturnOrder()}
      </CustomerAccountSideBarWeb>
    </>);
  }
}
export default withStyles(styles)(ReturnOrder);
