import React from "react";
import {
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Link,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "react-bootstrap";
import { ArrowBackIos, VisibilityOff, Visibility } from "@material-ui/icons";
import MobileAccountLoginWebController from "./MobileAccountLoginController.web";
import { Formik } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { withStyles } from "@material-ui/core/styles";
import validator from "validator";
const configJSON = require("./config");

interface ID {
  loginID: string;
}
interface Props { }
interface State { }

const styles: any = {
  modalwrapper: {
    position: "relative",
    padding: "25px 20px",
    "& .close-btn": {
      cursor: "pointer",
      position: "absolute",
      top: "20px",
      right: "20px",
    },
    "& .back": {
      cursor: "pointer",
      position: "absolute",
      top: "20px",
      left: "20px",
    },
    "& .back svg": {
      width: "14px",
      height: "14px",
    },
    "& .title": {
      padding: "45px 0 25px",
    },
    "& label": {
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.54)",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .body-content": {
      minHeight: "350px",
    },
    "& .body-content .error": {
      color: "red",
    },
    "& .divider-blog": {
      width: "50%",
      margin: "35px auto",
      position: "relative",
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      textAlign: "center",
    },
    "& .divider-blog span": {
      fontSize: "16px",
      backgroundColor: "white",
      padding: "0 5px",
      overflow: "hidden",
      color: "rgba(0, 0, 0, 0.42)",
      position: "absolute",
      top: "-13px",
      transform: "translateX(-50%)",
    },
    "& .google-button": {
      boxShadow: "none !important",
      borderRadius: "0 !important",
    },
    "& .google-button > div": {
      backgroundColor: "transparent !important",
    },
    "& .black-btn": {
      fontSize: "16px",
      textTransform: "capitalize",
    },
    "& .forgot-link": {
      margin: "25px 0",
    },
    "& .forgot-btn-wrapper": {
      margin: "0 0 25px",
    },
    "& .msg-text": {
      fontSize: "13px",
      color: "rgba(0,0,0,0.75)",
    },
    "& .wrapper-msg": {
      margin: "0 0 25px",
    },
    "& .wrapper-msg p": {
      fontSize: "14px",
      margin: "0 0 0",
    },
    "& .resend-link": {
      textAlign: "right",
      margin: "0 0 25px",
    },
    "& .new-password": {
      fontSize: "14px",
      margin: "25px 0 0",
    },
    "& .list-blog": {
      margin: "0 0 30px",
    },
    "& .list-blog div span": {
      float: "right",
      color: "green",
    },
    "& .list-blog p": {
      margin: "5px 0 5px",
      fontSize: "14px",
    },
    "& .list-blog ul": {
      paddingLeft: "25px",
      listStyleType: "disc",
    },
    "& .list-blog ul li": {
      fontSize: "14px",
      color: "rgba(0, 0, 0, 0.54)",
    },
    "& .list-blog .weak": {
      color: "red",
    },
    "& .MuiFormControl-root .weak-pass": {
      color: "red",
      fontSize: "13px",
    },
    "& .list-blog .strong": {
      color: "green",
    },
    "& .back-home": {
      fontSize: "16px",
      fontWeight: "500",
      margin: "25px 0",
      display: "block",
      cursor: "pointer",
    },
    "& .radio label": {
      color: "#000 !important",
    },
    "& .edit-wrapper span": {
      fontWeight: "500",
    },
    "& .edit-wrapper a": {
      float: "right",
    },
    "& .verify-text": {
      fontSize: "14px",
      margin: "5px 0 25px",
    },
    "& .form-group .MuiInputBase-root ": {
      display: "inline-flex",
    },
  },
};

const SignInSchema = Yup.object().shape({
  // email_mobileNo: Yup.string().required("Please enter valid mobile number/email").matches(/^(?:\d{10}|\d{12}|\w+@\w+\.\w{2,3})$/, 'Please enter valid mobile number/email'),
  email_mobileNo: Yup.string()
    .required("Please enter valid mobile number/email")
    .matches(
      /^(?:\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+))$/,
      "Please enter valid mobile number/email"
    ),
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^(\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+))$/,
      "Please enter valid mobile number/email"
    ),
  dob: Yup.string()
    .nullable()
    .required("Date of birth is required"),
  password: Yup.string().required("Password is required").min(8),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .min(8)
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export class MobileAccountLoginWebBlock extends MobileAccountLoginWebController {
  constructor(props: any) {
    super(props);
    this.doMobileLogIn = this.doMobileLogIn.bind(this);
    this.doEmailLogIn = this.doEmailLogIn.bind(this);
  }

  // 1. Enter  mobile / email screen
  renderSignInScreen = (classes: any) => {
    return (
      <Dialog
        open={this.state.showDialog}
        aria-labelledby="simple-dialog-title"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <div className={classes.modalwrapper}>
          <div className="modal-head">
            <span className="close-btn">
              <CloseIcon onClick={this.handleClose} />
            </span>
            <h4 className="title"> Welcome to Caelum </h4>
          </div>
          <div className="body-content">
            <form onSubmit={(event) => this.validateNavigation(event)}>
              <div className="form-group">
                <InputLabel htmlFor="email_mobileNo">
                  Enter Mobile Number / Email
                </InputLabel>
                <FormControl>
                  <TextField
                    autoFocus
                    margin="dense"
                    name="email_mobileNo"
                    id="email_mobileNo"
                    value={this.state.email_mobileNo}
                    onChange={this.handleChange}
                    type="text"
                    fullWidth
                    variant="standard"
                    required
                  />
                  <span
                    className="error"
                    hidden={this.state.isValidEmailMobile}
                  >
                    Please enter valid mobile number/email{" "}
                  </span>
                </FormControl>
              </div>

              <Button
                type="submit"
                className="black-btn"
                variant="contained"
                disabled={this.state.email_mobileNo.length === 0}
                fullWidth
              >
                Continue
              </Button>
            </form>
            <div className="divider-blog">
              <span>or</span>
            </div>
            <Button
              onClick={() => this.loginWithGoogle()}
              className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-fullWidth google-button"
            >
              <img
                width="20px"
                alt="Google sign-in"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
              />{" "}
              &nbsp;&nbsp; Continue With Google
            </Button>
          </div>
        </div>
      </Dialog>
    )
  }

  // 2. Enter password screen
  renderPasswordScreen = (classes: any) => {
    return (
      <Dialog
        open={this.state.showDialog}
        aria-labelledby="simple-dialog-title"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <div className={classes.modalwrapper}>
          <div className="modal-head">
            <span className="back" onClick={this.goToSignInscreen}>
              <ArrowBackIos />
              <span>Back</span>
            </span>
            <span className="close-btn">
              <CloseIcon onClick={this.handleClose} />
            </span>
            <h4 className="title"> Glad you're back! </h4>
          </div>
          <div className="body-content">
            <form onSubmit={(event) => this.login(event)}>
              <Alert variant="danger" show={this.state.isLoginFailed}>
                Invalid Password
              </Alert>
              <Alert
                variant="success"
                show={this.state.isSetNewPasswordSuccess}
              >
                Please login again with new password
              </Alert>
              <div className="form-group">
                <InputLabel htmlFor="standard-adornment-password">
                  Password
                </InputLabel>
                <FormControl variant="standard" fullWidth>
                  <Input
                    autoFocus
                    id="password"
                    type={this.state.showPassword ? "text" : "password"}
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                        >
                          {this.state.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <Button
                className="black-btn"
                type="submit"
                disabled={this.state.password.length === 0}
                variant="contained"
                fullWidth
              >
                Sign in
              </Button>
            </form>
            <div className="forgot-link">
              {/* <div
              className="back-home"
              onClick={this.ForgetPasswordSendOtp}
            >
              <span>Forgot Password?</span>
            </div> */}
              {/* <Link color="inherit" underline="none">
              Forgot Password?
            </Link> */}
            </div>
            <div className="forgot-btn-wrapper">
              <Button
                className="outline-btn"
                onClick={this.sendOTP}
                variant="contained"
                fullWidth
              >
                Sign in with OTP
              </Button>
            </div>
            <div className="msg-text">
              We will send an OTP to your registered mobile number and
              email.
            </div>
          </div>
        </div>
      </Dialog>
    )
  }

  // 3. OTP Screen
  renderOTPScreen = (classes: any) => {
    return (
      <Dialog
      open={this.state.showDialog}
      aria-labelledby="simple-dialog-title"
      fullWidth={true}
      maxWidth={"xs"}
    >
      <div className={classes.modalwrapper}>
        <div className="modal-head">
          <span className="back" onClick={this.goToPasswordScreen}>
            <ArrowBackIos />
            <span>Back</span>
          </span>
          <span className="close-btn">
            <CloseIcon onClick={this.handleClose} />
          </span>
          <h4 className="title">Sign In With OTP </h4>
        </div>
        <div className="body-content">
          <Alert variant="danger" show={this.state.isPinIncorrect}>
            {" "}
            Invalid OTP !{" "}
          </Alert>
          <div className="wrapper-msg">
            <p> Please enter OTP sent to </p>
            <p>
              <b> {this.state.email_mobileNo} </b>
            </p>
          </div>
          <form onSubmit={(event) => this.verifyOTP(event)}>
            <div className="form-group">
              <InputLabel htmlFor="otp">Enter OTP</InputLabel>
              <FormControl variant="standard" fullWidth>
                <TextField
                  value={this.state.otp}
                  onChange={this.handleChange}
                  autoFocus
                  margin="dense"
                  id="otp"
                  name="otp"
                  type="text"
                  fullWidth
                  variant="standard"
                />
                <span
                  className="error"
                  hidden={this.state.isValidEmailMobile}
                >
                  {" "}
                  Please enter OTP{" "}
                </span>
              </FormControl>
            </div>

            <div className="resend-link">
              <div hidden={this.state.counter >= 1 ? false : true}>
                Resend OTP in {this.state.counter} s
              </div>
              <Link
                onClick={this.resendOTP}
                hidden={this.state.counter <= 0 ? false : true}
                href=""
                color="inherit"
                underline="none"
              >
                Resend OTP
              </Link>
            </div>

            <Button
              type="submit"
              className="black-btn"
              variant="contained"
              disabled={this.state.otp.length === 0}
              fullWidth
            >
              Verify
            </Button>
          </form>
          <div className="new-password">
            Would you like to
            <Link
              onClick={this.goToSetPasswordScreen}
              color="inherit"
              underline="none"
            >
              <b> Set a new password? </b>
            </Link>
          </div>
        </div>
      </div>
      </Dialog>
      )
  }

  // 4. change password screen
  renderSetNewPasswordScreen = (classes: any) => {
    return (
      <Dialog
      open={this.state.showDialog}
      aria-labelledby="simple-dialog-title"
      fullWidth={true}
      maxWidth={"xs"}
    >
      <div className={classes.modalwrapper}>
        <div className="modal-head">
          <span className="back" onClick={this.goToPasswordScreen}>
            <ArrowBackIos />
            <span>Back</span>
          </span>
          <span className="close-btn">
            <CloseIcon onClick={this.handleClose} />
          </span>
          <h4 className="title">Set new password</h4>
        </div>
        <div className="body-content">
          <div className="wrapper-msg">
            <p>Please set a new password </p>
          </div>

          <form
            onSubmit={(event) => {
              event.preventDefault();
              this.setNewPasswordFunction();
            }}
          >
            <div className="form-group">
              <InputLabel htmlFor="standard-adornment-password">
                New Password*
              </InputLabel>
              <FormControl variant="standard" fullWidth>
                <Input
                  autoFocus
                  id="new-password"
                  type={this.state.showNewPassword ? "text" : "password"}
                  name="new-password"
                  value={this.state.setNewPassword}
                  onChange={this.handleSetNewPasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowNewPassword}
                      >
                        {this.state.showNewPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div className="list-blog">
              <div>
                Password must:{" "}
                {this.state.setNewPassword.length === 0 ? (
                  <span />
                ) : validator.isStrongPassword(
                  this.state.setNewPassword,
                  {
                    minLength: 8,
                    minLowercase: 1,
                    minUppercase: 1,
                    minNumbers: 1,
                    minSymbols: 1,
                  }
                ) ? (
                  <span className="strong">Strong</span>
                ) : (
                  <span className="weak">Weak</span>
                )}
              </div>
              <p>
                At least one of all enlisted below, min length of 8
                characters
              </p>
              <ul>
                <li
                  className={
                    this.state.setNewPassword.length >= 8 ? "strong" : ""
                  }
                >
                  Min length of 8 characters
                </li>
                <li
                  className={
                    validator.isUppercase(this.state.setNewPassword)
                      ? ""
                      : "strong"
                  }
                >
                  An uppercase character
                </li>
                <li
                  className={
                    validator.isLowercase(this.state.setNewPassword)
                      ? ""
                      : "strong"
                  }
                >
                  An lowercase character
                </li>
                <li
                  className={
                    /\d/.test(this.state.setNewPassword) ? "strong" : ""
                  }
                >
                  A number
                </li>
                <li
                  className={
                    this.state.setNewPassword.match(
                      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/
                    )
                      ? "strong"
                      : ""
                  }
                >
                  A special character
                </li>
              </ul>
            </div>
            <div className="form-group">
              <InputLabel htmlFor="standard-adornment-password">
                Confirm Password*
              </InputLabel>
              <FormControl variant="standard" fullWidth>
                <Input
                  id="new-confirm-password"
                  type={
                    this.state.showNewConfirmPassword
                      ? "text"
                      : "password"
                  }
                  name="new-confirm-password"
                  value={this.state.setNewConfirmPassword}
                  onChange={this.handleSetNewConfirmPasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowConfirmPassword}
                      >
                        {this.state.showNewConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {this.state.setNewConfirmPassword.length > 0 &&
                  this.state.setNewPassword !==
                  this.state.setNewConfirmPassword && (
                    <span className="weak-pass">
                      Password and Confirm Password Must be Same
                    </span>
                  )}
              </FormControl>
            </div>
            <Button
              disabled={
                this.state.setNewPassword !==
                this.state.setNewConfirmPassword ||
                this.state.setNewPassword === "" ||
                this.state.setNewConfirmPassword === "" ||
                this.state.setNewPassword.length < 8
              }
              className="black-btn"
              variant="contained"
              fullWidth
              type="submit"
            >
              <span>Sign in</span>
            </Button>
          </form>
          <div className="back-home" onClick={this.goToPasswordScreen}>
            <span>I'll do this later</span>
          </div>
        </div>
      </div>
      </Dialog>
      )
  }

  // 5. Sign up form screen
  renderSignUpScreen = (classes: any) => {
    return (
      <Dialog
      open={this.state.showDialog}
      aria-labelledby="simple-dialog-title"
      fullWidth={true}
      maxWidth={"xs"}
    >
      <div className={classes.modalwrapper} ref={this.signUpModelRef}>
        <div className="modal-head">
          <span className="back" onClick={this.goToSignInscreen}>
            <ArrowBackIos />
            <span>Back</span>
          </span>
          <span className="close-btn">
            <CloseIcon onClick={this.handleClose} />
          </span>
          <h4 className="title">Welcome to Caelum</h4>
        </div>
        <div className="body-content">
          <Alert variant="danger" show={this.state.isSignUpFailed}>
            {" "}
            {this.state.SignUpErrorMsg}!{" "}
          </Alert>
          {/* <GoogleLogin
                      className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-fullWidth google-button"
                      clientId={configJSON.clientID}
                      buttonText="Continue With Google"
                      onSuccess={this.responseGoogleSuccess}
                      onFailure={this.responseGoogleError}
                      cookiePolicy={"single_host_origin"}
                    /> */}
          <Button
            onClick={() => this.loginWithGoogle()}
            className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-fullWidth google-button"
          >
            <img
              width="20px"
              alt="Google sign-in"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
            />{" "}
            &nbsp;&nbsp; Continue With Google
          </Button>
          <div className="divider-blog">
            <span>or</span>
          </div>

          <div className="wrapper-msg">
            <p>Please create your account </p>
          </div>

          <Formik
            initialValues={this.intialValues}
            validationSchema={SignInSchema}
            onSubmit={(values) => {
              this.createAccount(values);
            }}
          >
            {(props) => {
              const { errors, touched, isValid, dirty, isSubmitting, isValidating } = props;
              props.values.password = this.state.password;
              props.values.dob = this.state.dob;
              props.values.email_mobileNo = this.state.email_mobileNo;

              if (isSubmitting) {
                if (Object.keys(errors).length) {
                  this.scrollToTopSignUpModal();
                }
              }

              return (
                <form onSubmit={props.handleSubmit}>
                  {!this.state.editEmailMobileNo && (
                    <div className="edit-wrapper">
                      {" "}
                      <span>{this.state.email_mobileNo} </span>{" "}
                      <a href="" onClick={this.editEmailMobileNo}>
                        {" "}
                        Edit{" "}
                      </a>
                    </div>
                  )}
                  {this.state.editEmailMobileNo && (
                    <div>
                      <div className="form-group">
                        <InputLabel htmlFor="email_mobileNo">
                          Enter Mobile Number / Email *
                        </InputLabel>
                        <FormControl>
                          <TextField
                            autoFocus
                            margin="dense"
                            id="email_mobileNo"
                            name="email_mobileNo"
                            value={props.values.email_mobileNo}
                            error={
                              touched.email_mobileNo &&
                                errors.email_mobileNo
                                ? true
                                : false
                            }
                            onChange={this.handleChange}
                            type="text"
                            fullWidth
                            variant="standard"
                          />
                          <span className="error">
                            {touched.email_mobileNo &&
                              errors.email_mobileNo
                              ? errors.email_mobileNo
                              : ""}
                          </span>
                        </FormControl>
                      </div>
                    </div>
                  )}
                  <p className="verify-text">
                    {" "}
                    OTP will be sent your email/number for verification
                  </p>
                  <div className="form-group">
                    <InputLabel htmlFor="name">Name*</InputLabel>
                    <FormControl>
                      <TextField
                        fullWidth
                        autoFocus
                        margin="dense"
                        id="name"
                        type="text"
                        onChange={props.handleChange}
                        value={props.values.name.trim()}
                        error={touched.name && errors.name ? true : false}
                        variant="standard"
                      />
                      <span className="error">
                        {touched.name && errors.name ? errors.name : ""}
                      </span>
                    </FormControl>
                  </div>
                  <div className="form-group">
                    <RadioGroup
                      className="radio"
                      row
                      value={props.values.gender}
                      onChange={props.handleChange}
                      aria-label="gender"
                      name="gender"
                      defaultValue="male"
                    >
                      <InputLabel
                        htmlFor="component-simple"
                        style={{ padding: "15px", paddingLeft: "0px" }}
                      >
                        Gender:{" "}
                      </InputLabel>
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Female"
                      />
                    </RadioGroup>
                  </div>
                  <div className="form-group">
                    <InputLabel htmlFor="email">Email*</InputLabel>
                    <FormControl>
                      <TextField
                        fullWidth
                        margin="dense"
                        id="email"
                        type="text"
                        onChange={props.handleChange}
                        value={props.values.email.trim()}
                        error={
                          touched.email && errors.email ? true : false
                        }
                        variant="standard"
                        autoComplete="off"
                      />
                      <span className="error">
                        {touched.email && errors.email
                          ? errors.email
                          : ""}
                      </span>
                    </FormControl>
                  </div>
                  <div className="form-group">
                    <InputLabel>Date of Birth*</InputLabel>
                    <br />
                    <DatePicker
                      placeholderText="dd-mm-yyyy"
                      selected={props.values.dob}
                      onChange={this.handleDobChange}
                      id="dob"
                      name="dob"
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                      autoComplete="off"
                      // peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    <span className="error">
                      {touched.dob && errors.dob ? errors.dob : ""}
                    </span>
                  </div>
                  <div className="form-group">
                    <InputLabel htmlFor="standard-adornment-password">
                      New Password*
                    </InputLabel>
                    <FormControl variant="standard" fullWidth>
                      <Input
                        id="password"
                        type={
                          this.state.showPassword ? "text" : "password"
                        }
                        onChange={this.handlePasswordChange}
                        value={props.values.password.trim()}
                        error={
                          touched.password && errors.password
                            ? true
                            : false
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                            >
                              {this.state.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <span className="error">
                      {touched.password && errors.password
                        ? errors.password
                        : ""}
                    </span>
                  </div>

                  <div className="list-blog">
                    <div>
                      Password must:{" "}
                      {this.state.minLength &&
                        this.state.upperCase &&
                        this.state.lowerCase &&
                        this.state.number &&
                        this.state.specialCase && <span>Strong</span>}
                    </div>
                    <p>
                      At least one of all enlisted below, min length of 8
                      characters
                    </p>
                    <ul>
                      <li
                        style={{
                          color: this.state.minLength ? "green" : "",
                        }}
                      >
                        Min length of 8 characters
                      </li>
                      <li
                        style={{
                          color: this.state.upperCase ? "green" : "",
                        }}
                      >
                        An uppercase character
                      </li>
                      <li
                        style={{
                          color: this.state.lowerCase ? "green" : "",
                        }}
                      >
                        An lowercase character
                      </li>
                      <li
                        style={{
                          color: this.state.number ? "green" : "",
                        }}
                      >
                        A number
                      </li>
                      <li
                        style={{
                          color: this.state.specialCase ? "green" : "",
                        }}
                      >
                        A special character
                      </li>
                    </ul>
                  </div>
                  <div className="form-group">
                    <InputLabel htmlFor="standard-adornment-password">
                      Confirm Password*
                    </InputLabel>
                    <FormControl variant="standard" fullWidth>
                      <Input
                        id="confirmPassword"
                        type={
                          this.state.showNewConfirmPassword
                            ? "text"
                            : "password"
                        }
                        onChange={props.handleChange}
                        value={props.values.confirmPassword.trim()}
                        error={touched.name && errors.name ? true : false}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={
                                this.handleClickShowConfirmPassword
                              }
                            // onMouseDown={handleMouseDownPassword}
                            >
                              {this.state.showNewConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <span style={{ color: "#f44336" }}>
                      {touched.confirmPassword && errors.confirmPassword
                        ? errors.confirmPassword
                        : ""}
                    </span>
                  </div>
                  <Button
                    className="black-btn"
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={this.state.isSignupApiCalled}
                  >
                    Send OTP
                  </Button>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
      </Dialog>
      )
  }

  // 6. Forget Password Screen
  renderForgetPasswordScreen = (classes: any) => {
    return (
      <Dialog
      open={this.state.showDialog}
      aria-labelledby="simple-dialog-title"
      fullWidth={true}
      maxWidth={"xs"}
    >
      <div className={classes.modalwrapper}>
        <div className="modal-head">
          <span className="back" onClick={this.goToPasswordScreen}>
            <ArrowBackIos />
            <span>Back</span>
          </span>
          <span className="close-btn">
            <CloseIcon onClick={this.handleClose} />
          </span>
          <h4 className="title">
            Hi {this.state.email_mobileNo}, Please Verify It's You
          </h4>
        </div>
        <div className="body-content">
          <Alert variant="danger" show={this.state.isPinIncorrect}>
            Invalid OTP !
          </Alert>
          <div className="wrapper-msg">
            <p> Please enter OTP sent to </p>
            <p>
              <b> {this.state.email_mobileNo} </b>
            </p>
          </div>
          <form
            onSubmit={(event) => {
              this.VerifyForgetPassOTP(event);
            }}
          >
            <div className="form-group">
              <InputLabel htmlFor="otp">Enter OTP</InputLabel>
              <FormControl variant="standard" fullWidth>
                <TextField
                  value={this.state.ForgetOtp}
                  onChange={this.handleForgetOtpChange}
                  autoFocus
                  margin="dense"
                  id="otp"
                  name="otp"
                  type="text"
                  fullWidth
                  variant="standard"
                />
                <span
                  className="error"
                  hidden={this.state.isValidEmailMobile}
                >
                  Please enter OTP
                </span>
              </FormControl>
            </div>

            <div className="resend-link">
              <div hidden={this.state.counter >= 1 ? false : true}>
                Resend OTP in {this.state.counter} s
              </div>
              <Link
                onClick={this.ResendForgetPassOTP}
                hidden={this.state.counter <= 0 ? false : true}
                href=""
                color="inherit"
                underline="none"
              >
                Resend OTP
              </Link>
            </div>

            <Button
              type="submit"
              className="black-btn"
              variant="contained"
              // onClick={this.goToSetPasswordScreen}
              disabled={this.state.ForgetOtp.length === 0}
              fullWidth
            >
              Verify
            </Button>
          </form>
        </div>
      </div>
      </Dialog>
      )
  }


  render() {
    const { classes } = this.props;

    let renderDialog = null

    if (this.state.signInScreen) {
      renderDialog = this.renderSignInScreen(classes)
    }
    else if (this.state.passwordScreen) { 
      renderDialog = this.renderPasswordScreen(classes);
    }
    else if (this.state.otpScreen) { 
      renderDialog = this.renderOTPScreen(classes);
    }
    else if (this.state.setNewPasswordScreen) {
      renderDialog = this.renderSetNewPasswordScreen(classes);
    }
    else if (this.state.signUpScreen) { 
      renderDialog = this.renderSignUpScreen(classes);
    }
    else if (this.state.forgetPasswordScreen) { 
      renderDialog = this.renderForgetPasswordScreen(classes);
    }

    return (
      <>
        {renderDialog}
      </>
    );
  }
}

export default withStyles(styles)(MobileAccountLoginWebBlock);
