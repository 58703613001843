import React, { useState, Component } from "react";
import {
    Box,
    TextField,
    InputLabel,
    Input,
    Divider,
    FormControl,
    Typography,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItems from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import {
    Menu,
    MenuItem,
    MenuButton,
    SubMenu,
} from "../../../../node_modules/@szhsin/react-menu";

import { withStyles } from "@material-ui/core/styles";
import SizeChartController, { Props } from "./SizeChartController.web";
import _ from 'lodash';
import { Redirect } from "react-router"
import clsx from "clsx";
import { Height } from "@material-ui/icons";
// import {  Modal, View } from "react-native";
import { Dialog } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import Loader from "../../../components/src/Loader.web";
import NewSizeChartController from "./NewSizeChartController.web";
import { sizeResponse } from "../../../framework/src/Interfaces/ISizeChart";

const styles = {
    pageOuter: {
        backgroundColor: "#F7F7F7",
        padding: "25px 0 70px",
        fontFamily: "'Montserrat', sans-serif",
        "& h2": {
            margin: "0 0 20px",
            textAlign: "center",
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: "700",
            fontFamily: "'Montserrat', sans-serif",
            textTransform: "uppercase",
            color: "#121212",
        },
        "& h4": {
            margin: "0 0 20px 0",
            fontSize: "16px",
            lineHeight: "20px",
            fontWeight: "600",
            color: "#121212",
            fontFamily: "'Montserrat', sans-serif",
        },
        "& .size-name": {
            margin: "0 5px 0 0",
        },
        "& .size-block": {
            padding: "15px",
            background: "#ffffff",
            marginBottom: "20px",
            "@media (max-width:767px)": {
                padding: "12px",
                marginBottom: "15px",
            },
        },
        "& .size-block:last-child": {
            marginBottom: "0",
        },
        "& .size-detail": {
            fontSize: "14px",
            lineHeight: "20px",
            color: "#666666",
            marginBottom: "6px",
            fontFamily: "'Montserrat', sans-serif",
        },
        "& .size-detail:last-child": {
            marginBottom: "0",
        },
        "& .size-detail span": {
            color: "#000000",
            fontWeight: "bold",
        },
        "& .size-desc": {
            fontSize: "14px",
            lineHeight: "20px",
            color: "#666666",
            marginBottom: "6px",
            fontFamily: "'Montserrat', sans-serif",
        },
        "& .black-btn": {
            backgroundColor: "#000",
            color: "#fff",
            borderRadius: "0",
            padding: "10px 10px",
            minWidth: "150px",
            textTransform: "capitalize",
            boxShadow: "none",
        },

        "& .myBox": {
            backgroundColor: "#000",

        },
        "& .size-heading": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "6px",
        },
        "& .top-heading": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginBottom: "20px",
        },
        "& .top-heading .heading-left": {
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            "@media (max-width:767px)": {
                width: "100%",
                marginBottom: "10px",
            },
        },
        "& .size-count": {
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: "bold",
            margin: "0 10px 0 0",
            textTransform: "uppercase",
            "@media (max-width:990px)": {
                fontSize: "17px",
                margin: "0 6px 0 0",
            },
            "@media (max-width:767px)": {
                width: "100%",
                marginBottom: "10px",
            },
        },
        "& .select-border": {
            minWidth: "250px",
            backgroundColor: "#ffffff",
            border: "2px solid #D7D7D7",
            padding: "5px 20px",
            color: "#7E7E7F",
            marginLeft: "20px",
            "@media (max-width:990px)": {
                minWidth: "200px",
            },
        },
        "& .menu-block .MuiIconButton-root": {
            padding: "0",

        },
        " & .menu-block .szh-menu": {
            right: "-20px",
            left: "auto !important",
        },
        " & .menu-item:: after": {
            border: "none"

        },
        " & .modalBox": {
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            display: "flex",
            width: "100%",
            height: "100%",
            backgroundColor: "red"
        },
        " & .modal": {
            width: "80%",
            borderRadius: 4,
            borderWidth: 1,
            borderColor: "#ccc",
            marginTop: 80,
            marginLeft: 40,
            padding: 15
        },

    },
    selection: {
        border: "none",
        width: "100%",
        backgroundColor: "white",
        paddingLeft: "10px"
    },
    measurementUnit: {
        fontWeight: 900,
        color: "#000000"
    },
};
export class NewSizeCharts extends NewSizeChartController {
    constructor(props: Props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({
            isDelete: false,
            // loading: false
        }, () => {
            this.getSizeCharts();
            this.getProductCategoies();
        });
    }

    renderSizeString(sizes: string[]) {
        var finalStr: string = '';
        sizes &&
            sizes.length &&
            sizes.forEach((size: string, index: number) => {
                finalStr += size?.trim();
                index < sizes.length - 1 ? finalStr += ', ' : finalStr;
            })
        return finalStr;
    }
    /**
     * render a list of size charts
     * @return React JSX element wrapping available size charts
     */
    renderSizeCharts() {
        const { classes } = this.props;
        let sizeCharts: any = [];
        if (this.state.sizeCharts != null) {
            this.state.sizeCharts &&
                this.state.sizeCharts.length &&
                this.state.sizeCharts.forEach((chart: any) => {
                    let sizes: string[] = [];

                    const sizeValue: sizeResponse[] = chart?.attributes?.sizes?.data;
                    sizeValue &&
                        sizeValue.length &&
                        sizeValue.forEach((size) => {
                            sizes.push(size.attributes.label_value);
                        });

                    const sizeChart = (
                        <Box className="size-block">
                            <Box className="size-heading">
                                <Typography variant="h5" className="size-name">
                                    {chart?.attributes?.name}
                                </Typography>
                                <Box className="menu-block">
                                    <Menu menuButton={
                                        <Box>
                                            <IconButton>
                                                <MoreHorizIcon />
                                            </IconButton>
                                        </Box>}>
                                        <MenuItem
                                            onClick={() => { this.props.navigation.navigate('EditSizeCharts', { id: chart?.attributes?.id }) }}>Edit</MenuItem>
                                        <MenuItem
                                            onClick={(e) => {
                                                this.setState({
                                                    isDelete: true,
                                                    sizeChartToDelete: chart
                                                });
                                                this.setState({
                                                    sizeChartId: chart?.attributes?.id
                                                });
                                            }}
                                        >Delete</MenuItem>
                                    </Menu>
                                </Box>
                            </Box>
                            <Box id={chart?.attributes?.id}>
                                <Box className="size-desc">{_.upperFirst(chart?.attributes?.categories_name)} {_.upperFirst(chart?.attributes?.sub_categories_name)}
                                </Box>
                                <Box className="size-detail" display="flex" flexDirection="row">
                                    <Typography variant="subtitle1">Size:</Typography>
                                    <Typography className={classes.measurementUnit} variant="subtitle1">
                                        &nbsp; {this.renderSizeString(sizes)}
                                    </Typography>
                                </Box>
                                <Box className="size-detail" display="flex" flexDirection="row" >
                                    <Typography variant="subtitle1">Unit:</Typography>
                                    <Typography className={classes.measurementUnit} variant="subtitle1">
                                        &nbsp; {_.upperFirst(chart?.attributes?.measurement_unit) ?? 'Inches'}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    );
                    sizeCharts.push(sizeChart);
                })
        }
        return sizeCharts;
    }

    /**
     * Function to render dropdown of product categories
     * @return React JSX element wrapping product categories
     */
    rendercategories() {
        let categories: any = [];
        _.uniqBy(this.state.categories, 'id').forEach((cat: any) => {
            const menuItem = (
                <MenuItems value={cat?.attributes?.id}>
                    <Typography className="size-name">{_.capitalize(cat?.attributes?.name)}</Typography>
                </MenuItems>
            );
            categories.push(menuItem);
        })
        return categories;
    }

    /**
     * Function to render dropdown of product sub-categories
     * empty by default, when a product category is selected it will populate this dropdown
     * @return React JSX element wrapping product sub-categories
     */
    rendersubcategories() {
        let categories: any = [];
        _.uniqBy(this.state.subCategories, 'id')?.forEach((subCat: any) => {
            const cate = (
                <MenuItems value={subCat?.attributes?.id}>
                    <Typography className="size-name">{_.capitalize(subCat?.attributes?.name)}</Typography>
                </MenuItems>
            );
            categories.push(cate);
        })
        return categories;
    }

    /**
     * Function triggered on selecting a product category
     * @param event object for selected product category
     */
    handleCategoryChange(event: any) {
        let value = event.target.value;
        this.setState({
            productCategoryId: value,
            productSubCategoryId: '',
            subCategories: []
        },
            () => {
                this.getSizeCharts();
                this.getProductSubCategoies(value);
            }
        );
    }

    // on sub category click get realted size chart
    handleSubCategoryChange(event: any) {
        let value = event.target.value;
        this.setState({
            productSubCategoryId: value
        },
            () => this.getSizeCharts()
        );
    }

    /**
     * Function to close the 'delete' modal
     */
    handleClose() {
        this.setState({
            isDelete: false
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                {this.state.loading ? <Loader loading={this.state.loading} /> : null}
                <Box className={classes.pageOuter}>
                    <Container maxWidth="md">
                        <Grid container>
                            <Grid item xs={12}>
                                <h2>Size Charts</h2>
                            </Grid>
                            <Grid className="top-heading" item xs={12}>
                                <Box width="100%" display="flex" flexWrap="nowrap" alignItems="center" justifyContent="space-between">
                                    <Typography className="size-count">{this.state.sizeCharts?.length} Size Charts</Typography>
                                    <Box className="select-border" display="flex" alignItems="center">
                                        <Typography variant="body1" className="select-label">Category:</Typography>
                                        <Select
                                            labelId="product-category"
                                            id="demo-simple-select"
                                            className="selection"
                                            IconComponent={ExpandMoreIcon}
                                            displayEmpty
                                            disableUnderline
                                            value={this.state.productCategoryId}
                                            onChange={(e) => this.handleCategoryChange(e)}
                                        >
                                            <MenuItems value="">All</MenuItems>
                                            {
                                                this.rendercategories()
                                            }
                                        </Select>
                                    </Box>
                                    <Box className="select-border" display="flex" alignItems="center">
                                        <Typography variant="body1" className="select-label">Sub Category:</Typography>
                                        <Select
                                            labelId="product-category"
                                            id="demo-simple-select"
                                            IconComponent={ExpandMoreIcon}
                                            className="selection"
                                            displayEmpty
                                            disableUnderline
                                            value={this.state.productSubCategoryId}
                                            onChange={(e) => this.handleSubCategoryChange(e)}
                                        >
                                            <MenuItems value="" className="menu-item">All</MenuItems>
                                            {
                                                this.state.subCategories &&
                                                    this.state.subCategories.length ?
                                                    this.rendersubcategories() :
                                                    null
                                            }
                                        </Select>
                                    </Box>
                                    <Box className="btn-wrapper" ml={2}>
                                        <Button
                                            href=""
                                            type="submit"
                                            variant="contained"
                                            className="black-btn order-btn"
                                            onClick={(e) => { this.props.navigation.navigate("CreateChart") }}
                                        >
                                            Create Size Chart
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    this.state.sizeCharts &&
                                        this.state.sizeCharts.length ?
                                        this.renderSizeCharts()
                                        :
                                        <Box className="size-block">
                                            <div className="not-found">No record found</div>
                                        </Box>
                                }
                            </Grid>
                        </Grid>
                        <Dialog
                            open={this.state.isDelete}
                            aria-labelledby="simple-dialog-title"
                            fullWidth={true} maxWidth={"xs"}
                        >
                            <Box className="myBox">
                                <Box textAlign="right" p={2}>
                                    <span className="close-btn">
                                        <CloseIcon id='close-btn_icon' onClick={() => this.handleClose()}></CloseIcon>
                                    </span>
                                </Box>
                                <Box className="modal-head" textAlign="center" fontWeight="600" p={1}>
                                    <Typography variant="h5"> Are you sure, you want to delete <b>{this.state.sizeChartToDelete.attributes?.product_name}</b> size chart?</Typography>
                                </Box>
                                <Box textAlign="center" p={1} display="flex" flexWrap="nowrap" justifyContent="space-evenly">
                                    <Button className="black-btn"
                                        onClick={() => this.handleClose()} size="medium" >
                                        No
                                    </Button>
                                    <Button className="black-btn"
                                        onClick={(e) => { this.deleteSizeCharts(this.state.sizeChartId) }} size="medium" >
                                        Yes
                                    </Button>
                                </Box>
                            </Box>
                        </Dialog>
                    </Container>
                </Box>
            </>
        );
    }
}

export default withStyles(styles)(NewSizeCharts);
