import React, { Component } from "react";
// import {
//     Box,
//     TextField,
//     InputLabel,
//     Input,
//     Divider,
//     FormControl,
// } from "@material-ui/core";
import Container from "@material-ui/core/Container";
// import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
// import MenuItems from "@material-ui/core/MenuItem";
// import IconButton from "@material-ui/core/IconButton";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import { Menu } from "../../../../node_modules/@szhsin/react-menu";

// import DocIc from "../assets/doc-icon.svg";

import VendorPaymentsController, {
    Props,
    configJSON
} from "./VendorPaymentsController.web";

import {
    // createStyles,
    withStyles,
    // makeStyles,
    // Theme,
} from "@material-ui/core/styles";

// Vendor Payment Start
const styles: any = {
    pageOuter: {
        backgroundColor: "#F7F7F7",
        padding: '50px 0',
        "& h2": {
            margin: "0 0 20px",
            textAlign: "center",
            textTransform: "uppercase",
            color: "#121212",
            fontSize: "20px",
        },
        "& h4": {
            margin: "0 0 20px 0",
        },
        "& .block-wrapper": {
            backgroundColor: "#fff",
            padding: "30px 15px 40px",
        },
        "& .block-wrapper-inner": {
            maxWidth: "515px",
            margin: "0 auto",
        },
        "& h5": {
            fontSize: "16px",
            lineHeight: "20px",
            color: "#121212",
            fontWeight: "500",
            margin: "0 0 10px"
        },
        "& .payment_list_outer .payment_list": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "20px 0",
            borderBottom: "1px solid #e3e3e3",
            '@media (max-width:767px)': {
                padding: "13px 0",
            }
        },
        "& .payment_list span": {
            fontSize: "14px",
            lineHeight: "18px",
            color: "#121212",
        },
        "& .payment_list .payment": {
            color: "#21c998",
        },
    },
};
// Vendor Payment End

export class VendorPayments extends VendorPaymentsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props;
        return (
            <>
                {/* Vendor Payment Start */}
                <div
                    className={classes.pageOuter}>
                    <div>
                        <Container maxWidth="lg">
                            <Grid container>
                                <Grid item xs={12}>
                                    <h2>Payments</h2>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="block-wrapper order-block">
                                        <div className="block-wrapper-inner">
                                            <h5>Payments</h5>
                                            <div className="payment_list_outer">
                                                <div className="payment_list">
                                                    <span className="date">18 Sep, 2021</span>
                                                    <span className="payment">₹1,249.50</span>
                                                </div>
                                                <div className="payment_list">
                                                    <span className="date">17 Sep, 2021</span>
                                                    <span className="payment">₹2,200</span>
                                                </div>
                                                <div className="payment_list">
                                                    <span className="date">15 Sep, 2021</span>
                                                    <span className="payment">₹1,178.50</span>
                                                </div>
                                                <div className="payment_list">
                                                    <span className="date">13 Sep, 2021</span>
                                                    <span className="payment">₹248</span>
                                                </div>
                                                <div className="payment_list">
                                                    <span className="date">12 Sep, 2021</span>
                                                    <span className="payment">₹14,789</span>
                                                </div>
                                                <div className="payment_list">
                                                    <span className="date">5 Sep, 2021</span>
                                                    <span className="payment">₹5,249.50</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </div>
                {/* Vendor Payment end */}
            </>
        );
    }
}

export default withStyles(styles)(VendorPayments);
