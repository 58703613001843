import React, { useState, Component } from "react";
import {
  Box,
  TextField,
  InputLabel,
  Input,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,  Radio
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TabContainer from "@material-ui/core/TableContainer";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Select from "@material-ui/core/Select";
import MenuItems from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import CheckIcon from "@material-ui/icons/Check";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import CustomerAddressBookController, { Props, configJSON } from "./CustomerAddressBookController.web";
import CustomerAccountSideBarWeb from "../../../components/src/CustomerAccountSideBar.web";
import {
  Menu,
  MenuItem,
  MenuButton,
  SubMenu,
} from "../../../../node_modules/@szhsin/react-menu";

import { withStyles } from "@material-ui/core/styles";
const styles = {
  userouter: {
    // padding: "50px 0",  to remove top margin
    "& h2": {
      fontSize: "18px",
      lineHeight: "22px",
      color: "#121212",
      fontWeight: "700",
      borderBottom: "1px solid #e6e6e6",
      margin: "0",
      textTransform: "uppercase",
      padding: "0 0 20px",
    },
    "& .user-detail-wrapper": {

      padding: "30px 20px",
      background: "#f3f3f3",
      "@media (max-width:1024px)": {
        padding: "20px",
      },
      "@media (max-width:767px)": {
        padding: "15px",
      },
    },
    "& .user-detail-inner": {
      background: "#fff",
      margin: "0 0 20px",
      padding: "29px 20px",
      "@media (max-width:1024px)": {
        padding: "20px",
      },
      "@media (max-width:767px)": {
        padding: "15px",
      },
    },
    "& .user-detail-inner h4": {
      fontSize: "16px",
      lineHeight: "20px",
      color: "#121212",
      fontWeight: "600",
      margin: "0 0 20px",
    },
    "& .inner-detail-wrapper": {
    //   maxWidth: "510px",   removed to fit in web size
      margin: "0 auto",
      "@media (max-width:767px)": {
        maxWidth: "100%",
      },
    },
    "& .btn-wrapper": {
      textAlign: "center",
      padding: "15px 0 0",
      "@media (max-width:767px)": {
        padding: "0 0 0",
      },
    },
    "& .user-address-wrapper .custom-radio": {
      flexDirection: "column",
    },
    "& .custom-radio": {
      display: "flex",
      flexDirection: "row",
    },
    "& .custom-radio .MuiRadio-root": {
      color: "#000",
    },
    "& .title-btn-wrapper": {
      margin: "0 0 10px",
    },
    "& .title-btn-wrapper p": {
      margin: "0 0 0x",
      fontSize: "14px",
      lineheight: "16px",
      color: "#121212",
      fontWeight: "500",
    },
    "& .user-address-wrapper .user-address-list:not(:last-child)": {
      borderBottom: "1px solid #e3e3e3",
      padding: "0 0 25px",
      margin: "0 0 20px",
    },
    "& .user-address-list h6": {
      fontSize: "14px",
      lineHeight: "16px",
      color: "#121212",
      fontWeight: "600",
      margin: "0 0 15px",
    },
    "& .user-address-list p": {
      fontSize: "14px",
      lineHeight: "18px",
      color: "#5d5d66",
      fontWeight: "400",
      margin: "0 0 15px",
      maxWidth: "500px",
      textTransform: "capitalize",
      "@media (max-width:767px)": {
        maxWidth: "100%",
      },
    },
    "& .user-address-list a": {
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: "500",
      color: "#121212",
      textTransform: "uppercase",
      border: "1px solid #212121",
      borderRadius: "4px",
      padding: "4px 7px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "& .user-address-list a:hover": {
      background: "#212121",
      color: "#fff",
    },
    "& .adress-detail-wrapper": {
      padding: "0 0 0 33px",
    },
    "& .address-title-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .address-title-wrapper label .MuiFormControlLabel-label": {
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: "600",
    },
    "& .check": {
      position: "absolute",
      right: "0",
      bottom: "0",
      color: "#21c998",
      display: "none",
    },
    "& .valid .check": {
      display: "block",
    },
    "& .valid-number": {
      position: "absolute",
      right: "0",
      bottom: "7px",
      color: "#0057ff",
      display: "none",
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: "500",
      textDecoration: "none",
    },
    "& .verify .valid-number": {
      display: "block",
    },
  },
};

const error_msg  = {
    color:"red"
  }
  
// Edit Profile End



const profileList = [
  { id: "1", category: "My Profile" },
  { id: "2", category: "My Orders" },
  { id: "3", category: "Shopping With Friends" },
  { id: "4", category: "Address Book" },
  { id: "5", category: "Coupons" },
  { id: "6", category: "Policies" },
  { id: "7", category: "Settings" },
  { id: "8", category: "Loyalty Points" },
];
export class CustomerAddressBook extends CustomerAddressBookController {
//   state = {
//     PhoneVerify: false,
//   };
constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


renderCustomerAddressBook= () => {
    const { classes } = this.props;
    return (<>
     <div className={classes.userouter}>
          {/* <Container maxWidth="lg">
            <Grid container spacing={4}> */}


                {/* side bar  */}

              {/* <Grid item xs={12}>
                <h2>Account</h2>
              </Grid>
              <Grid item xs={12} md={3}>
                <div className="profile-list">
                  <ul>
                    {profileList.map((items) => (
                      <li>
                        <Link href="#">{items.category}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Grid> */}



              <Grid 
            //   item xs={12} md={9} removed for full width
              >
                <div className="user-detail-wrapper">
                  <div className="user-detail-inner">
                    <div className="inner-detail-wrapper">
                      <h4>Address Book</h4>
                      <div className="title-btn-wrapper">
                        <p>Address</p>
                        <Button
                          variant="contained"
                          className="outline-btn"
                          type="button"
                          onClick={(e) => {
                            this.setState({
                              showNewAddressDialog: true,
                            });
                          }}
                        >
                          Add New Address
                        </Button>
                      </div>
                      {/* display address  */}
                      {this.state.addressData.map((address:any)=>         <div className="user-address-wrapper">
                      
                          <div className="user-address-list">
                            <div className="address-title-wrapper">
                              <FormControlLabel
                                value="name"
                                control={
                                  <Radio color="default" 
                                //   color="default" 
                                  />
                                }
                                label={address.attributes.name}
                              />

                              <div
                              onClick = {()=> this.setState({addressId:address.attributes.id})}
                              > 
                                <Menu
                                  menuButton={
                                    <div>
                                      <IconButton>
                                        <MoreHorizIcon />
                                      </IconButton>
                                    </div>
                                  }
                                >
                                  <MenuItem>Edit</MenuItem>
                                  <MenuItem  onClick = {()=> this.deleteAddress()}>Delete</MenuItem>
                                </Menu>
                              </div>
                            </div>
                            <div className="adress-detail-wrapper">
                              <p>
                              {address.attributes.address}
                              </p>
                              <Link href="#">{address.attributes.save_address_as}</Link>
                            </div>
                          </div>
                         
                    
                      </div> )}
                    </div>
                  </div>
                  {/* <div className="btn-wrapper">
                    <Button
                      variant="contained"
                      className="black-btn"
                      type="button"
                    >
                      Save Profile
                    </Button>
                  </div> */}
                </div>
              </Grid>
            {/* </Grid>
          </Container> */}
           {/* Dupilcate Dialog */}
        <Dialog
          // onClose={this.handleCloseNewAddressDialog}
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.showNewAddressDialog}
        >
          <DialogTitle
            id="customized-dialog-title"
            style={{ marginRight: "15px" }}
          >
            Add New Address
            <span style={{ position: "absolute", right: "10px" }}>
              <CloseIcon onClick={this.handleCloseNewAddressDialog} />
            </span>
          </DialogTitle>
          <DialogContent style={{ width: "450px" }}>
            <div className="form-group">
              <InputLabel htmlFor="my-input">
                Name<sup>*</sup>
              </InputLabel>
              <FormControl>
                <Input id="my-input" aria-describedby="my-helper-text" value = {this.state.name} onChange = {(e)=>{this.setState({name:e.target.value});console.log(this.state.name)}} />
              </FormControl>
              <p style= {error_msg}>
                        {this.state.nameError}
                      </p>
            </div>
            <div className="form-group">
              <InputLabel  htmlFor="my-input">Mobile Number<sup>*</sup></InputLabel>
              <FormControl>
                <Input  type ="number" id="my-input" aria-describedby="my-helper-text"  value = {this.state.mobile}  onChange = {(e)=>{this.setState({mobile:e.target.value});console.log(this.state.mobile)}}  />
              </FormControl>
              <p  style= {error_msg}>
                        {this.state.mobileError}
                      </p>
            </div>
            <div className="form-group">
              <InputLabel htmlFor="my-input">Pin Code<sup>*</sup></InputLabel>
              <FormControl>
                <Input id="my-input" type = "number" aria-describedby="my-helper-text"  value = {this.state.pincode}  onChange = {(e)=>{this.setState({pincode:e.target.value});console.log(this.state.pincode)}}  />
              </FormControl>
              <p  style= {error_msg}>
                        {this.state.pincodeError}
                      </p>
            </div>
            <div className="form-group">
              <InputLabel htmlFor="my-input">
                Address(House No, Building, street)<sup>*</sup>
              </InputLabel>
              <FormControl>
                <Input id="my-input" aria-describedby="my-helper-text"  value = {this.state.address}  onChange = {(e)=>{this.setState({address:e.target.value});console.log(this.state.address)}}   />
              </FormControl>
              <p  style= {error_msg}>
                        {this.state.addressError}
                      </p>
            </div>
            <div className="form-group">
              <InputLabel htmlFor="my-input">Locality / Town<sup>*</sup></InputLabel>
              <FormControl>
                <Input id="my-input" aria-describedby="my-helper-text"  value = {this.state.town}  onChange = {(e)=>{this.setState({town:e.target.value});console.log(this.state.town)}}  />
              </FormControl>
              <p  style= {error_msg}>
                        {this.state.townError}
                      </p>
            </div>
            <div className="form-group">
              <InputLabel htmlFor="my-input">City / District<sup>*</sup></InputLabel>
              <FormControl>
                <Input id="my-input" aria-describedby="my-helper-text"  value = {this.state.city}  onChange = {(e)=>{this.setState({city:e.target.value});console.log(this.state.city)}}   />
              </FormControl>
              <p  style= {error_msg}>
                        {this.state.cityError}
                      </p>
            </div>
            <div className="form-group">
              <InputLabel htmlFor="my-input">State<sup>*</sup></InputLabel>
              <FormControl>
                <Input id="my-input" aria-describedby="my-helper-text"   value = {this.state.state}  onChange = {(e)=>{this.setState({state:e.target.value});console.log(this.state.state)}} />
              </FormControl>
              <p  style= {error_msg}>
                        {this.state.stateError}
                      </p>
            </div>



            <div className="form-group">
            <RadioGroup className="align-row" aria-label="address" name="address">
              <h4>Save Address As</h4>
              {/* <FormControlLabel value="1"  checked={this.state.save_add === "home"} control={<Radio />} label="home"  onChange={()=>this.setState({save_add:"home"})}/>
              <FormControlLabel value="2" control={<Radio />} label="office"   onChange={()=>{this.setState({save_add:"office"});console.log(this.state.save_add)}}/> */}
                        <FormControlLabel value = "home"  checked={this.state.save_add === 'home'}  control={<Radio />} label="home"  onChange={(e:any)=>{this.setState({save_add:e.target.value},()=> console.log(this.state.save_add))}}/>
              <FormControlLabel  value ="office" control={<Radio />}  checked={this.state.save_add === 'office'}  label="office"   onChange={(e:any)=>{this.setState({save_add:e.target.value},()=> console.log(this.state.save_add))}}/>
              </RadioGroup>
              <p  style= {error_msg}>
                        {this.state.save_addError}
                      </p>
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: "flex-start" }}>
            <Button
              type="submit"
              variant="contained"
              style = {{width:"100%"}}
              className="black-btn order-btn"
              onClick={(e) => {
             
             this.addThisDeliveryAddress()
              }
              }
            >
              Add Address
            </Button>
          </DialogActions>
        </Dialog>
        </div>
        {/* Edit Profile End */}

         {/* cancel order
         <Dialog
          aria-labelledby="customized-dialog-duplicate"
          open={this.state.PhoneVerify}
          className="phone-verify"
        >
          <DialogTitle id="customized-dialog-title">
          <span>Enter OTP To Verify Phone Number</span>
            <CloseIcon
              className="close-dialog"
              onClick={(e) => {
                this.setState({
                  PhoneVerify: false,
                });
              }}
            />
          </DialogTitle>
          <DialogContent style={{ width: "480px" }}>
            <div className="phone-verify-content">
              <p>Please enter OTP sent to <span>98XXXXX080</span></p>
              <div className="form-group">
                    <InputLabel htmlFor="my-input">Enter OTP</InputLabel>
                    <FormControl>
                        <Input type="text" id="my-input" aria-describedby="my-helper-text" />
                    </FormControl>
              </div>
              <p className="resend-code">
                <Link href="#">Resend OTP </Link> <span>in 59s</span>
              </p>
            </div>
            <div className="btn-wrapper">
              <Button href="" type="submit" variant="contained" className="black-btn">
                Verify
              </Button>
            </div>
          </DialogContent>
        </Dialog> */} </>)
}

    
  



  render() {
      
    const { classes } = this.props;

    return (
      <>



<CustomerAccountSideBarWeb id={1} navigation={this.props.navigation} callbackShopWithFriend={this.props.callbackShopWithFriend} >
                    {this.renderCustomerAddressBook()}
                </CustomerAccountSideBarWeb>
















       
      </>
      
    );
  }
}

export default withStyles(styles)(CustomerAddressBook);
