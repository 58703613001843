import React, { Component } from "react";
import {
  Box,
  TextField,
  InputLabel,
  Input,
  Divider,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Menu } from "../../../../node_modules/@szhsin/react-menu";
import { withStyles } from "@material-ui/core/styles";
import AddProductPriceDetailController from "./AddProductPriceDetailController.web";
import { Formik } from "formik";
import * as Yup from 'yup';

const styles = {
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "50px 0",
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      textTransform: "uppercase",
      color: "#121212",
      fontSize: "20px"
    },
    "& h4": {
      margin: "0 0 20px 0",
    },
    "& .block-wrapper": {
      backgroundColor: "#fff",
      padding: "30px 15px 40px",
    },
    "& .block-wrapper-inner": {
      maxWidth: "515px",
      margin: "0 auto",
    },
    "& .error-msg": {
      fontSize: "13px",
      color: "red",
    },
    "& label": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#121212",
    },
    "& .MuiFormControl-root": {
      display: "block",
    },
    "& .MuiInputBase-root": {
      display: "block",
    },
    "& .MuiSelect-select": {
      background: "transparent",
    },
    "& .MuiFormControl-root .MuiInputBase-root": {
      display: "block",
    },
    "& .title-btn-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "25px 0",
    },
    "& .title-btn-wrapper h4": {
      margin: "0 0 0",
    },
    "& .title-btn-wrapper .black-btn": {
      minWidth: "100px",
    },
    "& .btn-wrapper": {
      textAlign: "center",
      paddingTop: "20px",
    },
    "& .guide-block": {
      border: "1px solid rgba(0, 0, 0, 0.42)",
      padding: "25px 15px",
      textAlign: "center",
      borderRadius: "6px",
    },
    "& .guide-block p": {
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.54)",
    },
    "& .add-row": {
      margin: "30px 0 0",
      borderTop: "1px solid rgba(0,0,0,0.45)",
    },
    "& .add-inner-row": {
      margin: "0 0 30px",
      borderBottom: "1px solid rgba(0,0,0,0.45)",
    },
    "& .add-inner-row .row-title-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 0 25px",
    },
    "& .add-inner-row .row-title-wrapper p": {
      margin: "0 0 0",
    },
    "& .add-inner-row .row-title-wrapper img": {
      height: "15px",
      width: "15px",
      cursor: "pointer",
    },

    "& .block-wrapper-inner p": {
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: "16px",
      margin: "0 0 15px",
    },
    "& .product-wrapper": {
      border: "1px solid #d3d3d3",
      padding: "15px",
      margin: "0 0 20px",
    },
    "& .product_detail": {
      margin: "0 0 20px",
    },
    "& .product_detail p": {
      margin: "0 0 10px",
    },
    "& .product_detail p:first-child": {
      margin: "0 0 15px",
    },
    "& .product_detail p b": {
      color: "#000",
      fontWeight: "400",
    },
    "& .custom-radio": {
      display: "flex",
      flexDirection: "row",
    },
    "& .custom-radio label": {
      color: "#000",
    },
    "& .custom-radio label .MuiIconButton-label": {
      color: "#000",
    },
    "& .block-wrapper-inner .error": {
      color: "red",
    },
  },
};

const productPriceSchema = Yup.object().shape({
  gstId: Yup.string().nullable().required("GST is required"),
  maximum_retail_price: Yup.string().nullable().required("Maximum Retail Price is required"),
  salePrice: Yup.string().nullable().required("Sale Price is required")
});

export class AddProductPriceDetail extends AddProductPriceDetailController {
  constructor(props: any) {
    super(props);
    this.myRef = React.createRef();
  }

  steps = ['Basic Info', 'Product Details', 'Pricing', 'Other Info', 'Policy'];
  setActiveStep = (step: any) => {
    this.setState({ activeStep: step });
  }

  handleNext = () => {
    this.setActiveStep(this.state.activeStep + 1);
  };

  handleBack = () => {
    this.gotoProductDetailScreen();
  };

  menuProps: any = {
    style: { maxHeight: "300px" },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };

  render() {
    const { classes } = this.props;
    let arr = this.state.priceData;
    return (
      <>
        {/* ADD PRODUCT start */}
        <div className={classes.pageOuter} ref={this.myRef}>
          <div>
            <Container maxWidth="lg">
              <Grid container>
                <Grid item xs={12}>
                  {/* step bar start */}
                  <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={this.state.activeStep}>
                      {this.steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                          optional?: React.ReactNode;
                        } = {};
                        if (index < this.state.activeStep) {
                          stepProps.completed = true;
                        }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                    <React.Fragment>
                      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                          color="inherit"
                          disabled={this.state.activeStep === 0}
                          onClick={this.handleBack}
                        >
                          Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {/* <Button onClick={this.handleNext}>
                          {this.state.activeStep === this.steps.length - 1 ? 'Finish' : 'Next'}
                        </Button> */}
                      </Box>
                    </React.Fragment>
                  </Box>
                  {/* step bar end */}

                  <h2>{this.state.isEdit ? "EDIT PRODUCT" : "ADD PRODUCT"}</h2>
                </Grid>
                <Grid item xs={12}>
                  <div className="block-wrapper">
                    <div className="block-wrapper-inner">
                      <Formik
                        initialValues={this.intialValues}
                        validationSchema={this.state.isSamePrice ? productPriceSchema : ''}
                        onSubmit={(values) => {
                          this.addProductPriceApiCall();
                        }}>
                        {(props) => {
                          const { errors, touched, isValid, dirty } = props;
                          props.values.maximum_retail_price = this.state.maximum_retail_price;
                          props.values.salePrice = this.state.salePrice;
                          props.values.gstId = this.state.gstId;

                          return (
                            <form onSubmit={props.handleSubmit}>
                              <h4>Price Details</h4>
                              <p>Add price of all products</p>
                              <div className="form-group">
                                <RadioGroup className="custom-radio" name="price">
                                  <FormControlLabel
                                    value="price1"
                                    control={<Radio color="default" checked={this.state.isSamePrice} onChange={() => this.setState({ isSamePrice: true })} />}
                                    label="Same Base Price"
                                  />
                                  <FormControlLabel
                                    value="pric2"
                                    control={<Radio color="default" checked={!this.state.isSamePrice} onChange={() => this.setState({ isSamePrice: false })} />}
                                    label="Price Variation"
                                  />
                                </RadioGroup>
                              </div>
                              {/* Same Base Price */}
                              {this.state.isSamePrice && (
                                <>
                                  <div className="form-group">
                                    <InputLabel htmlFor="priceretail">
                                      Maximum Retail Price*
                                    </InputLabel>
                                    <FormControl>
                                      <Input
                                        type="text"
                                        id="maximum_retail_price"
                                        name="maximum_retail_price"
                                        value={props.values.maximum_retail_price}
                                        error={touched.maximum_retail_price && errors.maximum_retail_price ? true : false}
                                        onChange={(e) => this.handleChange(e)}
                                        aria-describedby="my-helper-text"
                                      />
                                      <span className="error">{touched.maximum_retail_price && errors.maximum_retail_price ? errors.maximum_retail_price : ''}</span>
                                    </FormControl>
                                  </div>
                                  <div className="form-group">
                                    <InputLabel htmlFor="saleprice3">
                                      Sale Price*
                                    </InputLabel>
                                    <FormControl>
                                      <Input
                                        type="text"
                                        id="salePrice"
                                        name="salePrice"
                                        value={props.values.salePrice}
                                        error={touched.salePrice && errors.salePrice ? true : false}
                                        onChange={(e) => this.handleChange(e)}
                                        aria-describedby="my-helper-text"
                                      />
                                      <span className="error">{touched.salePrice && errors.salePrice ? errors.salePrice : ''}</span>
                                    </FormControl>
                                  </div>
                                </>
                              )}
                              <div className="form-group">
                                <InputLabel id="product-category">GST*</InputLabel>
                                <Select
                                  id="gstId"
                                  name="gstId"
                                  value={props.values.gstId}
                                  error={touched.gstId && errors.gstId ? true : false}
                                  onChange={(e) => this.handleChange(e)}
                                  MenuProps={this.menuProps}
                                  aria-describedby="my-helper-text"
                                >
                                  {
                                    this.state.gstData &&
                                    this.state.gstData.length &&
                                    this.state.gstData.map((gst: any) =>
                                      <MenuItem key={gst.value} value={gst.value}>{gst.label}</MenuItem>
                                    )
                                  }
                                </Select>
                                <span className="error">{touched.gstId && errors.gstId ? errors.gstId : ''}</span>
                                {/* for product varinats */}
                                {!this.state.isSamePrice &&
                                  this.state.hasError &&
                                  !this.state.gstId &&
                                  <> <span className="error">GST is required</span> </>
                                }
                              </div>

                              {/* price variation */}
                              {!this.state.isSamePrice && (
                                <>
                                  {arr &&
                                    arr.length &&
                                    arr.map((item, index) => (
                                      <>
                                        <div className="product-wrapper">
                                          <div className="product_detail">
                                            <p>Product {index + 1}</p>
                                            <p>
                                              Name: <b>{this.state.product.attributes.name}</b>
                                            </p>
                                            <p>
                                              Color: <b>{item.color}</b>
                                            </p>
                                            <p>
                                              Size: <b>{item.size}</b>
                                            </p>
                                          </div>
                                          <div className="form-group">
                                            <InputLabel htmlFor="price">
                                              Maximum Retail Price*
                                            </InputLabel>
                                            <FormControl>
                                              <Input
                                                type="text"
                                                id="retailPrice"
                                                name="retailPrice"
                                                onChange={(e) => this.onUpdatePrice(e.target.value, item, 'retailPrice')}
                                                value={item.maximum_retail_price}
                                                aria-describedby="my-helper-text"
                                              />
                                              {this.state.hasError && !item.maximum_retail_price && <span className="error">Maximum Retail Price is required</span>}
                                            </FormControl>
                                          </div>
                                          <div className="form-group">
                                            <InputLabel htmlFor="saleprice">
                                              Sale Price*
                                            </InputLabel>
                                            <FormControl>
                                              <Input
                                                type="text"
                                                id="saleprice"
                                                name="saleprice"
                                                onChange={(e) => this.onUpdatePrice(e.target.value, item, 'salePrice')}
                                                value={item.sale_price}
                                                aria-describedby="my-helper-text"
                                              />
                                              {this.state.hasError && !item.sale_price && <span className="error">Sale Price is required</span>}
                                            </FormControl>
                                          </div>
                                        </div>
                                      </>
                                    ))}
                                </>
                              )}
                              <div className="btn-wrapper">
                                <Button
                                  variant="contained"
                                  className="black-btn"
                                  type="submit"
                                // onClick={this.addProductPriceApiCall}
                                >
                                  Continue
                                </Button>
                              </div>
                            </form>
                          )
                        }}
                      </Formik>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        {/* ADD PRODUCT end */}
      </>
    );
  }
}

export default withStyles(styles)(AddProductPriceDetail);
