import { IBlock } from "../../../framework/src/IBlock";
import firebase from "firebase";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
import { SignUpAccountModel } from "../../../framework/src/Interfaces/ISignUp";
import { getReturnUrlOnSuccessFullLogin } from "../../../components/src/logout";

const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

interface S {
  countryCodeSelected: string;
  mobileNo: string;
  token: string,
  googleToken: string;
  userId: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderCountryCode: string;
  placeHolderMobile: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  isRegistration: boolean;
  btnTxtSocialLogin: string;
  labelOr: string;
  labelForgotPassword: string;
  btnTxtEmailLogin: string;
  switchNavigate: any;
  type: string;
  email_mobile: boolean;
  otp: string;
  email_mobileNo: string;
  isValidEmailMobile: boolean;
  showDialog: boolean;
  name: string;
  gender: string;
  email: string;
  dob: Date | null;
  password: string;
  confirmPassword: string;
  showPassword: boolean;
  showConfirmPassword: boolean;
  minLength: boolean;
  upperCase: boolean;
  lowerCase: boolean;
  specialCase: boolean;
  number: boolean;
  skipLogin: boolean;
  signInScreen: boolean;
  signUpScreen: boolean;
  passwordScreen: boolean;
  otpScreen: boolean;
  setNewPasswordScreen: boolean;
  editEmailMobileNo: boolean;
  isLoginFailed: boolean;
  isPinIncorrect: boolean;
  isSignUpFailed: boolean;
  SignUpErrorMsg: string;
  isNewAccount: boolean
  counter: number;
  timer: any;
  isAccountExist: boolean;
  isSocialAccount: boolean;
  isResendOTP: boolean;
  role: string;
  setNewPassword: string;
  setNewConfirmPassword: string;
  showNewPassword: boolean;
  showNewConfirmPassword: boolean;
  forgetPasswordScreen: boolean;
  isSetNewPasswordSuccess: boolean;
  ForgetOtp: string;

  isSignupApiCalled: boolean;
}

interface SS {
  id: any;
}

export default class MobileAccountLoginWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  phoneAuthApiCallId: any;
  apiPhoneLoginCallId: any;
  sendOtpApiCallId: any;
  accExistApiCallId: any;
  createAccountApiCallId: any;
  VerifyOTPApiCallId: any;
  loginApiCallId: any;
  sendOTPApiCallId: any;
  createGoogleApiCallId: any;
  loginGoogleApiCallId: any;
  SetNewPasswordCallId: any;
  VerifyOTPForgetPassApiCallId: any;
  labelTitle: string;

  private readonly errorTitle = "Error";
  signUpModelRef: React.RefObject<HTMLDivElement>; // error referrance

  public intialValues: SignUpAccountModel = {
    name: '',
    gender: 'male',
    email: '',
    dob: null,
    password: '',
    confirmPassword: ''
  };

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.signUpModelRef = React.createRef();

    this.state = {
      countryCodeSelected: "",
      email_mobile: true,
      mobileNo: "",
      token: "",
      googleToken: "",
      userId: "",
      placeHolderCountryCode: "Select Country",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderMobile: configJSON.placeHolderMobile,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      labelForgotPassword: configJSON.labelForgotPassword,
      btnTxtEmailLogin: configJSON.btnTxtEmailLogin,
      switchNavigate: false,
      isRegistration: true,
      type: "",
      email_mobileNo: '',
      showDialog: true,
      showPassword: false,
      showConfirmPassword: false,
      name: '',
      gender: '',
      email: '',
      dob: null,
      password: '',
      confirmPassword: '',
      minLength: false,
      upperCase: false,
      lowerCase: false,
      specialCase: false,
      number: false,
      skipLogin: false,
      signInScreen: true,
      signUpScreen: false,
      passwordScreen: false,
      otpScreen: false,
      setNewPasswordScreen: false,
      editEmailMobileNo: false,
      otp: '',
      isLoginFailed: false,
      isPinIncorrect: false,
      isSignUpFailed: false,
      SignUpErrorMsg: "",
      isNewAccount: false,
      counter: 0,
      timer: null,
      isAccountExist: false,
      isSocialAccount: false,
      isValidEmailMobile: true,
      isResendOTP: false,
      role: '',
      setNewPassword: "",
      setNewConfirmPassword: "",
      showNewPassword: false,
      showNewConfirmPassword: false,
      forgetPasswordScreen: false,
      isSetNewPasswordSuccess: false,
      ForgetOtp: "",
      isSignupApiCalled: false
    };

    this.labelTitle = configJSON.labelTitle;

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    localStorage.clear();
    // this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (apiRequestCallId && responseJson) {
        if (
          apiRequestCallId === this.accExistApiCallId ||
          apiRequestCallId === this.createAccountApiCallId ||
          apiRequestCallId === this.VerifyOTPApiCallId ||
          apiRequestCallId === this.loginApiCallId ||
          apiRequestCallId === this.sendOTPApiCallId
        ) {
          this.receiveBlock1(apiRequestCallId, responseJson);
        } else if (
          apiRequestCallId === this.createGoogleApiCallId ||
          apiRequestCallId === this.loginGoogleApiCallId ||
          apiRequestCallId === this.SetNewPasswordCallId ||
          apiRequestCallId === this.VerifyOTPForgetPassApiCallId
        ) {
          this.receiveBlock2(apiRequestCallId, responseJson);
        }
      }

      if (errorReponse) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  receiveBlock1 = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.accExistApiCallId) {
      this.accountExistApiResponse(responseJson);
    } else if (apiRequestCallId === this.createAccountApiCallId) {
      this.createAccountApiResponse(responseJson);
    } else if (apiRequestCallId === this.VerifyOTPApiCallId) {
      this.verifyOTPApiResponse(responseJson);
    } else if (apiRequestCallId === this.loginApiCallId) {
      this.loginApiResponse(responseJson);
    } else if (apiRequestCallId === this.sendOTPApiCallId) {
      this.sendOTPApiResponse(responseJson);
    }

  }

  receiveBlock2 = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.createGoogleApiCallId) {
      this.createGoogleApiResponse(responseJson);
    } else if (apiRequestCallId === this.loginGoogleApiCallId) {
      this.loginGoogleApiResponse(responseJson);
    } else if (apiRequestCallId === this.SetNewPasswordCallId) {
      this.setNewPasswordApiResponse(responseJson);
    } else if (apiRequestCallId === this.VerifyOTPForgetPassApiCallId) {
      this.verifyOTPForgetPasswordApiResponse(responseJson);
    }
  }

  accountExistApiResponse = (responseJson: any) => {
    if (responseJson.exist == 1) {
      if (this.state.isSocialAccount) {
        this.setState({ ...this.state, isAccountExist: false });
        this.loginFromSocial(this.state.email_mobileNo, this.state.googleToken);
      }
      else {
        this.setState({ passwordScreen: true, signInScreen: false, isAccountExist: true, isSignUpFailed: false });
      }
    } else {
      if (this.state.isSocialAccount) {
        const values = {
          name: this.state.name,
          email: this.state.email
        } as SignUpAccountModel;
        this.createAccountFromSocial(this.state.email_mobileNo, this.state.googleToken, this.state.name);
        return;
      }

      if (this.state.signUpScreen) {
        this.setState({ signUpScreen: true, signInScreen: false, isAccountExist: false });
      } else {
        this.setState({ signUpScreen: true, signInScreen: false, isAccountExist: false, minLength: false, upperCase: false, lowerCase: false, number: false, specialCase: false });
      }
    }
  }

  createAccountApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson?.errors) {
      if (this.state.isSocialAccount) {
        const msg: Message = new Message(
          getName(MessageEnum.NavigationHomeScreenMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
        localStorage.setItem('authToken', this.state.token)
      }
      // redirect to otp screen
      this.setState({ token: responseJson?.meta?.token, signUpScreen: false, otpScreen: true, isSignUpFailed: false, SignUpErrorMsg: '', isNewAccount: true });
    } else if (responseJson && responseJson?.errors?.[0]?.account) {
      //Check Error Response
      this.scrollToTopSignUpModal();
      this.setState({ isSignUpFailed: true, SignUpErrorMsg: responseJson.errors[0].account });
    } else {
      //Check Error Response
      this.scrollToTopSignUpModal();
      this.setState({ isSignUpFailed: true, SignUpErrorMsg: responseJson.errors });
    }

    this.setState({
      isSignupApiCalled: false  // enable send otp button after response
    })
  }

  verifyOTPApiResponse = (responseJson: any) => {
    if (!responseJson?.errors) {
      this.setState({ token: responseJson.meta.token, role: responseJson.meta.role, userId: responseJson.meta.id, isPinIncorrect: false });
      try {
        localStorage.setItem('role', this.state.role);
        localStorage.setItem('authToken', this.state.token);
        localStorage.setItem('userId', this.state.userId);
        localStorage.setItem('IsLogin', "true");

        // if query parameter found(like ?returnUrl)
        // if return Url Present then redirect to that url
        if (window.location.search && this.state.isAccountExist) {
          this.redirectToUrlAfterSuccessFullLogin();
          return;
        }

        if (responseJson.meta.role === "vendor") {
          this.props.navigation.navigate("HomePage");
        } else {
          this.props.navigation.navigate("Home");
        }
        location.reload();
      }
      catch (e) {
      }
    } else {
      this.setState({ isPinIncorrect: true });
    }
  }

  loginApiResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({ ...this.state, isSetNewPasswordSuccess: false, token: responseJson.meta.token, role: responseJson.meta.role, isLoginFailed: false, userId: responseJson.meta.id });
      try {
        localStorage.setItem('role', this.state.role)
        localStorage.setItem('authToken', this.state.token)
        localStorage.setItem('userId', this.state.userId)
        localStorage.setItem('IsLogin', "true")
      }
      catch (e) {
      }

      // if query parameter found(like ?returnUrl) then redirect to that url
      if (window.location.search) {
        this.redirectToUrlAfterSuccessFullLogin();
        return;
      }

      if (responseJson.meta.role === "vendor") {
        this.props.navigation.navigate("HomePage");
      } else {
        this.props.navigation.navigate("Home");
      }
      location.reload();
    } else {
      this.setState({ isLoginFailed: true });
    }
  }

  sendOTPApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson?.errors) {
      if (this.state.isResendOTP) {
        this.setTimer();
      }
      this.setState({ token: responseJson?.meta?.token });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  createGoogleApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson?.errors) {
      this.setState({ token: responseJson.meta.token, role: responseJson.meta.role, isLoginFailed: false, userId: responseJson.data.id });
      try {
        localStorage.setItem('role', 'customer');  // for google login is only available for customer
        localStorage.setItem('authToken', this.state.token)
        localStorage.setItem('userId', this.state.userId)
        localStorage.setItem('IsLogin', "true")
      }
      catch (e) {
      }
      
      if (window.location.search) { // if query parameter found(like ?returnUrl) then redirect to that url
        this.redirectToUrlAfterSuccessFullLogin();
        return;
      }

      if (responseJson.meta.role === "vendor") {
        this.props.navigation.navigate("HomePage");
      } else {
        this.props.navigation.navigate("Home");
      }
      location.reload();
    } else {
      this.setState({ isLoginFailed: true });
    }
  }

  loginGoogleApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson?.errors) {
      this.setState({ token: responseJson.meta.token, role: responseJson.meta.role, isLoginFailed: false, userId: responseJson.meta.id });
      try {
        localStorage.setItem('role', this.state.role)
        localStorage.setItem('authToken', this.state.token)
        localStorage.setItem('userId', this.state.userId)
        localStorage.setItem('IsLogin', "true")
      }
      catch (e) {
      }

      if (responseJson.meta.role === "vendor") {
        this.props.navigation.navigate("HomePage");
      } else {
        this.props.navigation.navigate("Home");
      }
      location.reload();
    } else {
      this.setState({ isLoginFailed: true });
    }
  }

  setNewPasswordApiResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        ...this.state,
        setNewPassword: "",
        setNewConfirmPassword: "",
        setNewPasswordScreen: false,
        passwordScreen: true,
        isSetNewPasswordSuccess: true,
        isLoginFailed: false,
      });
    } else {
      this.setState({ isLoginFailed: true });
      this.parseApiErrorResponse(responseJson);
    }
  }

  verifyOTPForgetPasswordApiResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        ...this.state,
        token: responseJson.meta.token,
        role: responseJson.meta.role,
        userId: responseJson.meta.id,
        isPinIncorrect: false,
        ForgetOtp: "",
      });
      try {
        this.goToSetPasswordScreen();
      }
      catch (e) {
      }
    } else {
      this.setState({ isPinIncorrect: true });
    }
  }

  redirectToUrlAfterSuccessFullLogin = () => {
    const returnUrl = getReturnUrlOnSuccessFullLogin();
    console.log("returnUrl:::", returnUrl);
    if (returnUrl) {
      window.location.href = window.location.origin + returnUrl;
      return;
    }
  }

  goToLoginScreen = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMobilePhoneLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  doEmailLogIn(): boolean {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.mobileNo,
      password: this.state.password
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPhoneLoginCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.mobileNo);

    msg.addData(
      getName(MessageEnum.LoginCountryCode),
      this.state.countryCodeSelected
    );

    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveOtpTokenData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.NavigationMobilePhoneAdditionalDetailsMessage));

      msg.addData(
        getName(MessageEnum.AuthTokenDataMessage),
        JSON.stringify(responseJson)
      );

      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );
      this.send(msg);
    }
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );

      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );
      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "sms");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToEmailLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doMobileLogIn(): boolean {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      full_phone_number: this.state.mobileNo,
      password: this.state.password
    };

    const data = {
      type: "sms_account",
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPhoneLoginCallId = apiRequest.messageId;
    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  sendOtp(): boolean {
    const header = {
      "Content-Type": configJSON.apiSendOtpContentType
    };

    const attrs = {
      full_phone_number: this.state.mobileNo
    };

    let dataObj = {}

    if (this.state.type == 'email_account') {
      dataObj = {
        email: this.state.mobileNo
      };
    } else {
      dataObj = {
        full_phone_number: this.state.mobileNo
      };
    }

    const data = dataObj
    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendOtpApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiSendOtpEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiSendOtpMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  processOnClickMessage(messageID: string, values: any = null) {
    switch (messageID) {
      case 'btnSendOtp':
        this.sendOtp();
        break;
      default:
        break;
    }
  }

  validateEmail = (text: string): boolean => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    // let reg = /^w+([.]?)w+@\w+\.\w{2,3})$/;
    if (reg.test(text) === false) {
      return false;
    }
    else {
      return true;
    }
  }

  validateNavigation = async (event: any) => {
    event?.preventDefault();
    const isValid = this.state.email_mobileNo.match(/^(?:\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+))$/);
    if (!isValid) {
      this.setState({ isValidEmailMobile: false });
      return false;
    }

    if (this.validateEmail(this.state.email_mobileNo)) {
      this.setState({ type: 'email_account' })
      this.checkAccountExist(event, 'email_account')
    } else {
      this.setState({
        type: 'sms_account'
      })
      this.checkAccountExist(event, 'sms_account')
    }
  }

  checkAccountExist = async (event?: any, type?: string) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    let dataObj = {}

    if (type === 'email_account') {
      dataObj = {
        type: "email_account",
        email: this.state.email_mobileNo
      };
    } else if (type === 'social_account') {
      dataObj = {
        type: "social_account",
        email: this.state.email_mobileNo
      };
    } else {
      dataObj = {
        type: "sms_account",
        full_phone_number: this.state.email_mobileNo
      };
    }

    const data = dataObj
    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.accExistApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiAccExist
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  public changeState(objectID: string, value: any) {
    switch (objectID) {
      case 'txtInputPhoneNumber':
        this.setState({ mobileNo: value });
        break;
      case 'txtInputPassword':
        this.setState({ password: value });
        break;
      default:
    }

  }

  handleClose = () => {
    this.setState({
      showDialog: false
    });
    this.props.navigation.navigate("Home");
  };

  setValue = (date: Date) => {
    this.setState({
      dob: date
    })
  }

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  handleClickShowNewPassword = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword
    });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      showNewConfirmPassword: !this.state.showNewConfirmPassword
    });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value.trim()
    });
  }

  handleDobChange = (date: Date) => {
    this.setState({ dob: date });
  }

  handlePasswordChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await this.setState({ password: event.target.value }, async () => {
      await this.passwordValidation(this.state.password)
    });
  }

  handleSetNewPasswordChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, setNewPassword: event.target.value });
  }

  handleSetNewConfirmPasswordChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ ...this.state, setNewConfirmPassword: event.target.value });
  }

  passwordValidation = (password: string) => {
    if (password.length >= 8) {
      this.setState({ minLength: true });
    } else {
      this.setState({ minLength: false });
    }

    if ((/[A-Z]/).test(password)) {
      this.setState({ upperCase: true });
    } else {
      this.setState({ upperCase: false });
    }

    if ((/[a-z]/).test(password)) {
      this.setState({ lowerCase: true });
    } else {
      this.setState({ lowerCase: false });
    }

    if ((/[0-9]/).test(password)) {
      this.setState({ number: true });
    } else {
      this.setState({ number: false });
    }

    if ((/[^A-Za-z0-9]/).test(password)) {
      this.setState({ specialCase: true });
    } else {
      this.setState({ specialCase: false });
    }
  }

  handleSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
  }

  login = (event: any) => {
    event?.preventDefault();
    const email_account = this.validateEmail(this.state.email_mobileNo);
    let type = "";
    if (email_account) {
      type = "email_account"
    } else {
      type = "sms_account"
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    let attrs;
    if (type === "sms_account") {
      attrs = {
        full_phone_number: this.state.email_mobileNo,
        password: this.state.password
      };
    } else {
      attrs = {
        email: this.state.email_mobileNo,
        password: this.state.password
      };
    }

    const data = {
      type: type,
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.loginApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  createAccount(values: SignUpAccountModel, isSocialAccount?: boolean): boolean {

    if (!this.state.email_mobileNo) {
      this.setState({ isValidEmailMobile: false })
      return true;
    }
    const { name, email, gender, dob, password } = values;
    let type = "";
    if (this.validateEmail(this.state.email_mobileNo)) {
      this.setState({ type: 'email_account' })
      type = "email_account";
    } else {
      if (this.state.email_mobileNo === null || this.state.email_mobileNo.length === 0) {
        this.setState({ isValidEmailMobile: false })
        return false;
      }
      else if (this.state.email_mobileNo.length < 10) {
        this.setState({ isValidEmailMobile: false })
        return false;
      }
      else if (this.state.email_mobileNo.length >= 11) {
        this.setState({ isValidEmailMobile: false })
        return false;
      }
      else {
        this.setState({
          type: 'sms_account'
        })
      }
      type = 'sms_account';
    }

    if (isSocialAccount) {
      type = "social_account"
    }
    let attrs;
    if (this.validateEmail(this.state.email_mobileNo)) {
      attrs = {
        name: name,
        email: this.state.email_mobileNo,
        password: password,
        gender: gender,
        date_of_birth: dob,
        user_type: 'customer',
        activated: true
      };
    } else {
      attrs = {
        name: name,
        email: email,
        full_phone_number: this.state.email_mobileNo,
        password: password,
        gender: gender,
        date_of_birth: dob,
        user_type: 'customer',
        activated: true
      };
    }

    this.setState({
      isSignupApiCalled: true // disable sent otp button after click
    })

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const data = {
      type: type,
      attributes: attrs
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  verifyOTP = (event: any) => {
    event?.preventDefault();
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.state.token
    };

    let account_type = '';
    if (this.validateEmail(this.state.email_mobileNo)) {
      account_type = configJSON.emailType;
    } else {
      account_type = configJSON.smsType;
    }

    const httpBody = {
      pin: this.state.otp
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.VerifyOTPApiCallId = requestMessage.messageId;
    if (account_type === configJSON.emailType) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyOtpEndPoint
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyOtpEndPointSMS
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  resendOTP = (event: any) => {
    this.preventDefault(event);
    this.setState({ isResendOTP: true }, () => this.sendOTP(event));
    return;
  };

  sendOTP = (event: any) => {
    this.preventDefault(event);
    this.goToOTPScreen();

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    let dataObj = {};

    if (this.validateEmail(this.state.email_mobileNo)) {
      dataObj = {
        email: this.state.email_mobileNo
      };
    } else {
      dataObj = {
        full_phone_number: this.state.email_mobileNo
      };
    }

    const httpBody = {
      data: dataObj
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendOTPApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendOTPAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // google login
  // if google account not exist then create new account
  createAccountFromSocial = (
    email: String,
    googleTokenId: String,
    name: string
  ) => {
    if (
      !email ||
      email.length === 0 ||
      !googleTokenId ||
      googleTokenId.length === 0
    ) {
      runEngine.debugLog("createAccountFromSocial empty info");
      return;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createGoogleApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    const data = {
      type: "social_account",
      attributes: {
        name: name,
        email: email,
        unique_auth_id: googleTokenId,
        user_type: 'customer',
      }
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  loginFromSocial = (
    incomingEmail: String,
    incomingId: String,
  ) => {
    if (
      !incomingEmail ||
      incomingEmail.length === 0 ||
      !incomingId ||
      incomingId.length === 0
    ) {
      runEngine.debugLog("createAccountFromSocial empty info");
      return;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.loginGoogleApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    const data = {
      type: "social_account",
      attributes: {
        email: incomingEmail,
        password: incomingEmail,
        unique_auth_id: incomingId,
      }
    };

    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  loginWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    console.log("provider:::", provider);
    await firebase.auth().
      signInWithPopup(provider).then((result: any) => {
        console.log("result:::", result);
        console.log("result Token:::", result.credential.idToken);
        this.setState({
          name: result.additionalUserInfo.profile.name,
          email_mobileNo: result.additionalUserInfo.profile.email,
          googleToken: result.credential.idToken,
          isSocialAccount: true,
          signInScreen: false
        });
        const event = undefined;
        // if account not exist then create account  // if account exist then return token
        this.createAccountFromSocial(this.state.email_mobileNo, this.state.googleToken, this.state.name);
      }).catch(error => {
        this.setState({ signInScreen: true });
      })
  }

  // Success Handler
  responseGoogleSuccess = (response: any) => {
    this.setState({
      name: response.profileObj.name,
      email: response.profileObj.email,
      email_mobileNo: response.profileObj.email,
      googleToken: response.tokenId,
      isSocialAccount: true,
      signInScreen: false
    })
    const event = undefined;
    this.checkAccountExist(event, 'social_account');
  };

  // Error Handler for
  responseGoogleError = (response: any) => {
    this.setState({
      signInScreen: true
    })
  };

  skipLogin = () => {
    this.setState({
      skipLogin: true
    })
  };

  goToSignInscreen = () => {
    this.setState({ ...this.state, isSetNewPasswordSuccess: false, signInScreen: true, passwordScreen: false, signUpScreen: false, password: '', isLoginFailed: false, isValidEmailMobile: true });
  }

  goToOTPScreen = () => {
    this.setState({ ...this.state, isSetNewPasswordSuccess: false, otpScreen: true, passwordScreen: false, isPinIncorrect: false });
    this.clearTimerObj();
  }

  goToPasswordScreen = () => {
    if (this.state.isNewAccount) {
      this.setState({ ...this.state, setNewPasswordScreen: false, setNewConfirmPassword: "", setNewPassword: "", signUpScreen: true, otpScreen: false, password: "", dob: null, isResendOTP: false, counter: 0 });
    }
    this.setState({ ...this.state, setNewPasswordScreen: false, setNewConfirmPassword: "", setNewPassword: "", passwordScreen: true, otpScreen: false, password: '', isResendOTP: false, counter: 0 });
    this.clearTimerObj();
  }

  goToSetPasswordScreen = () => {
    this.setState({
      ...this.state,
      setNewPasswordScreen: true,
      otpScreen: false,
      password: '',
      setNewPassword: "",
      setNewConfirmPassword: "",
    });
  }

  editEmailMobileNo = (event: any) => {
    this.preventDefault(event);
    this.setState({ editEmailMobileNo: true, password: '' });
  }

  dialogNavigation = (path: string) => {
    if (path === "passwordScreeen") {
      this.setState({ signInScreen: true, passwordScreen: false })
    }
    else if (path === "signUpScreen") {
      this.setState({ signInScreen: true, signUpScreen: false })
    }
  }

  setTimer() {
    this.setState({
      counter: 60
    });
    let timer = setInterval(this.decreamentTimer, 1000);
    this.setState({ timer })
  }

  clearTimerObj() {
    clearInterval(this.state.timer);
  }

  decreamentTimer = () => {
    if (this.state.counter <= 0) {
      this.clearTimerObj()
    } else {
      this.setState({
        counter: this.state.counter - 1
      });
    }
  }

  scrollToTopSignUpModal = () => {
    console.log("scroll to top");
    this.signUpModelRef.current?.scrollIntoView({ behavior: "auto" });
  }

  async componentWillUnmount() {
    super.componentWillUnmount()
    this.clearTimerObj()
  }

  goToHomePage = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  setNewPasswordFunction = () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    let body = {};
    if (this.validateEmail(this.state.email_mobileNo)) {
      const data = {
        data: {
          type: "email_account",
          email: this.state.email_mobileNo,
          password: this.state.setNewPassword,
        }
      }
      body = Object.assign(body, data);
    } else {
      const data = {
        data: {
          type: "sms_account",
          full_phone_number: this.state.email_mobileNo,
          password: this.state.setNewPassword,
        }
      }
      body = Object.assign(body, data);
    }

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.SetNewPasswordCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SetNewPasswordAPIEndPoint
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  // Forget Password Send Otp API Function
  ForgetPasswordSendOtp = (event: any) => {
    this.setState({ ...this.state, isResendOTP: true, isSetNewPasswordSuccess: false, passwordScreen: false, forgetPasswordScreen: true, setNewPasswordScreen: false });
    this.preventDefault(event);

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    let dataObj = {};

    if (this.validateEmail(this.state.email_mobileNo)) {
      dataObj = {
        email: this.state.email_mobileNo
      };
    } else {
      dataObj = {
        full_phone_number: this.state.email_mobileNo
      };
    }

    const httpBody = {
      data: dataObj
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendOTPApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendOTPAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Forget Password Resend Otp Function
  ResendForgetPassOTP = (event: any) => {
    this.preventDefault(event);
    this.setState({ isResendOTP: true }, () => this.ForgetPasswordSendOtp(event));
    // this.sendOTP(event);
    return;
  };

  // Verify Forget Password OTP API Function
  VerifyForgetPassOTP = (event: any) => {
    event?.preventDefault();
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: this.state.token
    };

    let account_type = '';
    if (this.validateEmail(this.state.email_mobileNo)) {
      account_type = configJSON.emailType;
    } else {
      account_type = configJSON.smsType;
    }

    const httpBody = {
      pin: this.state.ForgetOtp
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.VerifyOTPForgetPassApiCallId = requestMessage.messageId;

    if (account_type === configJSON.emailType) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyOtpEndPoint
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyOtpEndPointSMS
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Handle Forget Password Otp Change Function
  handleForgetOtpChange = (event: any) => {
    this.setState({
      ...this.state,
      ForgetOtp: event.target.value
    });
  }

  preventDefault = (event: React.ChangeEvent<HTMLInputElement>) => event.preventDefault();

}
