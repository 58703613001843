

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { setTimeout } from "timers";
import { CustomerDetailResponseModel } from "../../../framework/src/Interfaces/IPayment";
import { toast } from "react-toastify";
import { User_Message } from "../../../framework/src/EnumMessage";
import { baseUrl } from "../../../framework/src/Constants";
// Customizable Area End
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  handleCartCount(number: Number): Function;
  // Customizable Area End
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  data: any;
  loading: boolean;
  navigate: boolean;
  token: string | null;
  open: boolean;
  subMenuActive: boolean;
  placeOrderData: any;
  activeCartId: any;
  productSize: boolean;
  productQty: boolean;
  applyCoupon: boolean;
  payment: boolean;
  ShoppingCartData: any;
  priceDetailsData: any;
  coupons: any;
  details: boolean;
  appliedcoupon: any;
  Removeappliedcoupon: any;
  quantity: any;
  quantityUpdateData: any;
  productQuantityId: any;
  sizeArray: any;
  sizeUpdateData: any;
  size: any;
  productSizeId: any;
  loyaltyPoints: any;
  isRemoved: boolean;
  isMoveToWishlist: boolean;
  isDelete: boolean;
  ShoppingRemoveBag: any;
  sizeQuantityLoader: boolean;
  couponsCatalogueIds: any;
  couponCode: any;
  invalidCoupon: any;
  invalidAlertMessage: any;
  loyaltyDisplay: any;
  loyaltyCheck: any;
  applyLoyaltyPoints: any;
  redeemLoyalty: any;
  useLoyaltyPoints: any;
  removeLoyaltyPoints: any;
  loyaltyPointsError: any;
  exchangeFlow: boolean;
  placeExchangeOrderData: any;
  exchangeItemOrderId: any;
  ShoppingRemoveBagOnExchangeDiscard: any;
  ExchangeFlowDiscarded: boolean;
  alert: boolean
  isUserVerified: boolean;
  
  razorpay_order_id : string | null;
  customerDetail: CustomerDetailResponseModel | null;
  isOrderPlaced: boolean;
  isPaymentFailed: boolean;
  errorResponse: any;
  // shoppingCartloading:boolean;
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}



export default class ShoppingController extends BlockComponent<Props, S, SS> {
  AddToWishCallId: any;
  RemoveToWishCallId: any;
  ActiveCartCallId: any;
  ShoppingCartDataCallId: any;
  RemoveShoppingBagCallId: any;
  priceDetailsDataCallId: any;
  couponsDataCallId: any;
  appliedcouponDataCallId: any;
  quantityUpdateCallId: any;
  sizeUpdateCallId: any;
  loyaltyPointsCallId: any;
  RemoveappliedcouponDataCallId: any;
  applyLoyaltyPointsCallId: any;
  removeLoyaltyPointsCallId: any;
  PlaceExchangeOrderCallId: any;
  RemoveShoppingBagOnExchangeDiscardCallId: any
  getUserProfileId: any
  placeOrderApiCallId: any
  fetchCapturePaymentApiCallId: any

  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      data: [],
      loading: false,
      navigate: false,
      token: "",
      open: false,
      subMenuActive: false,
      placeOrderData: [],
      activeCartId: [],
      productSize: false,
      productQty: false,
      applyCoupon: false,
      payment: false,
      ShoppingCartData: [],
      priceDetailsData: [],
      coupons: [],
      details: false,
      appliedcoupon: [],
      Removeappliedcoupon: [],
      quantity: 1,
      quantityUpdateData: [],
      productQuantityId: "",
      sizeArray: [],
      sizeUpdateData: [],
      productSizeId: "",
      size: "",
      loyaltyPoints: [],
      isRemoved: false,
      isMoveToWishlist: false,
      isDelete: false,
      ShoppingRemoveBag: "",
      sizeQuantityLoader: false,
      couponsCatalogueIds: [],
      couponCode: "",
      invalidCoupon: false,
      invalidAlertMessage: "",
      loyaltyDisplay: "",
      loyaltyCheck: "Enter Points",
      applyLoyaltyPoints: [],
      redeemLoyalty: "choose_custom_point",
      useLoyaltyPoints: "",
      removeLoyaltyPoints: [],
      loyaltyPointsError: "",
      exchangeFlow: false,
      placeExchangeOrderData: [],
      exchangeItemOrderId: "",
      ShoppingRemoveBagOnExchangeDiscard: "",
      ExchangeFlowDiscarded: false,
      alert: false,
      isUserVerified: false,
      
      customerDetail: null,
      isOrderPlaced: false,
      isPaymentFailed: false,
      errorResponse: {},
      razorpay_order_id: null,

      // shoppingCartloading:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (apiRequestCallId && responseJson) {
        if (
          apiRequestCallId === this.quantityUpdateCallId ||
          apiRequestCallId === this.sizeUpdateCallId ||
          apiRequestCallId === this.ShoppingCartDataCallId ||
          apiRequestCallId === this.AddToWishCallId ||
          apiRequestCallId === this.RemoveToWishCallId ||
          apiRequestCallId === this.RemoveShoppingBagCallId ||
          apiRequestCallId === this.RemoveShoppingBagOnExchangeDiscardCallId ||
          apiRequestCallId === this.priceDetailsDataCallId
        ) {
          this.receiveBlock1(apiRequestCallId, responseJson);
        } else if (
          apiRequestCallId === this.couponsDataCallId ||
          apiRequestCallId === this.loyaltyPointsCallId ||
          apiRequestCallId === this.ActiveCartCallId ||
          apiRequestCallId === this.appliedcouponDataCallId ||
          apiRequestCallId === this.RemoveappliedcouponDataCallId ||
          apiRequestCallId === this.applyLoyaltyPointsCallId ||
          apiRequestCallId === this.removeLoyaltyPointsCallId ||
          apiRequestCallId === this.PlaceExchangeOrderCallId ||
          apiRequestCallId === this.getUserProfileId || 
          apiRequestCallId === this.placeOrderApiCallId || 
          apiRequestCallId === this.fetchCapturePaymentApiCallId
        ) {
          this.receiveBlock2(apiRequestCallId, responseJson);
        }
      }

      if (responseJson?.errors) {
        this.parseApiErrorResponse(responseJson);
      }

      if (errorReponse) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  receiveBlock1 = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.quantityUpdateCallId) {
      this.QuantityUpdateApiResponse(responseJson);
    } else if (apiRequestCallId === this.sizeUpdateCallId) {
      this.sizeUpdateApiResponse(responseJson);
    } else if (apiRequestCallId === this.ShoppingCartDataCallId) {
      this.shoppingCartApiResponse(responseJson);
    } else if (apiRequestCallId === this.AddToWishCallId) {
      this.addToWishlistApiResponse(responseJson);
    } else if (apiRequestCallId === this.RemoveToWishCallId) {
      this.RemoveToWishlistApiResponse(responseJson);
    } else if (apiRequestCallId === this.RemoveShoppingBagCallId) {
      this.removeShoppingBagApiResponse(responseJson);
    } else if (apiRequestCallId === this.RemoveShoppingBagOnExchangeDiscardCallId) {
      this.removeShoppingBagOnExchangeApiResponse(responseJson);
    } else if (apiRequestCallId === this.priceDetailsDataCallId) {
      this.priceDetailApiResponse(responseJson);
    }
  }

  receiveBlock2 = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.couponsDataCallId) {
      this.couponsApiResponse(responseJson);
    } else if (apiRequestCallId === this.loyaltyPointsCallId) {
      this.loyaltyPointsApiResponse(responseJson);
    } else if (apiRequestCallId === this.ActiveCartCallId) {
      this.activeCartApiResponse(responseJson);
    } else if (apiRequestCallId === this.appliedcouponDataCallId) {
      this.appliedCouponApiResponse(responseJson);
    } else if (apiRequestCallId === this.RemoveappliedcouponDataCallId) {
      this.removeAppliedCouponApiResponse(responseJson);
    } else if (apiRequestCallId === this.applyLoyaltyPointsCallId) {
      this.applyLoyaltyPointsApiResponse(responseJson);
    } else if (apiRequestCallId === this.removeLoyaltyPointsCallId) {
      this.removeLoyaltyApiResponse(responseJson);
    } else if (apiRequestCallId === this.PlaceExchangeOrderCallId) {
      this.PlaceExchangeOrderApiResponse(responseJson);
    } else if (apiRequestCallId === this.getUserProfileId) {
      this.getProfileApiResponse(responseJson);
    } else if (apiRequestCallId === this.placeOrderApiCallId) {
      this.placeOrderApiResponse(responseJson);
    } else if (apiRequestCallId === this.fetchCapturePaymentApiCallId) {
      this.fetchCapturePaymentApiResponse(responseJson);
    }
  }

  placeOrderApiResponse = (responseJson18: any) => {
    //  PLACE ORDER API
      this.placeOrderApiCallId = null;
      this.setState({ loading: false });
      if (responseJson18.data) {
        this.setState({ 
          razorpay_order_id: responseJson18?.data?.attributes?.razorpay_order_id, 
          customerDetail: responseJson18?.data?.attributes?.account?.attributes 
        }, 
        () => this.handlePaymentCapture());
      }
    // END PLACE ORDER API
  }

  handlePaymentCapture = () => {

    const totalAmout = this.state.priceDetailsData?.attributes?.total;
    // 
    if(totalAmout > 0) {
      this.displayrazorPayDashBoard();
    } else if(this.state.exchangeFlow && totalAmout <= 0) {
      this.placeExchangeorder()
    } else {
      this.setState({ isOrderPlaced: true });
    }
  }

  displayrazorPayDashBoard = async () => {

    const res = await this.loadRazorPayScript();

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    console.log("name :::", this.state.customerDetail?.name)
    console.log("email :::", this.state.customerDetail?.email)
    console.log("razor pay key ============> :::", process.env.REACT_APP_RAZOR_PAY_KEY);
    console.log("api base url ============> :::", process.env.REACT_APP_API_PATH);
    console.log("environent Type ============> :::", process.env.NODE_ENV);

    let liveModeKey = 'rzp_live_aF2ccNy23Ih8PG';
    let testModeKey = 'rzp_test_S9BTYeyJB8OOOR';   //updated key
    const devAPIURL = "https://caelumgaurav-65468-ruby.b65468.dev.eastus.az.svc.builder.cafe";
    const stageAPIURL = "https://caelumgaurav-65468-ruby.b65468.stage.eastus.az.svc.builder.ai"
    let razor_pay_key: string;
    if(baseUrl === devAPIURL || baseUrl === stageAPIURL){
      razor_pay_key = testModeKey;
    } else {
      razor_pay_key = liveModeKey;
    }
    const options = {
      key: razor_pay_key,
      amount: this.state.priceDetailsData?.attributes?.total,
      currency: "INR",
      name: "Caelum",
      description: "Test Transaction",
      image: "https://cdn.razorpay.com/logos/IrSTISPNKoYeoS_medium.png",
      order_id: this.state.razorpay_order_id,
      handler: (res: any) => {
        console.log("authorize response:::", res);
        this.FetchCapturePaymentApi(res.razorpay_payment_id, res.razorpay_order_id)
      },
      prefill: {
        name: this.state.customerDetail?.name,
        email: this.state.customerDetail?.email,
        contact: this.state.customerDetail?.full_phone_number,
      },
      modal: {
        handleback: false,
        escape: false
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    let rzp1 = new (window as any).Razorpay(options);
    rzp1.open();

    rzp1.on('payment.failed', (response: any) => {
      // handle failure scenario
      // display sweet toast failure alert with order_id and payment id
      console.log("payment failed:::", response);

      this.failedPayment(response.error);

      console.log("errror code",response.error.code, " errror description",response.error.description," errror source",response.error.source, " errror step",response.error.step," errror reason",response.error.reason," errror order_id",response.error.metadata.order_id," errror payment_id",response.error.metadata.payment_id);
    });
  };

  failedPayment = (errorResponse: any) => {
    // set isPaymet faied to true and desripyion and payment id
    console.log("inside failed payment");
    const container = document.getElementsByClassName("razorpay-container");
    console.log("container::", container);
    this.setState({ 
      isPaymentFailed: true, 
      errorResponse: errorResponse 
    });
    // razorpay-container is a css className for razor pay modal
    (document.getElementsByClassName("razorpay-container")[0] as any).style.display = 'none'; // hide razor pay model and display failure modal
  }

  showRazorPayModel = () => {
    (document.getElementsByClassName("razorpay-container")[0] as any).style.display = 'block'; // display razor pay model and hide failure modal
    this.setState({ 
      isPaymentFailed: false, 
      errorResponse: {} 
    });
  }

  goToHomePage = () => {
    localStorage.setItem('cartItemCount', "0");
    // no item should in cart
    this.props.handleCartCount(0);
    this.props.navigation.navigate('Home');
  }

  fetchCapturePaymentApiResponse = (responseJson: any) => {
     // FETCH CAPTURE PAYMENT API
      this.fetchCapturePaymentApiCallId = null;
      this.setState({ loading: false })
      if (responseJson && responseJson.data) {
        if (responseJson.data.attributes.status === 'captured' || (responseJson?.data?.attributes?.status === 'pending' && responseJson?.data?.attributes?.method === "cod")) {

          // exchange flow order placed code
          if (this.state.exchangeFlow) {
            this.placeExchangeorder()
          } else { this.setState({ isOrderPlaced: true }); }

        } 
      }

    // END FETCH CAPTURE PAYMENT API
  }

    // for capture payment on backend database
  // note : this transaction will we auto captured on razor pay dashboard
  FetchCapturePaymentApi = (razorpay_payment_id: string, razorpay_order_id: string) => {

    this.setState({ loading: true })

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.fetchCapturePaymentApiCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchPaymentAPIEndPoint + `?order_id=${this.state.activeCartId.id}&payment_id=${razorpay_payment_id}&razorpay_order_id=${razorpay_order_id}`
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;

  }

    // exchange item function 
    placeExchangeorder(
      // productid: any, variantId: any
    ) {
      // console.log(Number(productid), variantId)
  
      const header = {
        "Content-Type": configJSON.productApiContentType,
        token: this.state.token
      };
  
      const httpBody = {
        order_item_id: Number(this.state.exchangeItemOrderId),
        delivery_address_id: "",
        service_type: "exchange",
        // reason_for_exchange: "size is too short",
        //address id
        // additional_comments: "take additional comment whatever you want",
        // reason_to_exchange_id: 1,//pass the reasonforexchange id here
        // others: "dummy"
  
  
      };
  
      const apiPlaceExchangeOrderequest = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.PlaceExchangeOrderCallId = apiPlaceExchangeOrderequest.messageId;
  
      apiPlaceExchangeOrderequest.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.PlaceExchangeOrderAPiEndPoint
      );
  
      apiPlaceExchangeOrderequest.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      apiPlaceExchangeOrderequest.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      apiPlaceExchangeOrderequest.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypePost);
  
      runEngine.sendMessage(apiPlaceExchangeOrderequest.id, apiPlaceExchangeOrderequest);
  
      return true;
  
    }

  QuantityUpdateApiResponse = (responseJson17: any) => {
    this.quantityUpdateCallId = null;

    if (responseJson17?.data) {
      this.setState({ quantityUpdateData: responseJson17.data }, () => {
        this.shoppingCartData();
        this.shoppingPriceData()
        this.setState({ sizeQuantityLoader: false })
      });
    }

  }

  sizeUpdateApiResponse = (responseJson1: any) => {
    this.sizeUpdateCallId = null;
    if (responseJson1?.data) {
      this.setState({ sizeUpdateData: responseJson1.data }, () => {
        this.shoppingCartData();
        this.shoppingPriceData()
        this.setState({ sizeQuantityLoader: false })
      });
    }
  }

  shoppingCartApiResponse = (responseJson2: any) => {
    this.ShoppingCartDataCallId = null;
    if (responseJson2?.data) {
      this.setState({ ShoppingCartData: responseJson2.data }, () => {
        const removeondiscard: any = this.state.ShoppingCartData[0].id
        this.setState({ ShoppingRemoveBagOnExchangeDiscard: removeondiscard })
        const cartItemList: any[] = responseJson2.data;
        if (cartItemList) {
          localStorage.setItem('cartItemCount', cartItemList.length.toString());
          this.props.handleCartCount(cartItemList.length);
        } else {
          localStorage.setItem('cartItemCount', "0");
          this.props.handleCartCount(0);
        }
        let couponsCatalogueIds: string[] = []
        this.state.ShoppingCartData.map((ids: any) => {
          couponsCatalogueIds.push(ids.attributes.catalogue_id)
        })
        this.setState({
          couponsCatalogueIds: couponsCatalogueIds
        }, () => this.coupons())

      });
      this.shoppingPriceData()
      // this.setState({ shoppingCartloading: false });
      // console.log(this.state.couponsCatalogueIds, "cat ids")
      // console.log('////////////// ShoppingCartData data------------------------------------------------------', this.state.ShoppingCartData);
    }
    else {
      this.setState({ ShoppingCartData: "" }, () => {
        localStorage.setItem('cartItemCount', "0");
        this.props.handleCartCount(0);
      })
    }
  }

  addToWishlistApiResponse = (responseJson3: any) => {
    this.AddToWishCallId = null;
    if (responseJson3) {
      this.setState({ isMoveToWishlist: true });
      if (this.Removeappliedcoupon() &&
        this.removeLoyaltyPoints()) { this.RemoveShoppingBag() }
    }
  }

  RemoveToWishlistApiResponse = (responseJson4: any) => {
    this.RemoveToWishCallId = null;
  }

  removeShoppingBagApiResponse = (responseJson5: any) => {
    this.RemoveShoppingBagCallId = null;
    if (responseJson5?.message == "cart items Removed") {
      this.shoppingCartData();
      this.shoppingPriceData();
    }
  }

  removeShoppingBagOnExchangeApiResponse = (responseJson6: any) => {
    this.RemoveShoppingBagOnExchangeDiscardCallId = null;
    if (responseJson6?.message == "cart items Removed") {
      this.setState({ loading: false })
      this.shoppingCartData();
      this.shoppingPriceData();
      this.props.navigation.navigate("Home")
    }
  }

  priceDetailApiResponse = (responseJson7: any) => {
    this.priceDetailsDataCallId = null;
    if (responseJson7) {
      this.setState({ priceDetailsData: responseJson7.data });
    }
  }

  couponsApiResponse = (responseJson8: any) => {
    this.couponsDataCallId = null;
    if (responseJson8?.data) {
      this.setState({ coupons: responseJson8.data.reverse() });
    } else if (responseJson8?.message) {
      this.setState({ coupons: "" });
    }
  }

  loyaltyPointsApiResponse = (responseJson9: any) => {
    this.loyaltyPointsCallId = null;
    if (responseJson9?.data) {
      this.setState({ loyaltyPoints: responseJson9.data });
    }
  }

  activeCartApiResponse = (responseJson10: any) => {
    this.ActiveCartCallId = null;

    if (responseJson10) {
      this.setState({ activeCartId: responseJson10.data })
    }
    else { this.setState({ activeCartId: "" }) }
  }

  appliedCouponApiResponse = (responseJson11: any) => {
    this.appliedcouponDataCallId = null;
    if (responseJson11?.data) {
      this.setState({ invalidCoupon: false })
      this.setState({ appliedcoupon: responseJson11.data });
      this.activeCartId();
      this.setState({ applyCoupon: false })
      this.setState({ invalidCoupon: false })
      this.setState({ couponCode: "" })
      this.shoppingPriceData()

    }
    else {
      //Check Error Response
      this.setState({ invalidCoupon: true })
      this.setState({ invalidAlertMessage: responseJson11?.message })
    }
  }

  removeAppliedCouponApiResponse = (responseJson12: any) => {
    this.RemoveappliedcouponDataCallId = null;

    if (responseJson12?.data) {
      this.setState({ Removeappliedcoupon: responseJson12.data });
      this.activeCartId()
      this.shoppingPriceData()
    }
  }

  applyLoyaltyPointsApiResponse = (responseJson13: any) => {
    this.applyLoyaltyPointsCallId = null;
    if (responseJson13?.data) {
      this.setState({ applyLoyaltyPoints: responseJson13.data });
      this.activeCartId()
      this.shoppingPriceData()
      this.loyaltyPoints()
      this.setState({ loyaltyPointsError: `₹${this.state.priceDetailsData?.attributes?.loyalty_point_discount} will be deducted from total MRP`, useLoyaltyPoints: "" })

    }
  }

  removeLoyaltyApiResponse = (responseJson14: any) => {
    this.removeLoyaltyPointsCallId = null;

    if (responseJson14) {
      this.setState({ removeLoyaltyPoints: responseJson14 });
      this.activeCartId()
      this.shoppingPriceData()
      this.loyaltyPoints()

      this.setState({ loyaltyPointsError: "", useLoyaltyPoints: "" })

    }
  }

  PlaceExchangeOrderApiResponse = (responseJson15: any) => {
    this.PlaceExchangeOrderCallId = null;
      if (responseJson15) {
        this.setState({ placeExchangeOrderData: responseJson15?.data });
        this.setState({ isOrderPlaced: true });
        localStorage.removeItem("exchangeFlow")
        localStorage.removeItem("exchangeItemOrderId")
        localStorage.removeItem("addressId")
        console.log('////////////// place Exchange order------------------------------------------------------', this.state.placeExchangeOrderData)
      }
  }

  getProfileApiResponse = (responseJson16: any) => {
    this.getUserProfileId = null;
    if (responseJson16?.data) {
      const isEmailVerified = responseJson16?.data?.attributes?.email_verified;
      const ismobileVerified = responseJson16?.data?.attributes?.phone_verified;

      if (isEmailVerified || ismobileVerified) {
        this.setState({ isUserVerified: true });
      } else {
        this.setState({ isUserVerified: false });
      }
    }
  }

  // Customizable Area Start
  async componentDidMount() {

    // console.log("component rendered")

    try {
      const localToken = await localStorage.getItem("authToken")
      // console.log(localToken, "tokentoken")
      this.setState({ token: localToken });
      this.shoppingCartData();
      this.getProfileDetail()
      this.activeCartId()
      this.loyaltyPoints()

      // razor pay modal script load
      this.loadRazorPayScript();
      // const script = document.createElement("script");
      // script.src = "https://checkout.razorpay.com/v1/checkout.js";
      // script.async = true;
      // // script.integrity = 'sha256-LATlOsAQSNi2LogyPBU3P7s6gBAZ8jE6rYheAb60uWk=';
      // // script.crossOrigin = 'anonymous';
      // document.body.appendChild(script);

      if (this.state.ShoppingCartData! == undefined || this.state.ShoppingCartData.length! == 0) {
        this.shoppingPriceData();
      }
      this.shoppingPriceData();
      const exchangeItem: any = localStorage.getItem("exchangeItemOrderId")
      this.setState({ exchangeItemOrderId: exchangeItem })
      if (localStorage.getItem("exchangeFlow") == "true") {
        this.setState({ exchangeFlow: true })
      } else { this.setState({ exchangeFlow: false }) }
      // this.appliedcoupon()
      // this.loyaltyPoints()

    } catch (e) {
      // error reading value
    }


  }

  loadRazorPayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  // status block for adding wishlist and removing wishlist 
  StatusBlog = async (data: any, id: any) => {
    if (data) {
      this.RemoveWishList(id)



    } else {
      this.AddWishList(id)



    }
  }


  // Active Cart id start 
  activeCartId() {

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiActiveCartRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.ActiveCartCallId = apiActiveCartRequest.messageId;
    apiActiveCartRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ActiveCartAPIEndPoint
    );
    apiActiveCartRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiActiveCartRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(apiActiveCartRequest.id, apiActiveCartRequest);
    return true;


  }




  // loyalty points get start 

  loyaltyPoints() {

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiLoyaltyPointsRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.loyaltyPointsCallId = apiLoyaltyPointsRequest.messageId;
    apiLoyaltyPointsRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loyaltyPointsAPIEndPoint
    );
    apiLoyaltyPointsRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiLoyaltyPointsRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(apiLoyaltyPointsRequest.id, apiLoyaltyPointsRequest);
    return true;


  }


  // loyalty points get end  







  // price details start

  shoppingPriceData() {

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiShoppingPriceRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.priceDetailsDataCallId = apiShoppingPriceRequest.messageId;
    apiShoppingPriceRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.shoppingPriceAPIEndPoint
    );
    apiShoppingPriceRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiShoppingPriceRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(apiShoppingPriceRequest.id, apiShoppingPriceRequest);
    return true;


  }

  // price details end 

  // coupons start 

  coupons() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiCouponsRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.couponsDataCallId = apiCouponsRequest.messageId;
    apiCouponsRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.couponsAPIEndPoint + `[${this.state.couponsCatalogueIds}]`

    );
    apiCouponsRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiCouponsRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(apiCouponsRequest.id, apiCouponsRequest);
    return true;


  }

  // coupons end 



  applyCoupon(coupon: any) {
    if (this.state.activeCartId.attributes.is_coupon_applied) { this.Removeappliedcoupon() }
    setTimeout(() => this.appliedcoupon(coupon), 1000);

  }

  // applied coupon start

  appliedcoupon(code: any) {

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };

    const httpBody = {
      cart_id: this.state.activeCartId.id,
      coupon_code: code

    };

    const apiAppliedCouponRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.appliedcouponDataCallId = apiAppliedCouponRequest.messageId;

    apiAppliedCouponRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.appliedcouponAPIEndPoint
    );

    apiAppliedCouponRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiAppliedCouponRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiAppliedCouponRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiAppliedCouponRequest.id, apiAppliedCouponRequest);

    return true;


  }
  // applied coupon end 






  // remove coupon start 
  Removeappliedcoupon() {


    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };

    const httpBody = {
      cart_id: this.state.activeCartId?.id,
      coupon_code: this.state.activeCartId?.attributes?.coupon_code

    };

    const apiRemoveCouponRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.RemoveappliedcouponDataCallId = apiRemoveCouponRequest.messageId;

    apiRemoveCouponRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RemoveappliedcouponAPIEndPoint
    );

    apiRemoveCouponRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRemoveCouponRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRemoveCouponRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRemoveCouponRequest.id, apiRemoveCouponRequest);

    return true;


  }

  // remove coupon end 



  // apply loyalty points start

  onPressLoyaltyPointsApply() {
    if (this.state.redeemLoyalty == "use_all_point") {
      let body = {
        loyalty_point: "use_all_point"
      }
      this.applyLoyaltyPoints(body)
    } else if (this.state.useLoyaltyPoints == '') {
      // alert("Enter Loyalty Points to Redeem")
      this.setState({ loyaltyPointsError: "Enter Loyalty Points to Redeem" })
    } else if (isNaN(parseInt(this.state.useLoyaltyPoints))) {
      // alert('Enter Valid Loyalty Points to Redeem')
      this.setState({ loyaltyPointsError: "Enter Valid Loyalty Points to Redeem" })
    } else if (parseInt(this.state.useLoyaltyPoints) > this.state.loyaltyPoints?.attributes?.actual_points) {
      // alert('Entered points are more than available points')
      this.setState({ loyaltyPointsError: "Entered points are more than available points" })
    }
    else if (parseInt(this.state.useLoyaltyPoints) === 0 || this.state.useLoyaltyPoints.includes("+") || this.state.useLoyaltyPoints.includes("-") || this.state.useLoyaltyPoints.includes(".")) {

      this.setState({ loyaltyPointsError: "Please enter valid points" })
    }


    else {
      this.setState({ loyaltyPointsError: "" })
      let body = {
        loyalty_point: "choose_custom_point",
        use_points: parseInt(this.state.useLoyaltyPoints)
      }
      this.applyLoyaltyPoints(body)
    }
  }
  applyLoyaltyPoints(httpBody: any) {





    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };




    const apiApplyLoyaltyRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.applyLoyaltyPointsCallId = apiApplyLoyaltyRequest.messageId;

    apiApplyLoyaltyRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.applyLoyaltyPointsAPIEndPoint.concat(this.state.activeCartId.id)
    );

    apiApplyLoyaltyRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiApplyLoyaltyRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiApplyLoyaltyRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiApplyLoyaltyRequest.id, apiApplyLoyaltyRequest);

    return true;
  }
  // apply loyalty points end 



  removeLoyaltyPoints() {


    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };


    const apiRemoveLoyaltyRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeLoyaltyPointsCallId = apiRemoveLoyaltyRequest.messageId;

    apiRemoveLoyaltyRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeLoyaltyPointsAPIEndPoint.concat(this.state.activeCartId.id)
    );

    apiRemoveLoyaltyRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // apiRequest.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(httpBody)
    // );

    apiRemoveLoyaltyRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiRemoveLoyaltyRequest.id, apiRemoveLoyaltyRequest);

    return true;


  }

  // apply loyalty points end 








  applyCouponInput() {
    if (this.state.activeCartId.attributes.is_coupon_applied) { this.Removeappliedcoupon() }
    setTimeout(() => this.appliedcouponInput(), 500);

  }



  appliedcouponInput() {


    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };

    const httpBody = {
      cart_id: this.state.activeCartId.id,
      coupon_code: this.state.couponCode

    };

    const apiAppliedCouponInputRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.appliedcouponDataCallId = apiAppliedCouponInputRequest.messageId;

    apiAppliedCouponInputRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.appliedcouponAPIEndPoint
    );

    apiAppliedCouponInputRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiAppliedCouponInputRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiAppliedCouponInputRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost);

    runEngine.sendMessage(apiAppliedCouponInputRequest.id, apiAppliedCouponInputRequest);

    return true;


  }

  // coupons end 



  // shopping cart data start 
  shoppingCartData() {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiShoppingCartRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.ShoppingCartDataCallId = apiShoppingCartRequest.messageId;
    apiShoppingCartRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ShoppingCartAPIEndPoint
    );
    apiShoppingCartRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiShoppingCartRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(apiShoppingCartRequest.id, apiShoppingCartRequest);
    return true;



  }
  // shopping cart data end 

  //add WishList
  AddWishList(data: any) {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const httpBody = {
      "catalogue_ids": [data]
    }

    const apiAddToWishlistRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.AddToWishCallId = apiAddToWishlistRequest.messageId;
    apiAddToWishlistRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddWishlistAPIEndPoint
    );
    apiAddToWishlistRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiAddToWishlistRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )
    apiAddToWishlistRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(apiAddToWishlistRequest.id, apiAddToWishlistRequest);
    return true;
  }
  //remove whishlist
  RemoveWishList(data: any) {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const apiREmoveWishlistRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.RemoveToWishCallId = apiREmoveWishlistRequest.messageId;
    apiREmoveWishlistRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RemoveWishlistAPIEndPoint.concat(data)
    );
    apiREmoveWishlistRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiREmoveWishlistRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(apiREmoveWishlistRequest.id, apiREmoveWishlistRequest);
    return true;
  }

  // remove from shopping bag  start
  RemoveShoppingBag() {

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const httpBody = {
      "ids": [Number(this.state.ShoppingRemoveBag)]

    }
    const apiRemoveFromShoppingBagRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.RemoveShoppingBagCallId = apiRemoveFromShoppingBagRequest.messageId;
    apiRemoveFromShoppingBagRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RemoveShoppingBagAPIEndPoint
    );
    apiRemoveFromShoppingBagRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRemoveFromShoppingBagRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )

    apiRemoveFromShoppingBagRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDELETE
    );
    runEngine.sendMessage(apiRemoveFromShoppingBagRequest.id, apiRemoveFromShoppingBagRequest);
    return true;
  }
  // remove from shopping bag end 


  // remove from shopping bag  on clicking discard exchange
  RemoveShoppingBagOnExchangeDiscard() {
    this.setState({ ExchangeFlowDiscarded: true })
    this.setState({ loading: true })
    // console.log(this.state.ShoppingRemoveBag, "remove product id")

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };
    const httpBody = {
      "ids": [Number(this.state.ShoppingRemoveBagOnExchangeDiscard)]

    }
    const apiRemoveShoppingBagOnDiscardExchangeRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.RemoveShoppingBagOnExchangeDiscardCallId = apiRemoveShoppingBagOnDiscardExchangeRequest.messageId;
    apiRemoveShoppingBagOnDiscardExchangeRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RemoveShoppingBagAPIEndPoint
    );
    apiRemoveShoppingBagOnDiscardExchangeRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRemoveShoppingBagOnDiscardExchangeRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )

    apiRemoveShoppingBagOnDiscardExchangeRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDELETE
    );
    runEngine.sendMessage(apiRemoveShoppingBagOnDiscardExchangeRequest.id, apiRemoveShoppingBagOnDiscardExchangeRequest);
    return true;
  }
  // remove from shopping bag end 




  // detailsshowhide
  showHideToggle(id: any, code: any) {

    const ele: any = document.getElementById(code)
    const eledetails: any = document.getElementById(id)

    // console.log(ele.innerText, eledetails.style.display)

    if (ele.innerText == "view details" && eledetails.style.display == "none") {
      // console.log("its true")
      ele.innerText = "Hide Details"
      eledetails.style.display = "block"
    }
    // else{ele.textContent = "view details"
    else {
      // console.log("its false")
      ele.innerText = "view details"
      eledetails.style.display = "none"
    }
  }


  // quantity update api start

  quantityUpdate() {
    // console.log(this.state.productQuantityId)
    this.setState({ sizeQuantityLoader: true })

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };

    const httpBody = {
      quantity: this.state.quantity,

    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.quantityUpdateCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.quantityUpdateAPIEndPoint.concat(this.state.productQuantityId)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut);

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;

  }

  // quantity update api end




  // size update api start

  sizeUpdate() {
    // console.log(this.state.size)
    this.setState({ sizeQuantityLoader: true })
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };

    const httpBody = {
      catalogue_variant_id: this.state.size,

    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sizeUpdateCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sizeUpdateAPIEndPoint.concat(this.state.productSizeId)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut);

    runEngine.sendMessage(apiRequest.id, apiRequest);

    return true;

  }

  // size update api end
  // MoveToWishlist() {

  //   this.removeLoyaltyPoints()

  // }

  MoveToWishlistessage = () => {
    this.setState({ isMoveToWishlist: false });
  }
  RemovedMessage = () => {
    this.setState({ isRemoved: false });
  }
  ExchangeFlowDiscarded = () => {
    this.setState({ ExchangeFlowDiscarded: false });
  }
  // close delete box 

  handleClose() {
    this.setState({
      isDelete: false
    });
  }

  getProfileDetail() {
    const header = {
      "Content-Type": "application/json",
      token: this.state.token

    };

    const apiGetUserProfileRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileId = apiGetUserProfileRequest.messageId;

    apiGetUserProfileRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileAPiEndPoint
    );

    apiGetUserProfileRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiGetUserProfileRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(apiGetUserProfileRequest.id, apiGetUserProfileRequest);

    return true;
  }

  PlaceOrderApi = () => {

    if (!this.state.isUserVerified) {
      toast.error(User_Message.UserNotVerified, { autoClose: 7000 })
    } else {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token,
    };

    let httpbody: any = {
      cart_id: this.state.activeCartId.id,
      // payment_method: this.state.selectedPaymentMethod
    }

    if(Number(this.state.priceDetailsData?.attributes?.total) === 0) {
      httpbody = {
        ...httpbody,
        payment_method: "prepaid"
      }
    }
    
    const apiPlaceOrderRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.placeOrderApiCallId = apiPlaceOrderRequest.messageId;

    apiPlaceOrderRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.paymentAPIEndPoint
    );
    apiPlaceOrderRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiPlaceOrderRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    apiPlaceOrderRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpbody)
    );

    runEngine.sendMessage(apiPlaceOrderRequest.id, apiPlaceOrderRequest);
    return true;
    }
  }
  // Customizable Area End
}


