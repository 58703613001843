import React, { Component } from "react";
import { Container, Grid, Button, Box, Typography, Dialog, DialogContent, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SizeChartController, { Props } from "./SizeChartController.web";
import NewSizeChartController from "./NewSizeChartController.web";
import { styled } from "@material-ui/styles";
const Wrapper = styled('div')({
  pageOuter: {
    backgroundColor: "#F7F7F7",
    padding: "50px 0",
    fontFamily: "'Montserrat', sans-serif",
    "& h2": {
      margin: "0 0 20px",
      textAlign: "center",
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "700",
      fontFamily: "'Montserrat', sans-serif",
      textTransform: "uppercase",
      color: "#121212",
    },
    "& .image-block": {
      width: "200px",
      margin: "0 auto 50px",
    },
    "& .image-block img": {
      width: "150px",
    },
    "& p": {
      marginBottom: "30px",
      textAlign: 'center',
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: "600",
      fontFamily: "'Montserrat', sans-serif",
      color: "#121212",
    },
    '& h3': {
      margin: "0 0 20px",
      textAlign: "center",
      fontSize: "20px",
      lineHeight: "24px",
      fontWeight: "600",
      fontFamily: "'Montserrat', sans-serif",
      color: "#121212",
    },
    "& .black-btn": {
      width: "300px",
      maxWidth: "calc(100% - 30px)",
      margin: "0 auto 20px",
    },
    "& .content-block": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
});
class SizeChartsEmpty extends NewSizeChartController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (

      <Wrapper>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="content-block">
                <h2>Size Charts</h2>
                <div className="image-block">
                  <img src={require('../assets/char-block.png')} />
                </div>
                <h3>You haven't created any size chart yet!</h3>
                <p>
                  Size charts list is empty. After creaing size chart, You can
                  see them from here!
                </p>
                <Button
                  type="submit"
                  variant="contained"
                  className="black-btn order-btn"
                  onClick={() => this.props.navigation.navigate('CreateChart')}
                >
                  Create Size Chart
                </Button>
                <h3 className="dummy-chart-label">See dummy size chart</h3>
                <Button
                  type="submit"
                  variant="contained"
                  className="black-btn order-btn"
                  onClick={(e) => {
                    this.setState({
                      viewChartTable: true,
                    });
                  }}
                >
                  View Sample Size Chart
                </Button>
              </div>
            </Grid>
          </Grid>
          <Dialog
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="customized-dialog-duplicate"
            open={this.state.viewChartTable}
            className="dummy-chart-dialog"
          >
            <DialogContent>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography variant="h5">
                    Dummy Size Charts
                  </Typography>
                  <Typography variant="subtitle1">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </Typography>
                </Box>
                <Box>
                  <Button onClick={(e) => {
                    this.setState({
                      viewChartTable: false,
                    });
                  }}>
                    <CloseIcon />
                  </Button>
                </Box>
              </Box>
              <img width="100%" src={require('../assets/dummy-chart.png')}></img>
            </DialogContent>
          </Dialog>
        </Container>
        </Wrapper>
    );
  }
}
export default SizeChartsEmpty;
