import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { spykarBeachYellowShirt } from "../../Wishlist/src/assets";
import { toastErrorMessage, toastSuccessMessage } from "../../../components/src/toastMessage";
import { PaginationResponse } from "../../../framework/src/Interfaces/ICommonResponse";
import { paginationCount } from "../../../framework/src/Constants";
import { scrollToTopOfElement } from "../../../framework/src/commonFunction";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    loading: boolean;
    token: string | null;
    status: string;
    filter: any;
    productList: any[];
    addProductDialog: boolean;
    showDeleteDialog: boolean;
    selectedProductId: number | null;
    selectedProductName: string;
    searchText: string;
    currentPage: number;
    totalPage: number;
}

interface SS {

}


export default class InventoryController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getProductListApiCallId: any;
    deleteApiCallId: any;
    productStatusApiCallId: any;
    getSearchProductCallId: any;
    shopbyProductCallId: any;
    warrantyPeriodData = [];

    elementRef: any // top element scroll reference
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        this.state = {
            loading: false,
            token: localStorage.getItem('authToken'),
            status: "new_in",
            filter: "",
            productList: [],
            addProductDialog: false,
            showDeleteDialog: false,
            selectedProductId: null,
            selectedProductName: "",
            searchText: "",
            currentPage: 1,
            totalPage: 0
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async componentDidMount() {
        this.getProductList();
    }

    async receive(from: string, message: Message) {
        console.log('api call ------------------>>>')
        console.log('message ------------------>>>', message)

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            // console.log('json response ------------------>>>', responseJson.data)

            if (responseJson && responseJson.errors && responseJson.errors.length && responseJson.errors[0].token) {
                this.goToLoginScreen();
            }

            if (apiRequestCallId) {
                this.receiveBlock1(apiRequestCallId, responseJson)
            }
            this.setState({ loading: false });

            // api errors 
            if (responseJson?.errors) {
                this.parseApiErrorResponse(responseJson);
            }

            // api handle catch response
            if (errorReponse) {
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
    }
    // Customizable Area End

    receiveBlock1 = (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.getProductListApiCallId) {
            this.getProductListApiResponse(responseJson)
        } else if (apiRequestCallId === this.deleteApiCallId) {
            this.deleteProductApiResponse(responseJson)
        } else if (apiRequestCallId === this.productStatusApiCallId) {
            this.enableDisableStatusApiResponse(responseJson)
        }
    }

    getProductListApiResponse = (responseJson: any) => {
        if (responseJson?.data && responseJson?.data?.length && responseJson?.meta) {
            // store product data into product state
            const paginationResponse: PaginationResponse = responseJson?.meta;
            const totalPage = paginationResponse?.total_pages || 0;

            this.setState({ productList: responseJson.data, totalPage: totalPage });
        } else if (responseJson?.message) {
            //no record found
            this.setState({ productList: [], totalPage: 0 });
        }
    }
    deleteProductApiResponse = (responseJson: any) => {
        this.setState({ showDeleteDialog: false });
        if (responseJson?.data && responseJson?.data?.length) {
            if (responseJson.data[0]?.errors &&
                responseJson.data[0]?.errors.length
            ) {
                // product is not deleted show toast error message
                const error_message = responseJson.data[0].errors[0].catalogue;
                toastErrorMessage(error_message);
            }
        }
        this.getProductList();
    }
    enableDisableStatusApiResponse = (responseJson: any) => {
        if (responseJson?.message) {
            toastSuccessMessage(responseJson?.message)
        }
        this.getProductList(this.state.searchText);
    }

    goToLoginScreen = () => {
        console.log("go to login screen:::");
        const msg: Message = new Message(
            getName(MessageEnum.NavigationMobilePhoneLogInMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
    }

    goToAddProductScreen = (id?: number, isEdit?: boolean) => {

        // set id and isEdit to local storage
        if (isEdit) {
            localStorage.setItem('id', `${id}`);
            localStorage.setItem('isEdit', 'true');
        } else {
            localStorage.removeItem('isEdit');
            localStorage.removeItem('id');
        }

        const msg: Message = new Message(
            getName(MessageEnum.NavigationProductBasicDetailMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

        if (isEdit) {
            msg.addData(getName(MessageEnum.VendorAddUpdateProductDataMessage), {
                id: id,
                isEdit: true
            });
        } else {
            msg.addData(getName(MessageEnum.VendorAddUpdateProductDataMessage), {
                id: null,
                isEdit: false
            });
        }
        this.send(msg);
    }

    goToCsvUploadScreen = () => {
        window.open("/CsvUpload", "_self");
    }

    handlePageChange = async (currentPage: number) => {
        this.setState({
            currentPage: currentPage
        }, () => {
            this.getProductList(this.state.searchText);
            // add scroll functionality
            scrollToTopOfElement(this.elementRef)
        })
    }

    getProductList = (searchText?: string) => {

        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.apiContentType,
            "token": this.state.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getProductListApiCallId = requestMessage.messageId;

        if (searchText) {
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.getProductDataApi + `?search=${searchText}&page=${this.state.currentPage}&per_page=${paginationCount}`
            );
        } else {
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.getProductDataApi + `?page=${this.state.currentPage}&per_page=${paginationCount}`
            );
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    searchProduct = (text: string) => {
        this.setState({ searchText: text, currentPage: 1 }, () => {
            const { searchText } = this.state;

            this.getProductList(searchText);
        });
    }

    deleteProductApi = () => {

        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.apiContentType,
            "token": this.state.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteApiCallId = requestMessage.messageId;
        let productID = this.state.selectedProductId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.productDeleteAPiEndPoint + `/${productID}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    productEnableDisableApi = (status: boolean, productId: number) => {

        this.setState({ loading: true });

        const header = {
            "Content-Type": configJSON.apiContentType,
            "token": this.state.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.productStatusApiCallId = requestMessage.messageId;

        // let productID = this.state.selectProduct.id
        let productID = productId;

        const httpBody = {
            product_status: status,
            // status: "published"
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.productEditAPiEndPoint + `/${productID}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getFilterData = (value: any) => {
        if (value === "Low") {
            this.sortAscending()
        } else if (value === "High") {
            this.sortDescending()
        } else {
            const { productList } = this.state;
            const newshopbyProduct = productList.sort(function (product1: any, product2: any) {
                const product_id_1 = product1.id;
                const product_id_2 = product2.id;
                return Number(parseFloat(product_id_1)) - Number(parseFloat(product_id_2));
            });
            const revData = newshopbyProduct.reverse();
            this.setState({ productList: revData, loading: false });
        }
    }

    sortAscending = () => {
        const { productList } = this.state;
        this.setState({ loading: true });
        const newshopbyProduct = productList.sort(function (product1: any, product2: any) {
            const sale_price1 = product1.attributes.sale_price ? product1.attributes.sale_price : (product1.attributes.catalogue_variants_attributes as any[]).length ? product1.attributes.catalogue_variants_attributes[0].attributes.sale_price : "";
            const sale_price2 = product2.attributes.sale_price ? product2.attributes.sale_price : (product2.attributes.catalogue_variants_attributes as any[]).length ? product2.attributes.catalogue_variants_attributes[0].attributes.sale_price : "";
            return Number(parseFloat(sale_price1)) - Number(parseFloat(sale_price2));
        });
        this.setState({ productList: newshopbyProduct, loading: false });
    }

    sortDescending = () => {
        const { productList } = this.state;
        this.setState({ loading: true });
        const newshopbyProduct = productList.sort(function (product1: any, product2: any) {
            const sale_price1 = product1.attributes.sale_price ? product1.attributes.sale_price : (product1.attributes.catalogue_variants_attributes as any[]).length ? product1.attributes.catalogue_variants_attributes[0].attributes.sale_price : "";
            const sale_price2 = product2.attributes.sale_price ? product2.attributes.sale_price : (product2.attributes.catalogue_variants_attributes as any[]).length ? product2.attributes.catalogue_variants_attributes[0].attributes.sale_price : "";
            return Number(sale_price1) > Number(sale_price2) ? 1 : Number(sale_price1) < Number(sale_price2) ? -1 : 0;
        });
        const revData = newshopbyProduct.reverse();
        this.setState({ productList: revData, loading: false });
    }
    // Customizable Area End

}

