Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypeDel = "DELETE";
exports.apiMethodTypePut = "PUT";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.sizeAPiEndPoint = "bx_block_catalogue/size_charts";
exports.apiCategoryAPiEndPoint = "bx_block_categories/categories_listing";
exports.apiSubCategoryAPiEndPoint =
  "bx_block_categories/sub_categories_listing?category_id=";
exports.apiSizeDeleteAPiEndPoint = "bx_block_catalogue/size_charts/";
exports.apiSizeAPiEndPoint = "bx_block_catalogue/size_charts";
exports.apiSizeUpdateAPiEndPoint = "bx_block_catalogue/size_charts/";
exports.apiRecentlyViewdAPiEndPoint =
  "bx_block_catalogue/catalogues/recent_view_product";
exports.apiBlogAPiEndPoint = "bx_block_blogpostsmanagement/blogs";
exports.apiDiscoverBlogAPIEndPoint = "bx_block_settings/discover_blogs";
exports.blogAPIEndPoint = "bx_block_settings/discover_blogs/";
exports.apiBrandAPiEndPoint = "catalogue/brands";
exports.validationApiMethodType = "GET";

exports.getCategorySubCategorySubSubCategoryApi = "bx_block_categories/categories";

exports.apiShowCategoryAPiEndPoint = "bx_block_categories/categories_listing";
exports.apiShopByPricesAPiEndPoint =
  "bx_block_catalogue/catalogues/catalogue_by_price";
exports.apiShopByPriceAPiEndPoint = "bx_block_productquickview/shop_by_prices";
exports.ShowBannerAPiEndPoint = "bx_block_blogpostsmanagement/baners";
exports.ShopByEidtAPiEndPoint =
  "bx_block_catalogue/catalogues/shop_by_edit_item";
exports.FeatuerdByBrandAPiEndPoint = "catalogue/brands";
exports.apiSerachAPiEndPoint =
  "bx_block_catalogue/catalogues/catalogue_search?search=";
exports.apiTeandingAPiEndPoint = "bx_block_catalogue/catalogues/tranding_now";
exports.apiMainBannerEndPoint = "bx_block_blogpostsmanagement/banners";
exports.apiTreandingAPiEndPoint = "bx_block_catalogue/catalogues/tranding_now";
exports.apiCollectionAPiEndPoint = "bx_block_catalogue/catalogues/collection";
exports.apisubCategoryEndPoint = "bx_block_categories/sub_categories_listing";
exports.apiTopAPiEndPoint = "bx_block_catalogue/catalogues/top_brands";
exports.FeatuerdByBrandAPiEndPoint = "bx_block_catalogue/catalogues/top_brands";
exports.AddWishlistAPIEndPoint =
  "bx_block_wishlist2/wishlist/add_to_wishlist";
exports.RemoveWishlistAPIEndPoint =
  "bx_block_wishlist2/wishlist/remove_to_wishlist?catalogue_id=";
exports.WishlistAPIEndPoint =
  "bx_block_wishlist2/wishlist/wishlisted_catalogue";
exports.ShopbyProductAPiEndPoint =
  "bx_block_catalogue/catalogues/shop_by_product";
exports.PlaceOrderAPiEndPoint = "bx_block_order_management/orders";
exports.ActiveCartAPIEndPoint = "bx_block_shopping_cart/get_active_cart";
exports.ShoppingCartAPIEndPoint = "bx_block_shopping_cart/cart_items";
exports.RemoveShoppingBagAPIEndPoint = "bx_block_shopping_cart/cart_items/";
exports.getSizeChartApiEndPoint = "bx_block_catalogue/size_charts"; // same for delete
exports.getCategoriesApiEndPoint = "bx_block_categories/categories_listing";
exports.getSubCategoriesApiEndPoint =
  "bx_block_categories/sub_categories_listing";
exports.createSizeChartApiEndPoint = "bx_block_catalogue/size_charts";
exports.HomeTopBannerAPIEndPoint =
  "bx_block_blogpostsmanagement/home_top?plateform=web";
exports.HomeCenterBannerAPIEndPoint =
  "bx_block_blogpostsmanagement/home_center?plateform=web";
exports.HomeBottomBannerAPIEndPoint =
  "bx_block_blogpostsmanagement/home_bottom?plateform=web";

exports.productListingAPIEndPoint = "bx_block_catalogue/catalogues/product_list";
exports.ProductsAPIEndPoint =
  "bx_block_catalogue/catalogues/get_product_by_sub_sub_category";
exports.SearchProductsAPIEndPoint = "bx_block_catalogue/search_product";
exports.BannerProductsAPIEndPoint =
  "bx_block_blogpostsmanagement/catalogue_listing_for_banner";
exports.ShopByEditProductsAPIEndPoint =
  "bx_block_catalogue/catalogues/shop_by_edit_item";
exports.getProductsByOffersAPIEndPoint = "bx_block_catalogue/offers/get_offered_product";
exports.formError = "Please check all columns and rows are present";
exports.newSizeChartApiEndPoint = "bx_block_catalogue/measurement_charts";
exports.formHasError = "Please check all Attributes and rows are present";
exports.minimumOneRowError = "Please add atleast one row";
exports.getProfileAPiEndPoint = "account_block/account";
// Customizable Area End
