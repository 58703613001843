import React, { Component } from "react";
import { InputLabel } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import MenuItems from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
    DateRangePicker,
    defaultStaticRanges,
    createStaticRanges
} from "react-date-range";
// import { useTheme } from "@material-ui/core/styles";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
// import DocIc from "../assets/doc-icon.svg";

import ReportsDownloadController, {
    Props,
    //   configJSON
} from "./ReportsDownloadController.web";

import {
    withStyles,
} from "@material-ui/core/styles";

// Reports Download Start
const styles: any = {
    pageOuter: {
        backgroundColor: "#F7F7F7",
        padding: '50px 0',
        "& h2": {
            margin: "0 0 20px",
            textAlign: "center",
            textTransform: "uppercase",
            color: "#121212",
            fontSize: "20px",
            // margin: "0 0 20px",
        },
        "& h4": {
            margin: "0 0 20px 0",
        },
        "& .block-wrapper": {
            backgroundColor: "#fff",
            padding: "30px 15px 40px",
        },
        "& .block-wrapper-inner": {
            maxWidth: "515px",
            margin: "0 auto",
        },
        "& label": {
            fontSize: "14px",
            fontWeight: "400",
            color: "#121212",
        },
        "& .MuiFormControl-root": {
            display: "block",
        },
        "& .MuiInputBase-root": {
            display: "block",
        },
        "& .MuiSelect-select": {
            background: "transparent",
        },
        "& .MuiFormControl-root .MuiInputBase-root": {
            display: "block",
        },
    },
};
// Reports Download end


export class ReportsDownload extends ReportsDownloadController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    // renderDateRangePicker = () => {
    //     console.log("range picker:::");
    //     return (
    //         <>
    //             <div>Filter by start date and end date</div>

    //             <DateRangePicker
    //                 startDatePlaceholder="Start Date"
    //                 endDatePlaceholder="End Date"
    //                 maxDate={new Date()}
    //                 rangeColors={['#3d91ff', '#3ecf8e', '#fed14c']}
    //                 ranges={this.state.dateRange}
    //                 onChange={(ranges: any) => {
    //                     console.log("ranges:::", ranges);
    //                     this.setState({ dateRange: [ranges.rollup] });
    //                 }}
    //                 staticRanges={[]}
    //                 inputRanges={[]}
    //             />
    //         </>
    //     )
    // }

    render() {
        const { classes } = this.props;
        // const selectionRange = {
        //     startDate: new Date(),
        //     endDate: new Date(),
        //     key: 'selection',
        // }
        return (
            <>
                {/* Reports Download Start */}
                <div className={classes.pageOuter}>
                    <div>
                        <Container maxWidth="lg">
                            <Grid container>
                                <Grid item xs={12}>
                                    <h2>Reports Download</h2>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="block-wrapper order-block">
                                        <div className="block-wrapper-inner">
                                            <div className="title-btn-wrapper">
                                                <h4>Reports</h4>
                                                <div className="btn-wrapper">
                                                    <div className="select-border">
                                                        {/* <span className="select-label">Last Week</span> */}
                                                        <Select
                                                            labelId="product-category"
                                                            id="demo-simple-select"
                                                            value={this.state.filter}
                                                            onChange={(e: any) => {
                                                                console.log("filter value:::", e.target);
                                                                this.setState({ filter: e.target.value }, () => {
                                                                    if (this.state.filter === 'custom') {
                                                                        this.setState({ isOpenDateRangePicker: true });
                                                                    } else {
                                                                        console.log("date without custom filter")
                                                                        // this.getFilterDataList(this.state.filter)
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            <MenuItems value="All">All</MenuItems>
                                                            <MenuItems value="One Week">One Week</MenuItems>
                                                            <MenuItems value="One Month">One Month</MenuItems>
                                                            <MenuItems

                                                                value="custom" onClick={() => this.setState({ isOpenDateRangePicker: true, filter: 'custom' })}
                                                            >Custom</MenuItems>
                                                        </Select>
                                                    </div>
                                                    <Button
                                                        variant="contained"
                                                        className="outline-btn"
                                                        type="button"
                                                    >
                                                        Generate
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="form-group" >
                                                <InputLabel id="subtitle">Select Report</InputLabel>
                                                <Select labelId="subtitle" id="subtitle" style={{ marginTop: "15px" }}>
                                                    <MenuItem>Sales</MenuItem>
                                                    <MenuItem value="2">Sales</MenuItem>
                                                    <MenuItem value="3">Sales</MenuItem>
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Container>
                        {/* react date picker schedule */}
                        <Dialog
                            aria-labelledby="customized-dialog-duplicate"
                            open={this.state.isOpenDateRangePicker}
                            className="order-schedule order-dialog"
                            maxWidth="md"
                        >
                            <DialogTitle id="customized-dialog-title">
                                <CloseIcon
                                    className="close-dialog"
                                    onClick={(e) => {
                                        this.setState({
                                            isOpenDateRangePicker: false,
                                            filter: ''
                                        });
                                    }}
                                />
                            </DialogTitle>
                            <DialogContent>
                                <h2 className="modal-heading">
                                </h2>
                                {/* {this.renderDateRangePicker()} */}
                                <div>Filter by start date and end date</div>

                                <DateRangePicker
                                    startDatePlaceholder="Start Date"
                                    endDatePlaceholder="End Date"
                                    maxDate={new Date()}
                                    rangeColors={['#3d91ff', '#3ecf8e', '#fed14c']}
                                    ranges={this.state.dateRange}
                                    onChange={(ranges: any) => {
                                        console.log("ranges:::", ranges);
                                        this.setState({ dateRange: [ranges.selection] });

                                    }}
                                    staticRanges={[]}
                                    inputRanges={[]}
                                />
                                {console.log(this.state.dateRange)}
                                <div className="btn-wrapper">
                                    <Button
                                        href=""
                                        type="submit"
                                        variant="contained"
                                        className="black-btn full-width"
                                        onClick={(e) => { this.setState({ isOpenDateRangePicker: false }, () => this.getOrderFilterByStartAndEndDate()); }}
                                    >
                                        Okay
                                    </Button>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>


                </div>
                {/* Reports Download end */}
            </>
        );
    }
}

export default withStyles(styles)(ReportsDownload);
